import React, { useEffect, useState, useCallback } from "react";

import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";

import { useControlContent } from "../../../hooks/controlContent";
import ControlList from "../ControlList";
import LandingPage from "../../../components/LandingPage";
import DialogImport from "./DialogImport";

import './styles.css';

export default function ImportData() {
  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const { setControlContent, setPageTitle } = useControlContent();

  // Build control menu
  useEffect(() => {
    setPageTitle("RH");
    setControlContent(<ControlList />);
  }, [setControlContent, setPageTitle]);

  const handleOpenDialog = useCallback(type => {
    setOpenDialogImport(true);
    setDialogType(type);
  }, []);

  return (
    <>
      <LandingPage
        title="Import Data"
        subtitle="Import csv with holidays, internal governance meetings, vacations, etc"
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card>
              <CardActionArea onClick={() => handleOpenDialog('office')}>
                <CardContent>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import by office
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import holidays, vacations, internal meetings, licences and
                    more
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: "#f5f6f8" }}>
                <Button size="small" color="primary" onClick={() => handleOpenDialog('office')}>
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardActionArea onClick={() => handleOpenDialog('professional')}>
                <CardContent>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import by professional
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import holidays, vacations, internal meetings, licences and
                    more
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: "#f5f6f8" }}>
                <Button size="small" color="primary" onClick={() => handleOpenDialog('professional')}>
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </LandingPage>

      {openDialogImport ? (
        <DialogImport
          open={openDialogImport}
          handleClose={() => setOpenDialogImport(false)}
          handleOpen={() => handleOpenDialog(true)}
          type={dialogType}
        />
      ) : null}
    </>
  );
}
