import React from 'react';

import './styles.css';

export default function BoardHeaderPhoto({ src, alt }) {
  return (
    <div className="board-header-photo">
      <img src={src} alt={alt} />
    </div>
  );
}