import React, { useState, useEffect, useCallback } from 'react';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import ProfessionalLookup from '../ProfessionalLookup';
import DialogFilter from './DialogFilter';

import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
  },
  listItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    padding: '5px 16px 5px 16px',
    transition: 'border-width 0.2s',
    '&:hover, &:focus': {
      borderRightWidth: '10px !important',
    },
  },
}));

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={500}
    viewBox="0 0 250 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
    style={{ marginTop: 30 }}
  >
    <rect x="25" y="7" rx="3" ry="3" width="200" height="15" />
    <rect x="25" y="26" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="43" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="94" rx="3" ry="3" width="200" height="15" />
    <rect x="25" y="113" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="130" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="181" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="200" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="217" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="262" rx="3" ry="3" width="200" height="15" />
    <rect x="28" y="281" rx="3" ry="3" width="100" height="12" />
    <rect x="28" y="298" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="344" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="363" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="380" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="430" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="449" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="466" rx="3" ry="3" width="100" height="12" />
  </ContentLoader>
);

export default function ProfessionalControlContentList({
  selected,
  onSelectProfessional,
}) {
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filter, setFilter] = useState(null);
  const [name, setName] = useState('');

  const handleNavigationAllocation = useCallback(
    professionalId => {
      if (onSelectProfessional) {
        onSelectProfessional(professionalId);
      }
    },
    [onSelectProfessional],
  );

  const classes = useStyles();
  const [professionals, setProfessionals] = useState([]);

  const loadProfessionals = useCallback(async () => {
    try {
      setLoading(true);

      if (filter && Object.keys(filter).length > 0) {
        const response = await api.get(
          `master/professionals?where=${JSON.stringify(filter)}`,
        );
        setProfessionals(response.data);
      } else {
        const response = await api.get(`master/professionals`);
        setProfessionals(response.data);
      }

      setLoading(false);
    } catch (ex) {
      console.log('erro carregar projetos', ex);
    }
  }, [filter]);

  useEffect(() => {
    loadProfessionals();
  }, [filter, loadProfessionals]);

  useEffect(() => {
    setSelectedProfessional(Number(selected));
  }, [selected]);

  const handleOnFilter = useCallback(filter => {
    if (filter && Object.keys(filter).length >= 0) {
      setOpenFilterDialog(false);
      setFilter(filter);
    }
  }, []);

  const handleSearch = useCallback(
    e => {
      e.preventDefault();

      setFilter({
        Name: name,
        IsActive: true,
      });
    },
    [name],
  );

  return (
    <>
      <div className="home-item-content-sub-header-wrapper">
        <div className="boards-list-header-component selected">
          <form onSubmit={handleSearch}>
            <FormControl>
              <InputLabel
                htmlFor="professional-name-filter"
                style={{ fontSize: 14 }}
              >
                Filter by name...
              </InputLabel>
              <Input
                id="professional-name-filter"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                endAdornment={
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        size="small"
                        type="submit"
                      >
                        <FiSearch />
                      </IconButton>
                    </InputAdornment>

                    <InputAdornment position="end">
                      <IconButton
                        aria-label="More filters"
                        onClick={() => setOpenFilterDialog(true)}
                        size="small"
                      >
                        <FiFilter />
                      </IconButton>
                    </InputAdornment>
                  </>
                }
                inputProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
              />
            </FormControl>
          </form>
        </div>
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {!loading ? (
          professionals.map(item => (
            <ListItem
              button
              selected={item.ID === selectedProfessional}
              className={classes.listItem}
              key={item.ID}
              onClick={() => handleNavigationAllocation(item.ID)}
            >
              <ProfessionalLookup
                key={item.ID}
                Name={item.Name}
                Login={item.Login}
                JobTitle={item.JobTitle}
              />
            </ListItem>
          ))
        ) : (
          <Loader />
        )}
      </List>

      {openFilterDialog ? (
        <DialogFilter
          open={openFilterDialog}
          filter={filter}
          onFilter={handleOnFilter}
          handleClose={() => setOpenFilterDialog(false)}
        />
      ) : null}
    </>
  );
}
