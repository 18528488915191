import React, { useState, useCallback, useEffect } from 'react';

import { Button, Drawer, Box, Typography, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import api from '../../../services/api';

import { errorMessage$, successMessage$, infoMessage$ } from '../../../utils/subjects';

export default function Margin({ open, handleClose, handleOpen }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [projectCode, setProjectCode] = useState('');
  const [filterOption, setFilterOption] = useState('date');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleGenerate = useCallback(async () => {
    try {
      setErrorMessage(null);
      infoMessage$.next({ open: true, message: 'We generating your report. It could take a while' });

      if (projectCode) {
        const response = await api.get('scheduling/reports/finances/margin', {
          params: {
            projectCode,
          },
          responseType: 'blob',
        });
  
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `report-margin-${projectCode}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        successMessage$.next({ open: true, message: 'Your report is ready. Open or save it below' });

        return;
      }

      const response = await api.get('scheduling/reports/finances/margin', {
        params: {
          startDate,
          endDate,
        },
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-margin-period.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      successMessage$.next({ open: true, message: 'Your report is ready. Open or save it below' });
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Something were wrong with your report. Try again please.' });
    }
  }, [endDate, projectCode, startDate]);

  useEffect(() => {
    setProjectCode('');
    setStartDate(new Date());
    setEndDate(new Date());
  }, [filterOption])

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2} minWidth={300}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Margin
        </Typography>

        {errorMessage && (
          <Alert severity="error" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">{errorMessage}</Typography>
          </Alert>
        )}

        <FormControl fullWidth style={{ marginTop: 15 }}>
          <InputLabel id="filter-label">Select a filter option</InputLabel>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={filterOption}
            onChange={e => setFilterOption(e.target.value)}
          >
            <MenuItem value="date" selected>Date</MenuItem>
            <MenuItem value="project">Project Code</MenuItem>
          </Select>
        </FormControl>

        {
          filterOption === 'date' ?
          (
            <>
              <FormControl fullWidth style={{ marginTop: 15 }}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MMM/yyyy"
                  margin="normal"
                  id="date-picker-start-date"
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginTop: 15 }}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MMM/yyyy"
                  margin="normal"
                  id="date-picker-end-date"
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </>
          ) : (
            <FormControl fullWidth style={{ marginTop: 15 }}>
              <TextField 
                id="project-code" 
                label="Project code" 
                value={projectCode} 
                onChange={e => setProjectCode(e.target.value)} 
                placeholder="Ex: CTI005"
              />
            </FormControl>
          )
        }

        

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button
            onClick={handleGenerate}
            variant="contained"
            color="primary"
            size="small"
          >
            Generate
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}