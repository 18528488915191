import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import ApplicationLayers from './components/ApplicationLayers';
import AppProvider from './hooks';
import schedulingGroups from './config/schedulingGroups';

import Home from './pages/Home';
import Admin from './pages/Admin';
import Allocation from './pages/Allocation';
import Professional from './pages/Professional';
import FinanceHome from './pages/Finance/Home';
import TransferPrice from './pages/Finance/TransferPrice';
import TaxRate from './pages/Finance/TaxRate';
import CostRate from './pages/Finance/CostRate';
import AuxJobTitle from './pages/Finance/AuxJobTitle';
import ExchangeRate from './pages/Finance/ExchangeRate';
import FinanceImportData from './pages/Finance/ImportData';
import Currency from './pages/Finance/Currency';
import PracticeMargin from './pages/Finance/PracticeMargin';
import ProjectHome from './pages/ProjectHome';
import Report from './pages/Report';
import Signin from './pages/Auth/Signin';
import HRProfessional from './pages/HR/Professional';
import HRProfessionalHome from './pages/HR/ProfessionalHome';
import HRHome from './pages/HR/Home';
import HRImportData from './pages/HR/ImportData';
import DateOutType from './pages/HR/DateOutType';
import Client from './pages/Admin/Client';
import ProjectAdm from './pages/Admin/Project';
import EconomicGroup from './pages/Admin/EconomicGroup';
import Reason from './pages/Admin/Reason';
import AllocationHome from './pages/AllocationHome';
import ProfessionalHome from './pages/ProfessionalHome';
import Project from './pages/Project';
import SimulationAllocation from './pages/SimulationAllocation';
import SimulationHome from './pages/SimulationAllocation/Home';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AppProvider>
          <PublicRoute path="/" exact component={Signin} />
          <PublicRoute path="/auth/signin" exact component={Signin} />

          <ApplicationLayers>
            <PrivateRoute path="/app" exact component={Home} pageTitle="Home" />

            <PrivateRoute
              path="/admin"
              exact
              component={Admin}
              pageTitle="Administrator"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            <PrivateRoute
              path="/admin/reasons"
              exact
              component={Reason}
              pageTitle="Reasons"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            {/* <PrivateRoute path="/administrator/clients" exact component={Client} pageTitle="Clients" scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]} /> */}
            {/* <PrivateRoute path="/administrator/projects" exact component={ProjectAdm} pageTitle="Projects" scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]} /> */}
            {/* <PrivateRoute path="/administrator/economic-groups" exact component={EconomicGroup} pageTitle="Economic Groups" scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]} /> */}

            <PrivateRoute
              path="/professionals"
              exact
              component={ProfessionalHome}
              pageTitle="Professional"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
                schedulingGroups.ReadAll,
              ]}
            />
            <PrivateRoute
              path="/professionals/:professionalId"
              exact
              component={Professional}
              pageTitle="Professional"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
                schedulingGroups.ReadAll,
              ]}
            />

            <PrivateRoute
              path="/finances"
              exact
              component={FinanceHome}
              pageTitle="Finance"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/transfer-prices"
              exact
              component={TransferPrice}
              pageTitle="Transfer Prices"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/base-rates"
              exact
              component={TaxRate}
              pageTitle="Base Rate"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/cost-rates"
              exact
              component={CostRate}
              pageTitle="Cost Rate"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/exchange-rates"
              exact
              component={ExchangeRate}
              pageTitle="Exchange Rate"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/currencies"
              exact
              component={Currency}
              pageTitle="Currency"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/practices-margins"
              exact
              component={PracticeMargin}
              pageTitle="Practice Margin"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/import"
              exact
              component={FinanceImportData}
              pageTitle="Finance Import Data"
              scopes={[schedulingGroups.Admin]}
            />
            <PrivateRoute
              path="/finances/aux-jobtitles"
              exact
              component={AuxJobTitle}
              pageTitle="Aux JobTitle"
              scopes={[schedulingGroups.Admin]}
            />

            <PrivateRoute
              path="/reports"
              exact
              component={Report}
              pageTitle="Reports"
            />

            <PrivateRoute
              path="/hr"
              exact
              component={HRHome}
              pageTitle="HR"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            <PrivateRoute
              path="/hr/professionals"
              exact
              component={HRProfessionalHome}
              pageTitle="HR Professional Home"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            <PrivateRoute
              path="/hr/date-out-types"
              exact
              component={DateOutType}
              pageTitle="HR Date out types"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            <PrivateRoute
              path="/hr/import"
              exact
              component={HRImportData}
              pageTitle="HR Import"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />
            <PrivateRoute
              path="/hr/professionals/:professionalId"
              exact
              component={HRProfessional}
              pageTitle="HR Professional"
              scopes={[schedulingGroups.Admin, schedulingGroups.Allocation]}
            />

            <PrivateRoute
              path="/allocations"
              exact
              component={AllocationHome}
              pageTitle="Allocation Home"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.Manager,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
                schedulingGroups.ReadAll,
              ]}
            />
            {/* <PrivateRoute path="/projects" exact component={ProjectHome} pageTitle="Projects" />
            <PrivateRoute path="/projects/:projectId" exact component={Project} pageTitle="Projects" />*/}
            <PrivateRoute
              path="/projects/:projectId/allocations"
              exact
              component={Allocation}
              pageTitle="Allocation"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.Manager,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
                schedulingGroups.ReadAll,
              ]}
            />

            <PrivateRoute
              path="/simulation"
              exact
              component={SimulationHome}
              pageTitle="Simulation Home"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.Manager,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
              ]}
            />
            <PrivateRoute
              path="/simulation/:projectId/allocations"
              exact
              component={SimulationAllocation}
              pageTitle="Simulation"
              scopes={[
                schedulingGroups.Admin,
                schedulingGroups.Allocation,
                schedulingGroups.Manager,
                schedulingGroups.CoManager,
                schedulingGroups.TechLab,
              ]}
            />
          </ApplicationLayers>
        </AppProvider>
      </Switch>
    </BrowserRouter>
  );
}
