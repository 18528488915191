import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FiFilter, FiSearch } from "react-icons/fi";
import {
  List,
  ListItem,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ContentLoader from "react-content-loader";

import { statusColors } from "../ChipStatus";

import DialogFilter from "./DialogFilter";

import api from "../../services/api";

import './styles.css';

import { errorMessage$ } from '../../utils/subjects';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
  },
  listItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
    padding: "5px 16px 5px 16px",
    transition: "border-width 0.2s",
    "&:hover, &:focus": {
      borderLeftWidth: "10px !important",
    },
  },
}));

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={500}
    viewBox="0 0 250 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#dedede"
    style={{marginTop: 30}}
  >
    <rect x="25" y="7" rx="3" ry="3" width="200" height="15" />
    <rect x="25" y="26" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="43" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="94" rx="3" ry="3" width="200" height="15" />
    <rect x="25" y="113" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="130" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="181" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="200" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="217" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="262" rx="3" ry="3" width="200" height="15" />
    <rect x="28" y="281" rx="3" ry="3" width="100" height="12" />
    <rect x="28" y="298" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="344" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="363" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="380" rx="3" ry="3" width="100" height="12" />
    <rect x="25" y="430" rx="3" ry="3" width="200" height="15" />
    <rect x="27" y="449" rx="3" ry="3" width="100" height="12" />
    <rect x="27" y="466" rx="3" ry="3" width="100" height="12" />
  </ContentLoader>
);

export default function ProjectControlContentList({
  newProject,
  editedProject,
  selected,
  onSelectProject,
}) {

  const [page, setPage] = useState(1);
  const [totalProjects, setTotalProjects] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filter, setFilter] = useState(null);
  const [code, setCode] = useState("");
  const [projects, setProjects] = useState([]);
  const [focus, setFocus] = useState(false);

  const classes = useStyles();

  const loadProjects = useCallback(async () => {
    try {
      setLoading(true);

      const params = { page };

      if (filter && Object.keys(filter).length > 0) {
        params.where = JSON.stringify(filter);
      }

      const response = await api.get(`scheduling/projects`, {
        params,
      });

      setTotalProjects(Number(response.headers["x-total-count"]));
      setProjects((state) => [...state, ...response.data]);
      setLoading(false);
    } catch (ex) {
      errorMessage$.next({ open: true, message: 'Something went wrong with project list' });
    }
  }, [filter, page]);

  useEffect(() => {
    setPage(1);
    setProjects([]);
  }, [filter]);

  const handleNavigationAllocation = useCallback(
    (projectId) => {
      if (onSelectProject) onSelectProject(projectId);
    },
    [onSelectProject]
  );

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  useEffect(() => {
    setSelectedProject(Number(selected));
  }, [selected]);

  useEffect(() => {
    if (newProject && newProject.ID) {
      setProjects((items) => [newProject, ...items]);
    }
  }, [newProject]);

  useEffect(() => {
    if (editedProject && editedProject.ID) {
      setProjects((projects) => {
        const copiedProject = [...projects];
        const index = projects.findIndex(
          (el) => Number(el.ID) === Number(editedProject.ID)
        );
        if (index && index !== -1) {
          console.log('index', index);
          if (editedProject.ProjectName) {
            copiedProject[index].ProjectName = editedProject.ProjectName;
          }
          if (editedProject.ProjectCode) {
            copiedProject[index].ProjectCode = editedProject.ProjectCode;
          }
          if (editedProject.ProjectStatus) {
            copiedProject[index].ProjectStatus.Description =
              editedProject.ProjectStatus;
          }
        }
        
        return [...copiedProject];
      });
    }
  }, [editedProject]);

  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();

      setFilter({
        ProjectCode: code,
      });
    },
    [code]
  );

  const handleOnFilter = useCallback((filter) => {
    if (filter && Object.keys(filter).length >= 0) {
      setOpenFilterDialog(false);
      setFilter(filter);
    }
  }, []);

  const onScroll = useCallback(
    (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

      if (loading) return;

      if (totalProjects > 0 && projects.length === totalProjects) return;

      if (bottom) {
        setPage((state) => state + 1);
      }
    },
    [loading, projects, totalProjects]
  );

  return (
    <>
      <div className="home-item-content-sub-header-wrapper">
        <div className="boards-list-header-component selected">
          <form onSubmit={handleSearch}>
            <FormControl>
              <InputLabel
                htmlFor="project-code-filter"
                style={{ fontSize: 14 }}
              >
                Filter by code...
              </InputLabel>
              <Input
                id="project-code-filter"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                endAdornment={
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        size="small"
                        type="submit"
                      >
                        <FiSearch />
                      </IconButton>
                    </InputAdornment>

                    <InputAdornment position="end">
                      <IconButton
                        aria-label="More filters"
                        onClick={() => setOpenFilterDialog(true)}
                        size="small"
                      >
                        <FiFilter />
                      </IconButton>
                    </InputAdornment>
                  </>
                }
                inputProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
              />
            </FormControl>
          </form>
        </div>
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
        onScroll={onScroll}
      >
        {projects.map((item) => (
          <ListItem
            button
            selected={item.ID === selectedProject}
            className={classes.listItem}
            key={item.ID}
            onClick={() => handleNavigationAllocation(item.ID)}
            style={{
              borderLeft: `4px solid ${
                statusColors[item.ProjectStatus.Description]
              }`,
            }}
          >
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                >
                  {item.ProjectCode}
                </Typography>
              }
              secondary={
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    component="span"
                    style={{ fontSize: 11, marginBottom: 5 }}
                  >
                    {item.ProjectName}
                  </Typography>

                  <Typography
                    component="span"
                    style={{ fontSize: 11, margin: 0 }}
                  >
                    {item.Client && item.Client.Name}
                  </Typography>
                </span>
              }
              style={{ margin: 0 }}
            />
          </ListItem>
        ))}

        {!loading && projects.length <= 0 && 
          <div style={{ display: 'flex', flex: 1, textAlign: 'center', marginTop: 15, alignItems: 'center', justifyContent: 'center' }}>
            No data
          </div>
        }

        {loading && <Loader />}
      </List>

      {openFilterDialog ? (
        <DialogFilter
          open={openFilterDialog}
          filter={filter}
          onFilter={handleOnFilter}
          handleClose={() => setOpenFilterDialog(false)}
        />
      ) : null}
    </>
  );
}
