import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';
import { set } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogDelete({
  handleClose,
  handleOpen,
  open,
  id,
  projectId,
  callback,
}) {
  const classes = useStyles();
  const [projectSellValueTypeId] = useState(id);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      try {
        setLoading(true);
        await api.delete(
          `scheduling/projects/${projectId}/sell-value-types/${projectSellValueTypeId}`,
        );

        successMessage$.next({ open: true, message: 'Deleted successfully' });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({ open: true, message: 'Error on delete' });
      } finally {
        setLoading(false);
        handleClose();
      }
    },
    [callback, handleClose, projectId, projectSellValueTypeId],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Do you confirm?</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent>
          <p>Do you would like to delete this item?</p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} type="submit">
            {loading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
          <Button onClick={handleClose} disabled={loading} color="default">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
