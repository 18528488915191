import React from 'react';
import Surface from '../Surface';

import './styles.css';
import Tools from '../ToolsMenu';

export default function FirstLevelContent({ children, collapse, hasControl }) {
  return (
    <div
      className={`first-level-content-wrapper 
      ${
        collapse ? 'first-level-control-unpinned' : 'first-level-control-pinned'
      }
      ${
        hasControl
          ? 'first-level-control-pinned'
          : 'first-level-control-nocontrol'
      }
      `}
    >
      <div className="first-level-content">
        <div className="current-board-component">{children}</div>
      </div>
    </div>
  );
}
