import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Surface from '../Surface';
import FirstLevel from '../FirstLevel';

import {
  errorMessage$,
  successMessage$,
  infoMessage$,
} from '../../utils/subjects';

import './styles.css';
import Tools from '../ToolsMenu';

export default function ApplicationLayers({ children }) {
  const [errorMessage, setErrorMessage] = useState({
    open: false,
    message: null,
  });
  const [successMessage, setSuccessMessage] = useState({
    open: false,
    message: null,
  });
  const [infoMessage, setInfoMessage] = useState({
    open: false,
    message: null,
  });

  function handleErrorClose() {
    errorMessage$.next({ open: false, message: null });
  }

  function handleSuccessClose() {
    successMessage$.next({ open: false, message: null });
  }

  function handleInfoClose() {
    infoMessage$.next({ open: false, message: null });
  }

  useEffect(() => {
    errorMessage$.subscribe(result => {
      setErrorMessage(result);
    });
    successMessage$.subscribe(result => {
      setSuccessMessage(result);
    });
    infoMessage$.subscribe(result => {
      setInfoMessage(result);
    });
  }, []);

  return (
    <>
      {errorMessage.open ? (
        <Snackbar
          open={errorMessage.open}
          autoHideDuration={6000}
          onClose={handleErrorClose}
        >
          <Alert onClose={handleErrorClose} severity="error" variant="filled">
            {errorMessage.message || 'Error. Please try again'}
          </Alert>
        </Snackbar>
      ) : null}

      {successMessage.open ? (
        <Snackbar
          open={successMessage.open}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
        >
          <Alert
            onClose={handleSuccessClose}
            severity="success"
            variant="filled"
          >
            {successMessage.message || 'Success'}
          </Alert>
        </Snackbar>
      ) : null}

      {infoMessage.open ? (
        <Snackbar
          open={infoMessage.open}
          autoHideDuration={6000}
          onClose={handleInfoClose}
        >
          <Alert onClose={handleInfoClose} severity="info" variant="filled">
            {infoMessage.message || 'Information'}
          </Alert>
        </Snackbar>
      ) : null}

      <Surface />

      <FirstLevel>{children}</FirstLevel>
    </>
  );
}
