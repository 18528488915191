import React from 'react';

import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

export default function Upload({ onUpload }) {
  function renderDragMessage(
    isDragActive,
    isDragRejest,
  ) {
    if (!isDragActive) {
      return (
        <UploadMessage>Click or drop your file here</UploadMessage>
      );
    }

    if (isDragRejest) {
      return <UploadMessage type="error">File not allowed</UploadMessage>;
    }

    return <UploadMessage type="success">Drop your file here</UploadMessage>;
  }

  return (
    <>
      <Dropzone
        accept=".csv, application/vnd.ms-excel, text/csv, .xlsx"
        onDropAccepted={files => onUpload(files)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} data-testid="upload" multiple={false} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    </>
  );
};