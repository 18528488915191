import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FiCalendar,
  FiUsers,
  FiSettings,
  FiDollarSign,
  FiCoffee,
  FiBarChart2,
  FiFolder,
  FiHelpCircle,
  FiCpu,
} from 'react-icons/fi';
import Tooltip from '@material-ui/core/Tooltip';

import integrationLogo from '../../assets/images/sch-logo.svg';

import './styles.css';
import { useAuth } from '../../hooks/auth';
import { config } from '../../config/msAuth';
import MSAuth from '../../services/ms-auth';
import schedulingGroups from '../../config/schedulingGroups';

export default function Surface() {
  const { user } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [userLogin, setUserLogin] = useState('');

  const history = useHistory();

  function handleNavigationHome() {
    history.push('/app');
  }

  function handleNavigationAdmin() {
    history.push('/admin');
  }

  // function handleNavigationProjects() {
  //   history.push('/projects');
  // }

  function handleNavigationAllocation() {
    history.push('/allocations');
  }

  function handleNavigationProfessional() {
    history.push('/professionals');
  }

  function handleNavigationFinance() {
    history.push('/finances');
  }

  function handleNavigationReport() {
    history.push('/reports');
  }

  function handleNavigationHR() {
    history.push('/hr');
  }

  function handleNavigationSimulation() {
    history.push('/simulation');
  }

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const parsedUserLogin = userEmail?.split('@')[0];
    setUserLogin(parsedUserLogin);
  }, []);

  const logout = useCallback(() => {
    const msAuth = new MSAuth(config);

    localStorage.clear();
    const uri = msAuth.logout();
    window.location.href = uri;
  }, []);

  return (
    <div className="surface">
      <div className="surface-control" id="surface-control">
        <div className="surface-top">
          <div className="surface-company-logo-component">
            <Tooltip title="Dashboard" placement="right">
              <span className="surface-company-logo-image-wrapper">
                <img
                  className="surface-company-logo-image"
                  alt="Integration Scheduling"
                  src={integrationLogo}
                  onClick={handleNavigationHome}
                />
              </span>
            </Tooltip>
          </div>
          <Tooltip title="Allocation" placement="right">
            <div
              className="surface-navigation-dialog-item-component"
              onClick={handleNavigationAllocation}
            >
              <FiCalendar className="navigation-dialog-item-icon" />
            </div>
          </Tooltip>
          {userScopes.some(scope =>
            [
              schedulingGroups.Admin,
              schedulingGroups.Allocation,
              schedulingGroups.Manager,
              schedulingGroups.CoManager,
              schedulingGroups.TechLab,
            ].includes(scope),
          ) && (
            <Tooltip title="My Simulations" placement="right">
              <div
                className="surface-navigation-dialog-item-component"
                onClick={handleNavigationSimulation}
              >
                <FiCpu className="navigation-dialog-item-icon" />
              </div>
            </Tooltip>
          )}

          {userScopes.some(scope =>
            [
              schedulingGroups.Admin,
              schedulingGroups.Allocation,
              schedulingGroups.Manager,
              schedulingGroups.CoManager,
              schedulingGroups.TechLab,
              schedulingGroups.ReadAll,
            ].includes(scope),
          ) && (
            <Tooltip title="Professionals" placement="right">
              <div
                className="surface-navigation-dialog-item-component"
                onClick={handleNavigationProfessional}
              >
                <FiUsers className="navigation-dialog-item-icon" />
              </div>
            </Tooltip>
          )}
          {/* <Tooltip title="Projects" placement="right">
                    <div className="surface-navigation-dialog-item-component" onClick={handleNavigationProjects}>
                      <FiFolder className="navigation-dialog-item-icon" />
                    </div>
                  </Tooltip> */}
          {userScopes.some(scope =>
            [schedulingGroups.Admin].includes(scope),
          ) && (
            <Tooltip title="Finance" placement="right">
              <div
                className="surface-navigation-dialog-item-component"
                onClick={handleNavigationFinance}
              >
                <FiDollarSign className="navigation-dialog-item-icon" />
              </div>
            </Tooltip>
          )}
          {userScopes.some(scope =>
            [schedulingGroups.Admin, schedulingGroups.Allocation].includes(
              scope,
            ),
          ) && (
            <Tooltip title="HR" placement="right">
              <div
                className="surface-navigation-dialog-item-component"
                onClick={handleNavigationHR}
              >
                <FiCoffee className="navigation-dialog-item-icon" />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Reports" placement="right">
            <div
              className="surface-navigation-dialog-item-component"
              onClick={handleNavigationReport}
            >
              <FiBarChart2
                className="navigation-dialog-item-icon"
                title="Reports"
              />
            </div>
          </Tooltip>
        </div>

        <div className="surface-bottom">
          {userScopes.some(scope =>
            [schedulingGroups.Admin, schedulingGroups.Allocation].includes(
              scope,
            ),
          ) && (
            <Tooltip title="Settings" placement="right">
              <div
                className="surface-navigation-dialog-item-component"
                onClick={handleNavigationAdmin}
              >
                <FiSettings
                  className="navigation-dialog-item-icon"
                  title="Settings"
                />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Help" placement="right">
            <div
              className="surface-navigation-dialog-item-component"
              onClick={e => {
                e.preventDefault();
                window.location =
                  'mailto:newscheduling@integrationconsulting.com';
              }}
            >
              <FiHelpCircle className="navigation-dialog-item-icon" />
            </div>
          </Tooltip>
          <Tooltip title="Logoff" placement="right">
            <div
              className="surface-navigation-dialog-item-component"
              onClick={logout}
            >
              <img
                className="professional-image navigation"
                alt={userLogin}
                src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${userLogin}_192_192.png`}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
