import React, { useCallback, useState } from 'react';

import { Drawer, Box, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { WhisperSpinner } from 'react-spinners-kit';
import { errorMessage$, successMessage$ } from '../../utils/subjects.js';
import GridLoader from './GridLoader.js';
import api, { IChangeToApprovedBulk } from '../../services/api';

interface DialogChangeStatusProps {
  projectIds: number[];
  projectCodes: string[];
  handleClose(): void;
  open: boolean;
  onSubmitted(): void;
}

const DialogWaitingValidation: React.FC<DialogChangeStatusProps> = ({
  handleClose,
  onSubmitted,
  open,
  projectIds,
  projectCodes,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.patch<IChangeToApprovedBulk>(
        '/scheduling/projects/status/waiting-validation/bulk',
        {
          projectIds,
        },
      );

      setLoading(false);

      if (data.status === 'error') {
        setErrorMessage(data.errors);
        onSubmitted();
      } else {
        handleClose();
        onSubmitted();
        successMessage$.next({
          open: true,
          message: 'Projects status has been change to Waiting Validation!',
        });
      }
    } catch {
      setLoading(false);
      errorMessage$.next({
        open: true,
        message: 'Something went wrong, please try again...',
      });
    }
  }, [handleClose, onSubmitted, projectIds]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading ? (
        <GridLoader>
          <WhisperSpinner size={50} frontColor="#8b0304" backColor="#8b0304" />
        </GridLoader>
      ) : null}

      <Box m={2} style={{ minWidth: 350, maxWidth: '100%' }}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Change to Waiting Validation Projects
        </Typography>

        {!!errorMessage && (
          <Alert severity="error" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">
              {errorMessage}
            </Typography>
          </Alert>
        )}

        <Alert severity="info" style={{ marginTop: 15 }}>
          <Typography variant="inherit" component="p">
            You will change several projects status to Waiting Validation:
          </Typography>
          <Typography variant="inherit" component="p">
            <ul style={{ marginLeft: '1rem' }}>
              {projectCodes.map(code => (
                <li style={{ fontWeight: 700 }}>{code}</li>
              ))}
            </ul>
          </Typography>
        </Alert>

        <div style={{ position: 'fixed', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Change status
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};

export default DialogWaitingValidation;
