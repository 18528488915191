import React, { useState, useEffect, useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
  Button,
  FormGroup,
  Typography,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import {
  FiFolder,
  FiSettings,
  FiUser,
  FiHome,
  FiPercent,
  FiDollarSign,
  FiCalendar,
  FiShoppingBag,
  FiMapPin,
  FiInfo,
  FiGitPullRequest,
  FiGrid,
  FiSliders,
} from 'react-icons/fi';
import {
  format,
  parseISO,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addDays,
} from 'date-fns';
import PropTypes from 'prop-types';

import ContentLoader from 'react-content-loader';
import CustomStore from 'devextreme/data/custom_store';
import { useControlContent } from '../../../hooks/controlContent';
import ProjectControlContentList from '../../ProjectControlContentList';
import SellValueTypeList from '../../../pages/Project/Tabs/SellValueType';

import TabPanel from '../../TabPanel';
import Loader from '../../../pages/Finance/ImportData/Loader';

import './styles.css';

import { errorMessage$ } from '../../../utils/subjects';

import NumberFormatValue from '../../NumberFormat';
import InputInlineEdit from '../../InputInlineEdit';
import SelectInlineEdit from '../../SelectInlineEdit';

import api from '../../../services/api';
import schedulingGroups from '../../../config/schedulingGroups';
import { useAuth } from '../../../hooks/auth';
import { ReactComponent as IdOneLogo } from '../../../assets/images/IDOne_Azul.svg';

const CustomContentLoader = ({ width }) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={14}
      viewBox={`0 0 ${width} 14`}
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
    >
      <rect x="0" y="0" rx="7" ry="7" width={width} height="14" />
    </ContentLoader>
  );
};

CustomContentLoader.propTypes = {
  width: PropTypes.number.isRequired,
};

export default function ProjectInfo({ onChange, projectId }) {
  const { user } = useAuth();

  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
  ]);
  const [allowEdit] = useState(() =>
    userScopes.some(scope => allowEditScopes.includes(scope)),
  );
  const [currentMargin, setCurrentMargin] = useState();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [client, setClient] = useState(null);
  const [clientName, setClientName] = useState('');
  const [businessUnit, setBusinessUnit] = useState(null);
  const [businessUnitName, setBusinessUnitName] = useState('');
  const [projectPartner, setProjectPartner] = useState(null);
  const [projectPartnerName, setProjectPartnerName] = useState('');
  const [projectManager, setProjectManager] = useState(null);
  const [projectManagerName, setProjectManagerName] = useState('');
  const [currency, setCurrency] = useState(null);
  const [currencyName, setCurrencyName] = useState('');
  const [currencyDisplayName, setCurrencyDisplayName] = useState('');
  const [comanagement, setComanagement] = useState(null);
  const [comanagementName, setComanagementName] = useState('');
  const [supportComanagement, setSupportComanagement] = useState(null);
  const [supportComanagementName, setSupportComanagementName] = useState('');
  const [office, setOffice] = useState(null);
  const [billingOffice, setBillingOffice] = useState(null);
  const [geographic, setGeographic] = useState(null);
  const [geographicName, setGeographicName] = useState('');
  const [cityState, setCityState] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [billingOfficeName, setBillingOfficeName] = useState('');
  const [marginSale, setMarginSale] = useState('');
  const [marginSaleDisplay, setMarginSaleDisplay] = useState('');
  const [margin, setMargin] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [originalEndDate, setOriginalEndDate] = useState('');
  const [lastChargedExtensionEndDate, setLastChargedExtensionEndDate] =
    useState('');
  const [valueLoading, setValueLoading] = useState(false);
  const [projectLocalValue, setProjectLocalValue] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [firstAllocationMargin, setFirstAllocationMargin] = useState('');
  const [missingDataInfo, setMissingDataInfo] = useState('');
  const [isLegacy, setIsLegacy] = useState(false);
  const [mainCurrencyId, setMainCurrencyId] = useState(2);
  const [tab, setTab] = useState(0);

  const [projectValueTotal, setProjectValueTotal] = useState('');
  const [projectValueTotalDisplay, setProjectValueTotalDisplay] = useState('');
  const [projectValueInitial, setProjectValueInitial] = useState('');
  const [projectValueInitialDisplay, setProjectValueInitialDisplay] =
    useState('');
  const [projectValueMMC, setProjectValueMMC] = useState('');
  const [projectValueMMCDisplay, setProjectValueMMCDisplay] = useState('');
  const [mainCurrency, setMainCurrency] = useState('BRL');
  const [lastWeekMargin, setLastWeekMargin] = useState('');

  const [weeks, setWeeks] = useState([]);

  const onChangeInputValue = useCallback(
    async data => {
      setValueLoading(true);
      if (data.ProjectValueInitial) {
        setProjectValueInitial(data.ProjectValueInitial);
      }

      if (
        data.MarginSale &&
        userScopes &&
        !userScopes.includes(schedulingGroups.ReadAll)
      ) {
        setMarginSale(data.MarginSale);
      }

      if (onChange)
        onChange({
          ID: Number(projectId),
          ...data,
        });

      try {
        const project = await api.put(`scheduling/projects/${projectId}`, data);

        const margin = await api.get(`scheduling/projects/${projectId}/margin`);

        setValueLoading(false);
        if (userScopes && !userScopes.includes(schedulingGroups.ReadAll)) {
          setMargin(
            margin.data.MarginCurrent
              ? `${margin.data.MarginCurrent.toFixed(2)}%`
              : '',
          );
        }
        setProjectValueMMC(
          project.data.MainProjectValueMMC
            ? project.data.MainProjectValueMMC.toFixed(2)
            : '',
        );
        setProjectValueTotal(
          project.data.MainProjectValueTotal
            ? project.data.MainProjectValueTotal.toFixed(2)
            : '',
        );
      } catch (err) {
        console.log(err);
        setValueLoading(false);
        errorMessage$.next({
          open: true,
          message: 'Error when updating project',
        });
      }
    },
    [onChange, projectId, userScopes],
  );

  const onChangeCurrency = useCallback(data => {
    if (data.Currency) {
      const parsedCurrency = data.Currency.split('-')[0].trim();
      setCurrencyName(parsedCurrency);
    }
  }, []);

  const loadProjectInfo = useCallback(async () => {
    try {
      setLoading(true);

      const [project, parsedMargin] = await Promise.all([
        api.get(`scheduling/projects/${projectId}`),
        api.get(`scheduling/projects/${projectId}/margin`),
      ]);

      setName(project.data.ProjectName);
      setCode(project.data.ProjectCode);
      setClient(project.data.Client && project.data.Client.ID);
      setClientName(project.data.Client && project.data.Client.Name);
      setBusinessUnit(
        project.data.BusinessUnit && project.data.BusinessUnit.ID,
      );
      setBusinessUnitName(
        project.data.BusinessUnit && project.data.BusinessUnit.Name,
      );

      setComanagement(
        project.data.Comanagement && project.data.Comanagement.ID,
      );
      setMainCurrencyId(project.data.IDMainCurrency);
      setIsLegacy(project.data.IsLegacy);
      setMissingDataInfo(project.data.MissingFinancialDataInfo);

      setComanagementName(
        project.data.Comanagement && project.data.Comanagement.Name,
      );

      setSupportComanagement(
        project.data.SupportComanagement && project.data.SupportComanagement.ID,
      );
      setSupportComanagementName(
        project.data.SupportComanagement &&
          project.data.SupportComanagement.Name,
      );

      setOffice(project.data.Office.ID);
      setBillingOffice(
        project.data.BillingOffice && project.data.BillingOffice.ID,
      );
      setBillingOfficeName(
        project.data.BillingOffice && project.data.BillingOffice.Name,
      );
      setOfficeName(project.data.Office.Name);
      setGeographic(project.data.Geographic.ID);
      setGeographicName(project.data.Geographic.Name);
      setCityState(project.data.City);

      setWeeks(
        project.data.ProjectScheduleWeeks.map(x => ({
          ID: x.ScheduleWeek.WeekStartDate,
          Name: format(
            startOfWeek(addDays(parseISO(x.ScheduleWeek.WeekStartDate), 2), {
              weekStartsOn: 1,
            }),
            'dd MMM yyyy',
          ),
        })),
      );
      if (userScopes && !userScopes.includes(schedulingGroups.ReadAll)) {
        setCurrentMargin(project.data.MarginCurrent);
      }

      setProjectValueInitial(project.data.ProjectValueInitial);
      setProjectValueTotal(project.data.MainProjectValueTotal);
      setProjectValueMMC(project.data.MainProjectValueMMC);
      setMainCurrency(project.data.MainCurrency?.Name || 'BRL');
      setFirstAllocationMargin(project.data.MarginFirstAllocation);
      setProjectPartner(project.data.ProfessionalPartner.ID);
      setProjectPartnerName(project.data.ProfessionalPartner.Name);
      setProjectManager(project.data.ProfessionalManager.ID);
      setProjectManagerName(project.data.ProfessionalManager.Name);
      setCurrency(project.data.Currency.ID);
      setCurrencyName(project.data.Currency.Name);
      setCurrencyDisplayName(
        `${project.data.Currency.Name} - ${project.data.Currency.Abbreviation}`,
      );
      setMarginSale(project.data.MarginSale || '0');

      if (userScopes && !userScopes.includes(schedulingGroups.ReadAll)) {
        setMargin(
          parsedMargin.data.MarginCurrent
            ? `${parsedMargin.data.MarginCurrent.toFixed(2)}%`
            : '0',
        );
      }

      setStartDate(project.data.StartDateReal || project.data.StartDate);
      setEndDate(
        project.data.EndDate &&
          format(
            endOfWeek(subWeeks(parseISO(project.data.EndDate), 1), {
              weekStartsOn: 6,
            }),
            'dd MMM yyyy',
          ),
      );
      setOriginalEndDate(
        project.data.OriginalEndDate &&
          format(
            endOfWeek(subWeeks(parseISO(project.data.OriginalEndDate), 1), {
              weekStartsOn: 6,
            }),
            'dd MMM yyyy',
          ),
      );
      setLastChargedExtensionEndDate(
        project.data.ChargedExtensionDate &&
          format(
            endOfWeek(
              subWeeks(parseISO(project.data.ChargedExtensionDate), 1),
              {
                weekStartsOn: 6,
              },
            ),
            'dd MMM yyyy',
          ),
      );

      setProjectLocalValue(
        project.data.MainProjectValueTotalLocal
          ? new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: project.data?.Currency?.Name || 'BRL',
            }).format(project.data.MainProjectValueTotalLocal || 0)
          : 'Missing financial data',
      );

      setProjectValue(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: project.data?.MainCurrency?.Name || 'BRL',
        }).format(project.data.MainProjectValueTotal),
      );
      setLastWeekMargin(project.data.MarginLastWeek);

      setLoading(false);
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: `Error loading project information. (${err})`,
      });
    }
  }, [projectId, userScopes]);

  useEffect(() => {
    loadProjectInfo();
  }, [loadProjectInfo]);

  useEffect(() => {
    try {
      setProjectValueTotalDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: mainCurrency,
        }).format(projectValueTotal),
      );

      setProjectValueInitialDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: currencyName,
        }).format(projectValueInitial),
      );

      setProjectValueMMCDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: mainCurrency,
        }).format(projectValueMMC),
      );
    } catch (err) {
      setProjectValueTotalDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: mainCurrency,
        }).format(projectValueTotal),
      );

      setProjectValueInitialDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: mainCurrency,
        }).format(projectValueInitial),
      );

      setProjectValueMMCDisplay(
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: mainCurrency,
        }).format(projectValueMMC),
      );
    }
  }, [
    currencyName,
    projectValueInitial,
    projectValueMMC,
    projectValueTotal,
    mainCurrency,
  ]);

  useEffect(() => {
    setMarginSaleDisplay(`${marginSale}%`);
  }, [marginSale]);

  const [clientSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/clients', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [comanagementSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/comanagements');
        return [
          { ID: null, Name: '(Empty)' },
          ...data.filter(
            x =>
              x.Name !== 'Brazil' && x.Name !== 'Latam' && x.Name !== 'Europe',
          ),
        ];
      },
    }),
    paginate: true,
  });
  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [businessUnitSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/business-units');
        return data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currencyProps => ({
          ...currencyProps,
          DisplayName: `${currencyProps.Name} - ${currencyProps.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });
  const [officeSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/offices');
        return response.data;
      },
    }),
    paginate: true,
  });
  const [geographicSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/geographics');
        return response.data;
      },
    }),
    paginate: true,
  });

  const { setControlContent, setPageTitle } = useControlContent();
  const history = useHistory();

  const [status, setStatus] = useState('');
  const [comanagementId, setComanagementId] = useState('');
  const [categories, setCategories] = useState([]);
  const [editedProject, setEditedProject] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const handleNavigation = useCallback(
    projectId => {
      history.push(`/projects/${projectId}/allocations`);
    },
    [history],
  );

  const onChangeProject = useCallback(data => {
    if (data.ProjectName) {
      setName(data.ProjectName);
    }

    if (data.ProjectCode) {
      setCode(data.ProjectCode);
    }

    setEditedProject(data);
  }, []);

  useEffect(() => {
    setLoading(true);
    setTabValue(0);

    Promise.all([
      api.get(`scheduling/projects/${projectId}`),
      api.get(`scheduling/sell-value-types-categories`),
    ])
      .then(([project, parsedCategories]) => {
        setName(project.data.ProjectName);
        setCode(project.data.ProjectCode);
        setStatus(project.data.ProjectStatus.Description);
        setComanagementId(project.data.Comanagement.ID);

        setCategories(parsedCategories.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        errorMessage$.next({
          open: true,
          message: `Error on load project info ${err}`,
        });
      });
  }, [projectId]);

  const handleAllocationNavigation = useCallback(() => {
    history.push(`/projects/${projectId}/allocations`);
  }, [history, projectId]);

  useEffect(() => {
    setControlContent(
      <ProjectControlContentList
        onSelectProject={handleNavigation}
        editedProject={editedProject}
      />,
    );
    setPageTitle('Projects');
  }, [editedProject, handleNavigation, setControlContent, setPageTitle]);

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, newValue) => setTab(newValue)}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tooltip title="Info" arrow placement="top">
          <Tab icon={<FiInfo size={20} />} />
        </Tooltip>
        {categories.map(category => {
          let icon;
          switch (category.Description) {
            case 'Expenses':
              icon = <FiDollarSign size={20} />;
              break;
            case 'ID One':
              icon = <IdOneLogo className="idone-logo" />;
              break;
            case 'Value adjustments':
              icon = <FiSliders size={20} />;
              break;
            default:
              icon = <FiGitPullRequest size={20} />;
              break;
          }

          return (
            <Tooltip
              key={`category-${category.ID}`}
              title={category.Description}
              arrow
              placement="top"
            >
              <Tab key={`category-${category.ID}`} icon={icon} />
            </Tooltip>
          );
        })}
      </Tabs>
      <TabPanel value={tab} index={0} style={{ padding: 0 }}>
        <div className="big-numbers">
          <div className="project-info-box numbers ">
            <h3>Value</h3>
            {loading ? (
              <CustomContentLoader width={140} />
            ) : (
              <strong>{projectValue}</strong>
            )}
          </div>
          <div className="project-info-box numbers ">
            <h3>Local Value</h3>
            {loading ? (
              <CustomContentLoader width={140} />
            ) : (
              <strong>{projectLocalValue}</strong>
            )}
          </div>
          <div className="project-info-box numbers ">
            <h3>
              1<sup>st</sup> Allocation
            </h3>
            <strong>
              {mainCurrencyId === 2 && isLegacy ? (
                'N/A'
              ) : loading ? (
                <CustomContentLoader width={140} />
              ) : (
                `${firstAllocationMargin || 0}%`
              )}
            </strong>
          </div>
          <div className="project-info-box numbers ">
            <h3>Margin</h3>
            <strong>
              {!missingDataInfo ? (
                loading ? (
                  <CustomContentLoader width={140} />
                ) : (
                  `${currentMargin?.toFixed(2)}%`
                )
              ) : (
                '-'
              )}
            </strong>
          </div>
          <div className="project-info-box numbers ">
            <h3>Last Week</h3>
            <strong>
              {mainCurrencyId === 2 && isLegacy ? (
                'N/A'
              ) : loading ? (
                <CustomContentLoader width={140} />
              ) : (
                `${lastWeekMargin || 0}%`
              )}
            </strong>
          </div>
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <InputInlineEdit
              value={name}
              dataField="ProjectName"
              onChange={onChangeInputValue}
              caption="Project Name"
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Project Name: </span>
              <span>{name}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <InputInlineEdit
              value={code}
              dataField="ProjectCode"
              onChange={onChangeInputValue}
              caption="Project Code"
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Project Code: </span>
              <span>{name}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={client}
              displayValue={clientName}
              dataField="Client"
              caption="Client"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={clientSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Client: </span>
              <span>{clientName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={businessUnit}
              displayValue={businessUnitName}
              dataField="BusinessUnit"
              caption="Business Unit"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={businessUnitSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Business Unit: </span>
              <span>{businessUnitName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={comanagement}
              displayValue={comanagementName}
              dataField="Comanagement"
              caption="Practice"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={comanagementSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Practice: </span>
              <span>{comanagementName}</span>
            </div>
          )}
        </div>
        {/*
      <div className="project-info-box">
        {loading ? (
          <CustomContentLoader width={140} />
        ) : allowEdit ? (
          <SelectInlineEdit
            value={supportComanagement}
            displayValue={supportComanagementName}
            dataField="SupportComanagement"
            caption="Support Co-Management"
            displayExpr="Name"
            valueExpr="ID"
            dataSource={comanagementSource}
            onChange={onChangeInputValue}
          />
        ) : (
          <div className="list-item-content">
            <span className="secondary-text">Support Co-Management: </span>
            <span>{supportComanagementName}</span>
          </div>
        )}
      </div> */}
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={projectManager}
              displayValue={projectManagerName}
              dataField="ProfessionalManager"
              caption="Manager"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={professionalSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Manager: </span>
              <span>{projectManagerName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={projectManager}
              displayValue={projectPartnerName}
              dataField="ProfessionalPartner"
              caption="Partner"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={professionalSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Partner: </span>
              <span>{projectManagerName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={office}
              displayValue={officeName}
              dataField="Office"
              caption="Office"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={officeSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Office: </span>
              <span>{officeName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={billingOffice}
              displayValue={billingOfficeName}
              dataField="BillingOffice"
              caption="Billing Office"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={officeSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Billing Office: </span>
              <span>{billingOfficeName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <SelectInlineEdit
              value={geographic}
              displayValue={geographicName}
              dataField="Geographic"
              caption="Geographic"
              displayExpr="Name"
              valueExpr="ID"
              dataSource={geographicSource}
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">Geographic: </span>
              <span>{geographicName}</span>
            </div>
          )}
        </div>
        <div className="project-info-box">
          {loading ? (
            <CustomContentLoader width={140} />
          ) : allowEdit ? (
            <InputInlineEdit
              value={cityState}
              dataField="City"
              caption="City/State"
              onChange={onChangeInputValue}
            />
          ) : (
            <div className="list-item-content">
              <span className="secondary-text">City/State: </span>
              <span>{cityState}</span>
            </div>
          )}
        </div>
        {userScopes && !userScopes.includes(schedulingGroups.ReadAll) && (
          <>
            <div className="project-info-box">
              {loading ? (
                <CustomContentLoader width={140} />
              ) : allowEdit ? (
                <InputInlineEdit
                  value={marginSale}
                  displayValue={marginSaleDisplay}
                  dataField="MarginSale"
                  caption="Margin Sale"
                  onChange={onChangeInputValue}
                  inputProps={{
                    inputComponent: NumberFormatValue,
                  }}
                />
              ) : (
                <div className="list-item-content">
                  <span className="secondary-text">Margin Sale: </span>
                  <span>{marginSaleDisplay}</span>
                </div>
              )}
            </div>
            <div className="project-info-box">
              {loading ? (
                <CustomContentLoader width={140} />
              ) : allowEdit ? (
                <SelectInlineEdit
                  value={currency}
                  displayValue={currencyDisplayName}
                  dataField="Currency"
                  caption="Currency"
                  displayExpr="DisplayName"
                  valueExpr="ID"
                  dataSource={currencySource}
                  onChange={onChangeInputValue}
                  onChangeDisplayName={onChangeCurrency}
                />
              ) : (
                <div className="list-item-content">
                  <span className="secondary-text">Currency: </span>
                  <span>{currencyDisplayName}</span>
                </div>
              )}
            </div>
            <div className="project-info-box">
              {loading ? (
                <CustomContentLoader width={140} />
              ) : allowEdit ? (
                <InputInlineEdit
                  value={projectValueInitial}
                  displayValue={projectValueInitialDisplay}
                  dataField="ProjectValueInitial"
                  caption="Project Initial Value"
                  onChange={onChangeInputValue}
                  inputProps={{
                    inputComponent: NumberFormatValue,
                  }}
                />
              ) : (
                <div className="list-item-content">
                  <span className="secondary-text">Project Initial Value:</span>
                  {loading ? (
                    <CustomContentLoader width={140} />
                  ) : (
                    <span>{projectValueInitialDisplay}</span>
                  )}
                </div>
              )}
            </div>
            <div className="project-info-box">
              <div className="list-item-content">
                {loading ? (
                  <CustomContentLoader width={140} />
                ) : (
                  <>
                    <span className="secondary-text">
                      Project Value without ID One:
                    </span>
                    <span>{projectValueMMCDisplay}</span>
                  </>
                )}
              </div>
            </div>
            <div className="project-info-box">
              {loading ? (
                <CustomContentLoader width={140} />
              ) : allowEdit ? (
                <SelectInlineEdit
                  value={startDate}
                  displayValue={
                    startDate &&
                    format(
                      startOfWeek(addDays(parseISO(startDate), 2), {
                        weekStartsOn: 1,
                      }),
                      'dd MMM yyyy',
                    )
                  }
                  dataField="StartDateReal"
                  caption="Start Date"
                  displayExpr="Name"
                  valueExpr="ID"
                  dataSource={weeks}
                  onChange={onChangeInputValue}
                />
              ) : (
                <div className="list-item-content">
                  <span className="secondary-text">Start Date: </span>
                  {loading ? (
                    <CustomContentLoader width={140} />
                  ) : (
                    <span>
                      {startDate &&
                        format(
                          startOfWeek(addDays(parseISO(startDate), 2), {
                            weekStartsOn: 1,
                          }),
                          'dd MMM yyyy',
                        )}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="project-info-box">
              <div className="list-item-content">
                {loading ? (
                  <CustomContentLoader width={140} />
                ) : (
                  <>
                    <span className="secondary-text">End Date: </span>
                    <span>{endDate}</span>
                  </>
                )}
              </div>
            </div>
            <div className="project-info-box">
              <div className="list-item-content">
                {loading ? (
                  <CustomContentLoader width={140} />
                ) : (
                  <>
                    <span className="secondary-text">Original End Date: </span>
                    <span>{originalEndDate}</span>
                  </>
                )}
              </div>
            </div>
            <div className="project-info-box">
              <div className="list-item-content">
                {loading ? (
                  <CustomContentLoader width={140} />
                ) : (
                  <>
                    <span className="secondary-text">
                      Last Charged Extension End Date:{' '}
                    </span>
                    <span>{lastChargedExtensionEndDate}</span>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </TabPanel>
      {categories.map(category => (
        <TabPanel
          key={`category-${category.ID}`}
          value={tab}
          index={category.ID}
        >
          <SellValueTypeList
            categoryId={category.ID}
            projectId={projectId}
            projectStatus={status}
            comanagementId={comanagementId}
          />
        </TabPanel>
      ))}
    </>
  );
}
