import React, { useState, useCallback, useEffect } from 'react';
import { Button, Drawer, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import api from '../../../services/api';

export default function DialogSelectYear({ professionalId, open, handleClose, handleOpen, onChangedYear, year: _year }) {
  const [year, setYear] = useState(_year);
  const [years, setYears] = useState([]);

  useEffect(() => {
    Promise.all([
      api.get(`scheduling/professionals/${professionalId}/schedule-weeks/years`)
    ]).then(([years]) => {
      setYears(years.data);
    });
  }, [professionalId]);

  const handleChangeYear = useCallback(() => {
    if (onChangedYear) onChangedYear(year);
  }, [onChangedYear, year]);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2} minWidth={300}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Change Year
        </Typography>

        <FormControl style={{ width: '100%', marginTop: 15 }}>
          <InputLabel id="years-label">Years</InputLabel>
          <Select
            labelId="years-label"
            id="years-select"
            value={year}
            onChange={e => setYear(e.target.value)}
          >
            {years.map(year => (
              <MenuItem key={ year.Year } value={ year.Year }>{ year.Year }</MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button 
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>

          <Button 
            onClick={handleChangeYear} 
            variant="contained" 
            color="primary" 
            size="small"
          >
            Change year
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}