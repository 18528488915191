import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getWeek, getWeekYear } from '../../../utils/IntegrationDates';
import { getYear } from 'date-fns';

import { FiClock, FiCalendar } from 'react-icons/fi';

import {
  Button,
  IconButton,
  Tooltip,
  Badge,
  CircularProgress,
} from '@material-ui/core';
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  RangeRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

import styles from '../../Allocation/styles.css';

import GridToolbar from '../../../components/GridToolbar';
import GridLoaderNew from '../../Allocation/GridLoaderNew';
import WeekHeader from './WeekHeader.tsx';
import DateOutLookup from './DateOutLookup';

import DialogSelectYear from './DialogSelectYear';

import api from '../../../services/api';

import { errorMessage$ } from '../../../utils/subjects';

export default function Grid() {
  const getCurrentWeek = useCallback(() => {
    const currentWeekNumber = getWeek(new Date());
    const currentWeekYear = getWeekYear(new Date());

    return { WeekNumber: currentWeekNumber, Year: currentWeekYear };
  }, []);

  const gridRef = useRef();
  const [selectedYear, setSelectedYear] = useState(() => {
    const today = new Date();
    return getYear(today);
  });
  const { professionalId } = useParams();
  const [currentWeek] = useState(getCurrentWeek().WeekNumber);
  const [currentYear] = useState(getCurrentWeek().Year);
  const [openDialogSelectYear, setOpenDialogSelectYear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateOuts, setDateOuts] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const scheduleStore = new CustomStore({
    key: 'ID',
    loadMode: 'raw',
    load: () => {
      console.log('loading');
      return dateOuts;
    },
    update: (key, data) => {
      console.log('updated');
      handleEdit(key, data);
    },
  });

  const persistData = useCallback(async ({ dateOutId: key, allocations }) => {
    try {
      api.patch(`scheduling/date-out/${key}/allocations`, allocations);
    } catch (err) {
      errorMessage$.next({ open: true, message: err.response.data.message });
    }
  }, []);

  const handleEdit = useCallback(
    (key, data) => {
      scheduleStore.push([
        {
          type: 'update',
          key,
          data,
        },
      ]);

      const allocations = Object.keys(data).map(scheduleWeek => ({
        Professional: professionalId,
        ScheduleWeek: Number(scheduleWeek),
        TotalDays: data[scheduleWeek],
      }));
      persistData({
        allocations,
        dateOutId: key,
      });
    },
    [persistData, professionalId, scheduleStore],
  );

  const onChangedYear = useCallback(year => {
    setOpenDialogSelectYear(false);
    setSelectedYear(year);
  }, []);

  const focusOnCurrentWeek = useCallback(() => {
    const { WeekNumber: currentWeekNumber, Year: currentWeekYear } =
      getCurrentWeek();
    const currentWeek = weeks.find(
      week =>
        week.WeekNumber === currentWeekNumber && week.Year === currentWeekYear,
    );

    if (currentWeek && gridRef.current) {
      const grid = gridRef.current.instance;
      const cell = grid.getCellElement(0, currentWeek.ID);
      grid.focus(cell);
    }
  }, [getCurrentWeek, weeks]);

  useEffect(() => {
    const today = new Date();
    const year = getYear(today);
    setSelectedYear(year);
  }, [professionalId]);

  useEffect(() => {
    setLoading(true);
    setDateOuts([]);
    setWeeks([]);

    Promise.all([
      api.get(
        `scheduling/professionals/${professionalId}/schedule-weeks?year=${selectedYear}`,
      ),
      api.get(`scheduling/date-out-types`),
      api.get(
        `scheduling/professionals/${professionalId}/date-out?year=${selectedYear}`,
      ),
    ]).then(([scheduleWeeks, dateOutTypes, dateOutAllocations]) => {
      const weeks = scheduleWeeks.data.map(item => ({
        ID: item.ID.toString(),
        StartDate: item.WeekStartDate,
        EndDate: item.WeekEndDate,
        WeekNumber: item.WeekNumber,
        Year: item.Year,
      }));

      const dateOuts = dateOutAllocations.data;
      const rows = [];

      dateOutTypes.data.map(dataOutType => {
        const row = {};

        const dateOut = dateOuts.find(
          dateOut => dateOut.DateOutType.ID === dataOutType.ID,
        );

        row.ID = dataOutType.ID;
        row.DateOut = dataOutType.Description;

        if (dateOut) {
          dateOut.Allocations.map(allocation => {
            const scheduleWeekId = allocation.ScheduleWeek.ID;
            row[scheduleWeekId] = allocation.TotalDays;
          });
        }

        rows.push(row);
      });

      setDateOuts(rows);

      setWeeks(weeks);
      setLoading(false);
    });
  }, [professionalId, selectedYear]);

  useEffect(() => {
    setTimeout(focusOnCurrentWeek, 2000);
  }, [focusOnCurrentWeek, weeks]);

  return (
    <>
      <section id="allocation">
        {loading ? (
          <GridLoaderNew style={{ paddingTop: 30 }} />
        ) : (
          <>
            <Tooltip
              title="Go to current week"
              placement="top"
              arrow
              followCursor
            >
              <Button
                className="current-button"
                size="small"
                onClick={focusOnCurrentWeek}
              >
                <FiClock size={16} />
              </Button>
            </Tooltip>
            <Tooltip title="Change year" placement="top" arrow followCursor>
              <Button
                className="change-year-button"
                onClick={() => setOpenDialogSelectYear(true)}
                style={{ marginTop: 12 }}
              >
                <FiCalendar size={16} />
              </Button>
            </Tooltip>
            <DataGrid
              ref={gridRef}
              scrolling="standard"
              dataSource={scheduleStore}
              showBorders={false}
              remoteOperations
              height="100%"
              showColumnLines
              id="hr-grid"
              allowColumnResizing={false}
              loadPanel={null}
            >
              <Paging enabled={false} />
              <Editing
                mode="cell"
                allowUpdating
                selectTextOnEditStart
                startEditAction="click"
              />

              <Column
                dataField="DateOut"
                fixed={true}
                allowEditing={false}
                cssClass="cursor-pointer"
                width={250}
                cellRender={DateOutLookup}
              />

              {/* <Column dataField="JobTitle" visible={false} />
<Column dataField="AllowDelete" visible={false} /> */}

              {weeks.map((week, index) => (
                <Column
                  alignment="center"
                  key={week.ID}
                  dataField={week.ID}
                  caption={week.caption}
                  allowSorting={false}
                  headerCellRender={() => (
                    <WeekHeader
                      isToday={
                        currentWeek === week.WeekNumber &&
                        week.Year === currentYear
                      }
                      week={week}
                      index={index}
                      isEditable={true}
                    />
                  )}
                  width={65}
                  cssClass={
                    currentWeek === week.WeekNumber && week.Year === currentYear
                      ? 'highlight-cell'
                      : null
                  }
                >
                  <RangeRule max={5} min={0} message="" />
                </Column>
              ))}

              <Column caption="" />
            </DataGrid>
          </>
        )}
      </section>
      {openDialogSelectYear ? (
        <DialogSelectYear
          open={openDialogSelectYear}
          handleClose={() => setOpenDialogSelectYear(false)}
          handleOpen={() => setOpenDialogSelectYear(true)}
          professionalId={professionalId}
          year={selectedYear}
          onChangedYear={onChangedYear}
        />
      ) : null}
    </>
  );
}
