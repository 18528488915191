import React from 'react';
import Routes from './routes';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// se remover login fica zoado
import 'bootstrap/dist/css/bootstrap.css'; 

import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.integration.css';
import './assets/global.css';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#8b0305'
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;