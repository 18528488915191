import styled, { css } from 'styled-components';

interface TimelineItemProps {
  iconBackgroud?: string;
}

export const Container = styled.div`
  width: 100%;

  .dosdonts {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    align-items: flex-start;

    .dosDontsList {
      width: 50%;
      display: flex;
      flex-direction: column;

      h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }
    }

    .no-data {
      text-align: center;
      margin-top: 25px;

      h1 {
        text-transform: none;
        font-size: 24px;
        color: #9699a6;
        font-weight: 400;
      }
    }
  }
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TimelineItem = styled.div<TimelineItemProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 70px;
  margin-bottom: 15px;

  background-color: #efefef;
  padding: 5px 15px;
  border-radius: 5px;

  & > div:first-child {
    margin-right: 16px;
    flex: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }

  .icon {
    background-color: #8b0304;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${props =>
    props.iconBackgroud &&
    css`
      .icon {
        background-color: ${props.iconBackgroud};
      }
    `}

  .hr {
    width: 2px;
    flex-grow: 1;
    background-color: #bdbdbd;
  }

  .content {
    flex: 1;

    span {
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      font-weight: 300;
    }

    span:not(:last-child) {
      margin-right: 10px;
    }

    .link {
      color: #8b0304;
      cursor: pointer;
    }

    .link:hover {
      text-decoration: underline;
    }

    .title {
      /* background-color: #fff; */
      /* border-radius: 5px; */
      /* padding: 5px 15px; */

      h4 {
        font-size: 14px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
      }
    }
  }
`;
