import React, { useEffect, useState, useRef, useCallback } from "react";

import { InputBase } from "@material-ui/core";

import "./styles.css";

export default function InputInlineEdit({
  type: _type = "text",
  value: _value,
  displayValue: _displayValue,
  caption: _caption,
  dataField: _dataField,
  onChange,
  inputProps: _inputProps,
}) {
  const formRef = useRef(null);
  const [value, setValue] = useState("");
  const [displayValue, setDisplayValue] = useState("");
  const [type, setType] = useState("");
  const [caption, setCaption] = useState("");
  const [inputProps, setInputProps] = useState("");
  const [dataField, setDataField] = useState("");
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setValue(_value);
    setCaption(_caption);
    setType(_type);
    setDisplayValue(_displayValue);
    setInputProps(_inputProps);
    setDataField(_dataField);
  }, [_caption, _dataField, _displayValue, _inputProps, _type, _value]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setShowInput(false);

      if (onChange) {
        onChange({ [dataField]: value });
      }
    },
    [dataField, onChange, value]
  );

  return (
    <div
      className={`inline-edit-container ${showInput && "show-input"}`}
      onClick={() => setShowInput(true)}
    >
      {!showInput ? (
        <>
          {!!caption && <span className="secondary-text">{caption}: </span>}
          <span>{displayValue || value}</span>
        </>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit}>
          <InputBase
            fullWidth
            className="inline-edit-input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleSubmit}
            autoFocus
            type={type}
            {...inputProps}
          />
        </form>
      )}
    </div>
  );
}
