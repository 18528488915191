import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import Grid from './Grid';

import { useControlContent } from '../../hooks/controlContent';

import ProfessionalControlContentList from '../../components/ProfessionalControlContentList';

import api from '../../services/api';

import {
  Board,
  BoardContent,
  BoardHeader,
  BoardActions,
} from '../../components/Board';

export default function Professional() {
  const history = useHistory();
  const { professionalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [professional, setProfessional] = useState(null);
  const [professionalPractices, setProfessionalPractices] = useState([]);

  const { setControlContent, setPageTitle } = useControlContent();

  const handleNavigationAllocation = useCallback(
    professionalId => {
      history.push(`/professionals/${professionalId}`);
    },
    [history],
  );

  // Build control menu
  useEffect(() => {
    setPageTitle('Professionals');
    setControlContent(
      <ProfessionalControlContentList
        selected={professionalId}
        onSelectProfessional={handleNavigationAllocation}
      />,
    );
  }, [
    handleNavigationAllocation,
    professionalId,
    setControlContent,
    setPageTitle,
  ]);

  useEffect(() => {
    setLoading(true);
    api.get(`master/professionals/${professionalId}`).then(response => {
      setLoading(false);
      setProfessional(response.data);

      const practices = response.data.Practices.map(practice => practice.Name);
      setProfessionalPractices(practices.join(', '));
    });
  }, [professionalId]);

  return (
    <>
      <Board style={{ height: 'calc(100vh - 120px)' }}>
        {!loading ? (
          <BoardHeader
            title={professional && professional.Name}
            photo={
              professional &&
              `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professional.Login}_192_192.png`
            }
            description={
              professional &&
              `${professional.JobTitle.Name} - ${professionalPractices}`
            }
            headerRight={<BoardActions></BoardActions>}
          />
        ) : (
          <div>
            <ContentLoader
              speed={2}
              viewBox="0 0 900 120"
              backgroundColor="#f3f3f3"
              foregroundColor="#dedede"
            >
              <rect x="0" y="26" rx="3" ry="3" width="400" height="30" />
              <rect x="0" y="71" rx="3" ry="3" width="260" height="23" />
            </ContentLoader>
          </div>
        )}

        <BoardContent style={{ padding: 0 }}>
          <Grid />
        </BoardContent>
      </Board>
    </>
  );
}
