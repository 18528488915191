import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowUpCircle, FiExternalLink, FiRefreshCcw } from 'react-icons/fi';

import { WhisperSpinner } from 'react-spinners-kit';

import GridToolbar from '../../components/GridToolbar';

import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
  Selection,
} from 'devextreme-react/data-grid';

import ProjectControlContentList from '../../components/ProjectControlContentList';
import DialogAuditSellValueType from '../ProjectHome/DialogAuditSellValueType';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardContent,
  Tooltip,
  IconButton,
  Chip,
  Button,
} from '@material-ui/core';

import { useControlContent } from '../../hooks/controlContent';
import { useAuth } from '../../hooks/auth';
import { List } from 'react-content-loader';
import DialogProject from '../Project/DialogProject';
import DialogSimulation from '../SimulationAllocation/DialogProject';
import DialogClient from '../Admin/Client/DialogClient';
import DialogProjectStatus from '../Allocation/DialogProjectStatus';
import DialogLoadConflicts from './DialogLoadConflicts';
import DialogWaitingValidation from './DialogWaitingValidation';

import api from '../../services/api';
import LandingPage from '../../components/LandingPage';
import ChipStatus from '../../components/ChipStatus';
import GridLoader from './GridLoader';
import DialogChangeStatus from './DialogChangeStatus';

import schedulingGroups from '../../config/schedulingGroups';

export default function AllocationHome() {
  const { user, hasScope } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.CoManager,
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
  ]);
  const [allowEdit] = useState(() =>
    userScopes.some(scope => allowEditScopes.includes(scope)),
  );

  const marginFormat = useMemo(() => ({ minimumFractionDigits: 1 }), []);

  const [selectedIds, setSelectedId] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openDialogLoadConflict, setOpenDialogLoadConflict] = useState(false);
  const [openDialogSimulation, setOpenDialogSimulation] = useState(false);
  const [dialogProjectOpen, setDialogProjectOpen] = useState(false);
  const [dialogClientOpen, setDialogClientOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialogChangeStatus, setOpenDialogChangeStatus] = useState(false);
  const [openDialogWaitingValidation, setOpenDialogWaitingValidation] =
    useState(false);
  const [openAuditSellValueType, setOpenAuditSellValueType] = useState(false);

  const [projectId, setProjectId] = useState('');
  const [projectsSource, setProjectsSource] = useState([]);
  const [newItem, setNewItem] = useState(null);
  const history = useHistory();
  const [statusSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/project-status');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [comanagementsSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/comanagements');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [yesNoSource] = useState([
    {
      Description: 'Yes',
      Value: true,
    },
    {
      Description: 'No',
      Value: false,
    },
  ]);

  const { setControlContent, setPageTitle, pageTitle } = useControlContent();

  const handleNavigation = useCallback(
    projectId => {
      history.push(`/projects/${projectId}/allocations`);
    },
    [history],
  );

  const handleNavigationNewTab = useCallback(projectId => {
    window.open(`/projects/${projectId}/allocations`);
  }, []);

  // Build control menu
  useEffect(() => {
    setPageTitle('Allocation');
    setControlContent(
      <ProjectControlContentList
        newProject={newItem}
        onSelectProject={handleNavigation}
      />,
    );

    const projectSource = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          setLoading(true);
          const response = await api.get('scheduling/projects', {
            params: {
              where: {
                WithConflicts: true,
              },
            },
          });

          const projects = response.data.map(project => ({
            ...project,
            TotalConflicts: project.AllocationConflicts.length,
            HasConflicts: project.AllocationConflicts.length > 0 ? 'Yes' : 'No',
          }));

          setLoading(false);
          return projects;
        } catch (err) {
          console.log('load projects allocation home', err);
        }
      },
    });

    const projectDateSource = new DataSource({
      store: projectSource,
      paginate: true,
      reshapeOnPush: true,
    });

    setProjectsSource(projectDateSource);
  }, [handleNavigation, newItem, setControlContent, setPageTitle]);

  const handleAddProject = useCallback(newProject => {
    setNewItem(newProject);
  }, []);

  const handleOnLoadedConflicts = useCallback(() => {
    projectsSource.reload();
  }, [projectsSource]);

  const onStatusChanged = useCallback(async () => {
    projectsSource.reload();
  }, [projectsSource]);

  const statusColumnRender = useCallback(
    e => <ChipStatus status={e.data.ProjectStatus.Description} />,
    [],
  );

  const conflictsColumnRender = useCallback(
    e => (
      <Chip
        size="small"
        color="default"
        style={{
          backgroundColor:
            (e.data.AllocationConflicts && e.data.AllocationConflicts.length) >
            0
              ? '#f44336'
              : '',
          color:
            (e.data.AllocationConflicts && e.data.AllocationConflicts.length) >
            0
              ? '#ffffff'
              : '',
        }}
        label={
          (e.data.AllocationConflicts.length &&
            e.data.AllocationConflicts.length) > 0
            ? 'Yes'
            : 'No'
        }
      />
    ),
    [],
  );

  const modifiedColumnRender = useCallback(
    e => (
      <Chip
        size="small"
        color="default"
        style={{
          backgroundColor:
            (e.data.ModifiedAllocations || 0) > 0 ? '#5393ff' : '',
          color: (e.data.ModifiedAllocations || 0) > 0 ? '#ffffff' : '',
        }}
        label={(e.data.ModifiedAllocations || 0) > 0 ? 'Yes' : 'No'}
      />
    ),
    [],
  );

  const commandColumnRender = useCallback(
    e => (
      <>
        {((hasScope([schedulingGroups.CoManager]) &&
          user.comanagementsIds.includes(Number(e.data.Comanagement))) ||
          hasScope([schedulingGroups.Admin, schedulingGroups.Allocation])) && (
          <Tooltip title="Change status" aria-label="status" placement="left">
            <IconButton
              aria-label="Change status"
              onClick={() => {
                setProjectId(e.key);
                setOpenChangeStatus(true);
              }}
              size="small"
            >
              <FiArrowUpCircle size={18} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Open allocation" aria-label="open" placement="left">
          <IconButton
            aria-label="Open allocation"
            size="small"
            onClick={() => handleNavigationNewTab(e.key)}
          >
            <FiExternalLink size={18} />
          </IconButton>
        </Tooltip>
      </>
    ),
    [handleNavigationNewTab, hasScope, user],
  );

  const handleCreatedSimulation = useCallback(
    data => {
      history.push(`/simulation/${data.ID}/allocations`);
    },
    [history],
  );

  const handleSelectionChanged = useCallback(
    ({ selectedRowKeys, selectedRowsData }) => {
      setSelectedCodes(
        selectedRowsData.map(
          x => `${x.ProjectCode || 'No code'} - ${x.ProjectName}`,
        ),
      );
      setSelectedId(selectedRowKeys);
    },
    [],
  );

  return (
    <LandingPage
      title={pageTitle}
      subtitle="Select one project on the side menu or choose and option below"
    >
      {allowEdit && (
        <div className="home-big-buttons-wrapper">
          <button
            onClick={() => setDialogProjectOpen(true)}
            className="home-big-buttons"
            type="button"
          >
            Create project
          </button>
          <button
            onClick={() => setOpenDialogSimulation(true)}
            className="home-big-buttons"
            type="button"
          >
            Create simulation
          </button>
          <button
            onClick={() => setOpenAuditSellValueType(true)}
            className="home-big-buttons"
            type="button"
          >
            Audit value adjustments
          </button>
        </div>
      )}

      <div className="active-projets-list-wrapper">
        <h2>List of active projects </h2>

        <p>Easier way to change project status or edit allocations</p>

        {loading ? (
          <List
            speed={2}
            width="100%"
            height="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#dedede"
            style={{ marginTop: 30 }}
          />
        ) : (
          <>
            {hasScope([schedulingGroups.Admin, schedulingGroups.Allocation]) &&
              selectedIds.length > 0 && (
                <div className="home-buttons-wrapper">
                  <Tooltip title="Change project status to Waiting Validation">
                    <Button
                      onClick={() => setOpenDialogWaitingValidation(true)}
                    >
                      Waiting Validation Selected
                    </Button>
                  </Tooltip>
                  <Tooltip title="Change project status">
                    <Button onClick={() => setOpenDialogChangeStatus(true)}>
                      Approve selected
                    </Button>
                  </Tooltip>
                  <Tooltip title="Refresh projects">
                    <Button onClick={() => setOpenDialogLoadConflict(true)}>
                      Refresh selected
                    </Button>
                  </Tooltip>
                </div>
              )}
            <div className="list-active-projects-wrapper">
              <DataGrid
                dataSource={projectsSource}
                showBorders={false}
                remoteOperations={false}
                onSelectionChanged={handleSelectionChanged}
                id="list-active-projects"
                height="100%"
                width="100%"
              >
                <Selection
                  mode="multiple"
                  selectAllMode="allPages"
                  showCheckBoxesMode="onClick"
                />
                {/* <FilterRow visible={true} /> */}
                <HeaderFilter visible={true} />
                <Scrolling mode="infinite" />
                <Grouping autoExpandAll={false} />

                <Column dataField="ProjectCode" caption="Code" width={90} />
                <Column dataField="ProjectName" caption="Name" />
                <Column dataField="Client.Name" caption="Client" />
                {/* <Column dataField="Comanagement" caption="Comanagement">
                  <Lookup
                    dataSource={comanagementsSource}
                    displayExpr="Name"
                    valueExpr="ID"
                  />
                </Column> */}
                <Column
                  cellRender={conflictsColumnRender}
                  caption="Has Conflicts"
                  dataField="HasConflicts"
                  width={130}
                  allowFiltering={false}
                >
                  <Lookup
                    dataSource={yesNoSource}
                    displayExpr="Description"
                    valueExpr="Description"
                  />
                </Column>
                <Column
                  cellRender={modifiedColumnRender}
                  caption="Has Modified"
                  dataField="ModifiedAllocations"
                  width={130}
                  allowFiltering={false}
                >
                  <Lookup
                    dataSource={yesNoSource}
                    displayExpr="Description"
                    valueExpr="Value"
                  />
                </Column>
                <Column
                  cellRender={statusColumnRender}
                  caption="Status"
                  dataField="ProjectStatus.ID"
                  width={180}
                  allowFiltering={false}
                >
                  <Lookup
                    dataSource={statusSource}
                    displayExpr="Description"
                    valueExpr="ID"
                  />
                </Column>
                <Column
                  dataType="number"
                  dataField="MarginCurrent"
                  caption="Margin"
                  format={marginFormat}
                  visible={
                    userScopes && !userScopes.includes(schedulingGroups.ReadAll)
                  }
                  width={100}
                  allowFiltering={false}
                />
                <Column
                  dataType="number"
                  dataField="WeeksLeft"
                  caption="Weeks left"
                  width={150}
                  allowFiltering={false}
                />
                <Column
                  type="buttons"
                  cellRender={commandColumnRender}
                  width={100}
                  allowFiltering={false}
                ></Column>
              </DataGrid>
            </div>
          </>
        )}
      </div>

      {dialogProjectOpen && (
        <DialogProject
          open={dialogProjectOpen}
          handleOpen={() => setDialogProjectOpen(true)}
          handleClose={() => setDialogProjectOpen(false)}
          callback={handleAddProject}
        />
      )}

      {dialogClientOpen && (
        <DialogClient
          open={dialogClientOpen}
          handleOpen={() => setDialogClientOpen(true)}
          handleClose={() => setDialogClientOpen(false)}
        />
      )}
      {openChangeStatus && (
        <DialogProjectStatus
          open={openChangeStatus}
          projectId={projectId}
          handleClose={() => setOpenChangeStatus(false)}
          handleOpen={() => setOpenChangeStatus(true)}
          onStatusChanged={onStatusChanged}
          onLoadedConflicts={handleOnLoadedConflicts}
          closureId=""
        />
      )}

      {openDialogWaitingValidation && (
        <DialogWaitingValidation
          projectCodes={selectedCodes}
          projectIds={selectedIds}
          open={openDialogWaitingValidation}
          handleClose={() => setOpenDialogWaitingValidation(false)}
          onSubmitted={() => projectsSource.reload()}
        />
      )}

      {openDialogLoadConflict && (
        <DialogLoadConflicts
          open={openDialogLoadConflict}
          projectIds={selectedIds}
          handleClose={() => setOpenDialogLoadConflict(false)}
          onSubmitted={handleOnLoadedConflicts}
        />
      )}
      {openDialogSimulation && (
        <DialogSimulation
          open={openDialogSimulation}
          projectId={projectId}
          handleClose={() => setOpenDialogSimulation(false)}
          handleOpen={() => setOpenDialogSimulation(true)}
          callback={handleCreatedSimulation}
        />
      )}
      {openDialogChangeStatus && (
        <DialogChangeStatus
          projectCodes={selectedCodes}
          projectIds={selectedIds}
          open={openDialogChangeStatus}
          handleClose={() => setOpenDialogChangeStatus(false)}
          onSubmitted={() => projectsSource.reload()}
        />
      )}

      {openAuditSellValueType && (
        <DialogAuditSellValueType
          open={openAuditSellValueType}
          handleOpen={() => setOpenAuditSellValueType(true)}
          handleClose={() => setOpenAuditSellValueType(false)}
        />
      )}
    </LandingPage>
  );
}
