import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Slide,
  InputAdornment,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';
import ProfessionalLookup from '../../components/ProfessionalLookup/index.tsx';
import NumberFormatValue from '../../components/NumberFormat';

import { errorMessage$, successMessage$ } from '../../utils/subjects';
import api from '../../services/api';

import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogProject({
  handleClose,
  handleOpen,
  open,
  callback,
}) {
  const classes = useStyles();
  const [clientSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/clients', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });
  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [comanagementSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/comanagements');
        return response.data.filter(
          x => x.Name !== 'Brazil' && x.Name !== 'Latam' && x.Name !== 'Europe',
        );
      },
    }),
    paginate: true,
  });
  const [officeSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/offices');
        return response.data;
      },
    }),
    paginate: true,
  });
  const [geographicSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/geographics');
        return response.data;
      },
    }),
    paginate: true,
  });
  const [businessUnitSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/business-units');
        return response.data;
      },
    }),
    paginate: true,
  });

  const [loading, setLoading] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [weeks, setWeeks] = useState(1);
  const [client, setClient] = useState(null);
  const [cityState, setCityState] = useState('');
  const [geographic, setGeographic] = useState(null);
  const [projectPartner, setProjectPartner] = useState(null);
  const [projectManager, setProjectManager] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [currencyName, setCurrencyName] = useState(null);
  const [value, setValue] = useState('');
  const [comanagement, setComanagement] = useState(null);
  const [businessUnit, setBusinessUnit] = useState(null);
  const [office, setOffice] = useState(null);

  useEffect(() => {
    if (weeks < 1 && weeks !== '') setWeeks(1);
  }, [weeks]);

  useEffect(() => {
    setDatePickerOpen(false);
  }, [startDate]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      if (
        client &&
        name &&
        value &&
        office &&
        currency &&
        cityState &&
        geographic &&
        comanagement
      ) {
        const projectData = {
          Client: client,
          ProfessionalManager: projectManager,
          ProfessionalPartner: projectPartner,
          ProjectName: name,
          ProjectCode: '',
          ProjectValueInitial: Number(value),
          Office: office,
          Comanagement: comanagement,
          ScheduleWeeks: weeks,
          Currency: currency,
          WeekStartDate: startDate,
          Geographic: geographic,
          City: cityState,
          BusinessUnit: businessUnit,
        };

        const newProject = await api.post('scheduling/projects', projectData);
        successMessage$.next({
          open: true,
          message: 'Project created successfully',
        });
        setLoading(false);
        handleClose();

        if (callback) callback(newProject.data);
      } else {
        errorMessage$.next({ open: true, message: 'Please fill all fields' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorMessage$.next({ open: true, message: 'Error on create project' });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <div className="dialog-project">
        <DialogTitle id="form-dialog-title">+ Add project</DialogTitle>
        <form
          className={classes.root}
          onSubmit={handleSubmit}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Project information
            </Typography>
            <DialogContentText>
              Fill the fields below to create a new project
            </DialogContentText>
            <Grid style={{ marginBottom: 24 }}>
              <TextField
                autoFocus
                id="name"
                label="Name"
                type="text"
                fullWidth
                value={name}
                required
                onChange={e => setName(e.target.value)}
                style={{ marginBottom: 8 }}
              />

              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-start-date"
                label="Start Date"
                value={startDate}
                onChange={date => setStartDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  onFocus: e => {
                    setDatePickerOpen(true);
                  },
                }}
                fullWidth
                style={{ marginBottom: 8 }}
                open={datePickerOpen}
                PopoverProps={{
                  disableRestoreFocus: true,
                  onClose: () => {
                    setDatePickerOpen(false);
                  },
                }}
                InputProps={{
                  onFocus: () => {
                    setDatePickerOpen(true);
                  },
                }}
              />

              <TextField
                id="weeks"
                label="How many weeks will this project last?"
                type="number"
                fullWidth
                required
                value={weeks}
                onChange={e => setWeeks(e.target.value)}
                style={{ marginBottom: 8 }}
              />

              <SelectBox
                dataSource={currencySource}
                displayExpr="DisplayName"
                valueExpr="ID"
                placeholder="Select a currency"
                searchExpr={['Name', 'Abbreviation']}
                minSearchLength={2}
                showDataBeforeSearch
                searchEnabled
                value={currency}
                required
                onValueChanged={e => {
                  setCurrencyName(e.component.option('text').split('-')[1]);
                  setCurrency(e.value);
                }}
                style={{ marginTop: 24, marginBottom: 24 }}
              />

              <TextField
                fullWidth
                label="Project Value"
                id="project-value"
                value={value}
                required
                onChange={e => setValue(e.target.value)}
                InputProps={{
                  inputComponent: NumberFormatValue,
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencyName || 'R$'}
                    </InputAdornment>
                  ),
                }}
              />

              <SelectBox
                dataSource={clientSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Client"
                searchExpr={['Name']}
                required
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                value={client}
                onValueChanged={e => setClient(e.value)}
                style={{ marginTop: 24 }}
              />

              <SelectBox
                dataSource={businessUnitSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Business Unit"
                searchExpr={['Name', 'Abbreviation']}
                minSearchLength={2}
                showDataBeforeSearch
                searchEnabled
                required
                value={businessUnit}
                onValueChanged={e => setBusinessUnit(e.value)}
                style={{ marginTop: 24 }}
              />

              <SelectBox
                dataSource={comanagementSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Practices"
                searchExpr={['Name', 'Abbreviation']}
                minSearchLength={2}
                showDataBeforeSearch={true}
                searchEnabled={true}
                required={true}
                value={comanagement}
                onValueChanged={e => setComanagement(e.value)}
                style={{ marginTop: 24 }}
              />

              <SelectBox
                dataSource={officeSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Office"
                searchExpr={['Name', 'Abbreviation']}
                minSearchLength={2}
                showDataBeforeSearch
                searchEnabled
                required
                value={office}
                onValueChanged={e => setOffice(e.value)}
                style={{ marginTop: 24 }}
              />

              <SelectBox
                dataSource={geographicSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Geographic"
                searchExpr={['Name']}
                minSearchLength={2}
                showDataBeforeSearch
                searchEnabled
                required
                value={geographic}
                onValueChanged={e => setGeographic(e.value)}
                style={{ marginTop: 24 }}
              />

              <TextField
                id="cityState"
                label="City/State"
                type="text"
                fullWidth
                value={cityState}
                required
                onChange={e => setCityState(e.target.value)}
                style={{ marginBottom: 8 }}
              />

              <SelectBox
                dataSource={professionalSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Select a responsible"
                itemRender={ProfessionalLookup}
                searchExpr={['Name', 'JobTitle.Name']}
                minSearchLength={3}
                showDataBeforeSearch
                searchEnabled
                required
                value={projectPartner}
                onValueChanged={e => setProjectPartner(e.value)}
                style={{ marginTop: 24 }}
              />

              <SelectBox
                dataSource={professionalSource}
                displayExpr="Name"
                valueExpr="ID"
                placeholder="Select a leader"
                itemRender={ProfessionalLookup}
                searchExpr={['Name', 'JobTitle.Name']}
                minSearchLength={3}
                required
                showDataBeforeSearch
                searchEnabled
                value={projectManager}
                onValueChanged={e => setProjectManager(e.value)}
                style={{ marginTop: 24 }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button color="primary" disabled={loading} type="submit">
                Create
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}
