import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { 
  List, 
  ListItem,
  ListItemText, 
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import { FiChevronRight } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    padding: '5px 16px 5px 16px'
  }
}));

export default function ControlList() {
  const classes = useStyles();
  const history = useHistory();

  const handleProfessionalNavigation = useCallback(() => {
    history.push(`/hr/professionals`);
  }, [history]);

  const handleDateOutTypeNavigation = useCallback(() => {
    history.push(`/hr/date-out-types`);
  }, [history]);

  const handleImportNavigation = useCallback(() => {
    history.push(`/hr/import`);
  }, [history]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button className={classes.listItem} onClick={handleProfessionalNavigation}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Professionals
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>
      <ListItem button className={classes.listItem} onClick={handleDateOutTypeNavigation}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Date Out Types
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem button className={classes.listItem} onClick={handleImportNavigation}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Import
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>
    </List>
  );
}