import React from 'react';
import Board from './Board';
import BoardHeaderTemplate from './BoardHeaderTemplate';
import BoardHeaderLeft from './BoardHeaderLeft';
import BoardHeaderRight from './BoardHeaderRight';
import BoardTitle from './BoardTitle';
import BoardActions from './BoardActions';
import BoardHeaderMain from './BoardHeaderMain';
import BoardContent from './BoardContent';
import BoardFilter from './BoardFilter';
import BoardDescription from './BoardDescription';
import BoardHeaderTop from './BoardHeaderTop';
import BoardHeaderPhoto from './BoardHeaderPhoto';

import '../../pages/Allocation/styles.css';

interface BoardHeaderProps {
  masterTitle?: string;
  title?: string;
  description?: string;
  headerRight?: any;
  chip?: string;
  photo?: string;
}

export const BoardHeader: React.FC<BoardHeaderProps> = ({
  masterTitle,
  title,
  description,
  headerRight,
  chip,
  photo,
  children,
}) => {
  return (
    <div className="board-header">
      <div className="board-header-content-wrapper">
        <BoardHeaderLeft>
          <div className="code-status">
            {masterTitle}
            {!!chip && chip}
          </div>
          <div>
            {photo && <BoardHeaderPhoto src={photo} alt={title} />}
            <div>
              <BoardTitle title={title || ''} />
              <BoardDescription description={description || ''} />
            </div>
          </div>
        </BoardHeaderLeft>
        <BoardHeaderRight>{headerRight}</BoardHeaderRight>
      </div>
      {children}
    </div>
  );
};

export { Board, BoardContent, BoardActions, BoardFilter };
