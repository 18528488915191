import React, { useEffect, useState } from 'react';

import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardContent,
  CardActions,
  Button,
  Tooltip,
} from '@material-ui/core';

import { useControlContent } from '../../hooks/controlContent';
import LandingPage from '../../components/LandingPage';
import { useAuth } from '../../hooks/auth';
import schedulingGroups from '../../config/schedulingGroups';

import NetRevenue from './Reports/NetRevenue';
import ProfessionalProductivity from './Reports/ProfessionalProductivity';
import Margin from './Reports/Margin';
import ProjectBase from './Reports/ProjectBase';
import ThreeWeeksDesallocated from './Reports/ThreeWeeksDesallocated';
import AvailableNextWeek from './Reports/AvailableNextWeek';
import Seniors from './Reports/Seniors';
import Offices from './Reports/Offices';
import Consolidated from './Reports/Consolidated';
import ConsolidatedMoreWeeks from './Reports/ConsolidatedMoreWeeks';
import Unallocated from './Reports/Unallocated';
import ProjectObservations from './Reports/ProjectObservations';
import ProjectTransferPrice from './Reports/ProjectTransferPrice';
import LastAllocation from './Reports/LastAllocation';
import AllocationSuggestion from './Reports/AllocationSuggestion';
import ProjectBaseLegacy from './Reports/ProjectBaseLegacy';

export default function Report() {
  const { user } = useAuth();

  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowedFinancialScopes] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
    schedulingGroups.CoManager,
    schedulingGroups.Partner,
  ]);
  const [allowFinancialReports] = useState(() =>
    userScopes.some(scope => allowedFinancialScopes.includes(scope)),
  );

  const { setHasControl, setPageTitle, pageTitle } = useControlContent();
  const [openNetRevenue, setOpenRevenue] = useState(false);
  const [openProfessionalProductivity, setOpenProfessionalProductivity] =
    useState(false);
  const [openMargin, setOpenMargin] = useState(false);
  const [openProjectsBase, setOpenProjectsBase] = useState(false);
  const [openProjectsBaseLegacy, setOpenProjectsBaseLegacy] = useState(false);
  const [openThreeWeeksDesallocated, setOpenThreeWeeksDesallocated] =
    useState(false);
  const [openAvailableNextWeek, setOpenAvailableNextWeek] = useState(false);
  const [openSeniors, setOpenSeniors] = useState(false);
  const [openOffices, setOpenOffices] = useState(false);
  const [openConsolidated, setOpenConsolidated] = useState(false);
  const [openConsolidatedMoreWeeks, setOpenConsolidatedMoreWeeks] =
    useState(false);
  const [openUnallocated, setOpenUnallocated] = useState(false);
  const [openProjectObservations, setOpenProjectObservations] = useState(false);
  const [openProjectTransferPrice, setOpenProjectTransferPrice] =
    useState(false);
  const [openLastAllocation, setOpenLastAllocation] = useState(false);
  const [openAllocationSuggestions, setOpenAllocationSuggestions] =
    useState(false);

  useEffect(() => {
    setPageTitle('Reports');
    setHasControl(false);
  }, [setHasControl, setPageTitle]);

  return (
    <LandingPage
      title={pageTitle}
      subtitle="Select one report below to export to Excel"
    >
      <Grid container spacing={3}>
        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenRevenue(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Allocations per project
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Allocations per project report (current year)
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenRevenue(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sm={6}>
          <Tooltip
            title="Disabled temporarily for maintenance. Contact IT Support for more information."
            placement="top"
          >
            <Card>
              <CardActionArea
                disabled
                onClick={() => setOpenProfessionalProductivity(true)}
              >
                <CardContent style={{ minHeight: 100 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Allocations per professionals{' '}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Allocations per professionals report
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setOpenProfessionalProductivity(true)}
                  disabled
                >
                  Generate
                </Button>
              </CardActions>
            </Card>
          </Tooltip>
        </Grid>

        {allowFinancialReports && (
          <Grid item md={3} xs={12} sm={6}>
            <Card>
              <CardActionArea onClick={() => setOpenMargin(true)}>
                <CardContent style={{ minHeight: 100 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Margin
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Margin report
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setOpenMargin(true)}
                >
                  Generate
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenProjectsBase(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Projects
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Project base with dollars as currency.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenProjectsBase(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenProjectsBaseLegacy(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Projects (Legacy)
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Project base with reais as currency.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenProjectsBaseLegacy(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenAvailableNextWeek(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Available next week
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of professionals that is available next week
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenAvailableNextWeek(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenSeniors(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Seniors
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of seniors professionals
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenSeniors(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenOffices(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Offices
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of professionals per offices
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenOffices(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenConsolidated(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Consolidated
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of professionals consolidated
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenConsolidated(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenConsolidatedMoreWeeks(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Consolidated more weeks
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of professionals consolidated.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenConsolidatedMoreWeeks(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenUnallocated(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Unallocated
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Professionals unallocated on the next 8 weeks.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenUnallocated(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenProjectObservations(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Project observations
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Active project observations
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenProjectObservations(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenProjectTransferPrice(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Project Transfer Price
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Project transfer price
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenProjectTransferPrice(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenLastAllocation(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Professionals last allocations
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Professionals last allocations
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenLastAllocation(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenAllocationSuggestions(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Allocation Suggestions
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Allocation Suggestions Report
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenAllocationSuggestions(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sm={6}>
          <Card>
            <CardActionArea onClick={() => setOpenThreeWeeksDesallocated(true)}>
              <CardContent style={{ minHeight: 100 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Three weeks desallocated
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  List of professionals that has 3 weeks or more desallocated
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions style={{ backgroundColor: '#f5f6f8' }}>
              <Button
                size="small"
                color="primary"
                onClick={() => setOpenThreeWeeksDesallocated(true)}
              >
                Generate
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {
        <NetRevenue
          open={openNetRevenue}
          handleClose={() => setOpenRevenue(false)}
          handleOpen={() => setOpenRevenue(true)}
        />
      }

      {
        <ProfessionalProductivity
          open={openProfessionalProductivity}
          handleClose={() => setOpenProfessionalProductivity(false)}
          handleOpen={() => setOpenProfessionalProductivity(true)}
        />
      }

      {
        <Margin
          open={openMargin}
          handleClose={() => setOpenMargin(false)}
          handleOpen={() => setOpenMargin(true)}
        />
      }

      {
        <ProjectBase
          open={openProjectsBase}
          handleClose={() => setOpenProjectsBase(false)}
          handleOpen={() => setOpenProjectsBase(true)}
        />
      }

      {
        <ProjectBaseLegacy
          open={openProjectsBaseLegacy}
          handleClose={() => setOpenProjectsBaseLegacy(false)}
          handleOpen={() => setOpenProjectsBaseLegacy(true)}
        />
      }

      {
        <ThreeWeeksDesallocated
          open={openThreeWeeksDesallocated}
          handleClose={() => setOpenThreeWeeksDesallocated(false)}
          handleOpen={() => setOpenThreeWeeksDesallocated(true)}
        />
      }

      {
        <AvailableNextWeek
          open={openAvailableNextWeek}
          handleClose={() => setOpenAvailableNextWeek(false)}
          handleOpen={() => setOpenAvailableNextWeek(true)}
        />
      }

      {
        <Seniors
          open={openSeniors}
          handleClose={() => setOpenSeniors(false)}
          handleOpen={() => setOpenSeniors(true)}
        />
      }

      {
        <Offices
          open={openOffices}
          handleClose={() => setOpenOffices(false)}
          handleOpen={() => setOpenOffices(true)}
        />
      }

      {
        <Consolidated
          open={openConsolidated}
          handleClose={() => setOpenConsolidated(false)}
          handleOpen={() => setOpenConsolidated(true)}
        />
      }

      {
        <ConsolidatedMoreWeeks
          open={openConsolidatedMoreWeeks}
          handleClose={() => setOpenConsolidatedMoreWeeks(false)}
          handleOpen={() => setOpenConsolidatedMoreWeeks(true)}
        />
      }

      {
        <Unallocated
          open={openUnallocated}
          handleClose={() => setOpenUnallocated(false)}
          handleOpen={() => setOpenUnallocated(true)}
        />
      }

      {
        <ProjectObservations
          open={openProjectObservations}
          handleClose={() => setOpenProjectObservations(false)}
          handleOpen={() => setOpenProjectObservations(true)}
        />
      }

      {
        <ProjectTransferPrice
          open={openProjectTransferPrice}
          handleClose={() => setOpenProjectTransferPrice(false)}
          handleOpen={() => setOpenProjectTransferPrice(true)}
        />
      }

      {
        <LastAllocation
          open={openLastAllocation}
          handleClose={() => setOpenLastAllocation(false)}
          handleOpen={() => setOpenLastAllocation(true)}
        />
      }

      {
        <AllocationSuggestion
          open={openAllocationSuggestions}
          handleClose={() => setOpenAllocationSuggestions(false)}
          handleOpen={() => setOpenAllocationSuggestions(true)}
        />
      }
    </LandingPage>
  );
}
