import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import {
  Button,
  Slide,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Tabs,
  Tab,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import {
  FiUser,
  FiMail,
  FiBriefcase,
  FiLogIn,
  FiGift,
  FiHome,
  FiSettings,
  FiFlag,
  FiChevronDown,
  FiCheck,
  FiCalendar,
  FiTrash2,
} from 'react-icons/fi';
import TeamsIcon from '../../assets/Teams';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../hooks/auth';
import schedulingGroups from '../../config/schedulingGroups';

import { TabDoDont } from './TabDoDont';
import { TabClientAgreement } from './TabClientAgreement';

import TabPanel from '../../components/TabPanel';

import DialogDelete from './DialogDelete';

import api from '../../services/api';
import ContentLoader from 'react-content-loader';

import userImage from '../../assets/images/user.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
}));

export default function DialogResource({
  professionalId,
  jobtitleId,
  projectId,
  professionalAllocationId,
  onResourceDeleted,
  allowEdit,
  allowDelete,
  isTBD,
  loadAllocation,
  handleCloseTooltip,
  type,
  onReload,
}) {
  const { user, hasScope } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  // const [allowDelete] = useState(_allowDelete);
  // const [professionalAllocationId] = useState(_professionalAllocationId);
  // const [professionalId] = useState(_professionalId);
  // const [projectId] = useState(_projectId);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowDoDontScopes] = useState([
    schedulingGroups.Admin,
    schedulingGroups.Allocation,
    schedulingGroups.CoManager,
    schedulingGroups.Partner,
  ]);
  const [allowDoDont] = useState(() =>
    userScopes.some(scope => allowDoDontScopes.includes(scope)),
  );

  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [login, setLogin] = useState('');
  const [practices, setPractices] = useState('');
  // const [coManagements, setComanagements] = useState('');
  const [email, setEmail] = useState('');
  const [entryAt, setEntryAt] = useState('');
  const [birthday, setBirthday] = useState('');
  const [offices, setOffices] = useState('');
  const [isActive, setIsActive] = useState('');
  const [dodontClassification, setDoDontClassification] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comanagements, setComanagements] = useState('');

  const hasClientAgreementSkills =
    skills.filter(x => x.IDSkillType === 6).length > 0;

  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleDeleteResource = useCallback(() => {
    if (onResourceDeleted) onResourceDeleted(professionalAllocationId);
    setOpenDialogDelete(false);
    handleCloseTooltip();
  }, [onResourceDeleted, professionalAllocationId]);

  const handleOpenProfessional = useCallback(() => {
    const url = `/professionals/${professionalId}`;
    window.open(url, '_blank');
  }, [professionalId]);

  const loadProfessional = useCallback(async () => {
    setLoading(true);
    let professional;

    if (isTBD) {
      const response = await api.get(
        `master/professionals-tbd/${professionalId}`,
      );
      professional = response.data;
    } else {
      const response = await api.get(`master/professionals/${professionalId}`);
      professional = response.data;
    }

    if (professional) {
      setName(professional.Name);
      setEmail(professional.Email);
      setJobTitle(professional.JobTitle.Name);
      setLogin(professional.Login);

      if (professional.EntryAt) {
        const entryAt = parseISO(professional.EntryAt);
        setEntryAt(format(entryAt, 'dd MMM yyyy'));
      }

      if (professional.Birthday) {
        const birthday = parseISO(professional.Birthday);
        setBirthday(format(birthday, 'dd MMM yyyy'));
      }

      if (isTBD) {
        setPractices(professional.Practice.Name);
        setOffices(professional.Office.Name);
      } else {
        const practices = professional.Practices.map(practice => practice.Name);
        setPractices(practices.join(', '));
        const comanagements = professional.CoManagements.map(
          comanagement => comanagement.Name,
        );
        setComanagements(comanagements.join(', '));

        const offices = professional.Offices.map(office => office.Name);
        setOffices(offices.join(', '));

        setIsActive(professional.IsActive ? 'Yes' : 'No');

        setDoDontClassification(
          professional.DoDonts?.DoDontClassifications || [],
        );
        setSkills(professional.Skills || []);
      }

      setLoading(false);
    }
  }, [isTBD, professionalId]);

  useEffect(() => {
    loadProfessional();
  }, [loadProfessional]);

  return (
    <>
      {loading ? (
        <ContentLoader
          speed={2}
          width="100%"
          height={120}
          viewBox={`0 0 500 160`}
          backgroundColor="#f3f3f3"
          foregroundColor="#dedede"
        >
          <rect x="0" y="0" rx="7" ry="7" width="500" height="100" />
          <rect x="0" y="115" rx="7" ry="7" width="500" height="30" />
        </ContentLoader>
      ) : (
        <>
          <div>
            <div className="professional-dialog-top-section">
              {!isTBD && (
                <div className="professional-is-active">
                  <Tooltip title="Professional is active" followCursor>
                    <FiCheck size={15} color="white" />
                  </Tooltip>
                </div>
              )}
              <img
                className="resource-image"
                alt={login}
                src={
                  !isTBD
                    ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${login}_192_192.png`
                    : userImage
                }
              />

              <div className="professional-dialog-header">
                <div className="professional-dialog-header-info">
                  <h2>{name}</h2>
                  <p>{jobTitle}</p>
                  <p>{practices}</p>
                  <p>{offices}</p>
                </div>
                <div className="professional-icons">
                  {!isTBD && (
                    <>
                      <Tooltip title="Send email" followCursor>
                        <button
                          onClick={() =>
                            openInNewTab(`mailto:${login}@integrationconsulting.com
`)
                          }
                        >
                          <FiMail size={25} color="#bbb" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Send message" followCursor>
                        <button
                          onClick={() =>
                            openInNewTab(`https://teams.microsoft.com/l/chat/0/0?users=${login}@integrationconsulting.com
`)
                          }
                        >
                          <TeamsIcon size={25} color="#bbb" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Allocation" followCursor>
                        <button onClick={handleOpenProfessional}>
                          <FiCalendar size={25} color="#bbb" />
                        </button>
                      </Tooltip>
                    </>
                  )}

                  {allowDelete && (
                    <Tooltip title="Remove Professional" followCursor>
                      <button
                        onClick={() => allowEdit && setOpenDialogDelete(true)}
                      >
                        <FiTrash2 size={25} color="#bbb" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className="spacer" />
          </div>
          <div className="agreement">
            <Accordion disabled={!hasClientAgreementSkills}>
              <AccordionSummary expandIcon={<FiChevronDown />}>
                <p>
                  {hasClientAgreementSkills
                    ? 'Client Agreement'
                    : 'No client agreement'}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <TabClientAgreement skills={skills} />
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}

      {openDialogDelete && (
        <DialogDelete
          open={openDialogDelete}
          handleClose={() => setOpenDialogDelete(false)}
          // handleOpen={() => setOpenDialogDelete(true)}
          projectId={projectId}
          id={professionalAllocationId}
          handleDeleteResource={handleDeleteResource}
          isTBD={isTBD}
          loadAllocation={loadAllocation}
          type={type}
          onReload={onReload}
        />
      )}
    </>
  );
}
