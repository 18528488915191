import React, { useEffect, useCallback, useState } from 'react';
import queryString from 'query-string';
import { useAuth } from '../../../hooks/auth';
import { WhisperSpinner } from 'react-spinners-kit';
import Button from '@material-ui/core/Button';

import './styles.css';

export default function Signin(props) {
  const { signInAuthorize, redirectMicrosoft } = useAuth();
  const hash = useState(props.location.hash);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = useCallback(async (accessToken) => {
    try {
      await signInAuthorize(accessToken);
      const from = localStorage.getItem('from');

      if (from && from !== '/') window.location.href = from;
      else window.location.href = '/app';
      localStorage.removeItem('from');
    } catch (err) {
      const errorMessage = err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Error on logon';
      setErrorMessage(errorMessage);
    }
  }, [signInAuthorize]);

  useEffect(() => {
    const params = queryString.parse(hash[0] || hash);
    const from = props.location?.state?.from?.pathname || '/';
    if (params.access_token) {
      handleLogin(params.access_token);
    } else {
      redirectMicrosoft(from);
    }
  }, [handleLogin, hash, redirectMicrosoft, signInAuthorize]);

  return (
    <section className="loading">
      {errorMessage ? (
        <div style={{ textAlign: 'center' }}>
          <p>{errorMessage}</p>
          <Button
            variant="contained" color="primary"
            onClick={redirectMicrosoft}>
            Sign-in with Microsoft
          </Button>
        </div>
      ) : (
        <WhisperSpinner size={80} frontColor="#8b0304" backColor="#8b0304" />
      )}
    </section>
  )
}