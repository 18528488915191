import React, { useCallback, useState } from "react";
import {
  Button,
  Drawer,
  Box,
  Typography,
  CircularProgress
} from "@material-ui/core";

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';

import Upload from "../../../components/Upload";
import api from "../../../services/api";
import FileList from './FileList';
import Loader from './Loader';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import { format } from "date-fns";

export default function DialogImportOTC({ open, handleClose, handleOpen }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesRaw, setFilesRaw] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [week, setWeek] = useState(null);

  const submitFile = useCallback((files) => {
    const filesProps = files.map((file) => ({
      file,
      name: file.name,
      readableSize: file.size.toString(),
    }));

    setFilesRaw(files);

    setUploadedFiles(filesProps);
  }, []);

  const handleSubmit = useCallback(async () => {
    const data = new FormData();
    filesRaw.map((fileRaw) => {
      data.append("file", fileRaw, fileRaw.name);
    });

    data.append('IDScheduleWeek', week);

    try {
      setLoading(true);
      setLoadingMessage('Importing... This could take a while');
      await api.post(`scheduling/finances/otc/import`, data);

      successMessage$.next({ open: true, message: 'Imported with success' });
      handleClose();
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Error on import' });
    }

    setLoading(false);
    setLoadingMessage(null);
  }, [filesRaw, handleClose, week]);

  const handleDownloadTemplate = useCallback(async () => {
    const response = await api.get(`scheduling/finances/otc/import/template`, {
      responseType: 'blob',
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `template-otc.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  const [scheduleWeekSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/schedule-weeks');

        return response.data.map(x => ({
          ...x,
          DisplayName: format(new Date(x.WeekStartDate), 'dd/MMM/yyyy'),
        }));
      },
    }),
    paginate: true,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: "relative" }}
    >
      {loading ? (
        <Loader>
          <CircularProgress color="primary" />
          {!!loadingMessage && <Typography component="p" style={{ marginTop: 15 }}>{loadingMessage}</Typography>}
        </Loader>
      ) : null}

      <Box m={2} minWidth={300}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: "15px" }}
        >
          Import CSV
        </Typography>

        <Typography variant="subtitle2" component="p">
          To import your file please, follow the template below
        </Typography>

        <Button variant="text" fullWidth style={{ margin: "15px 0" }} onClick={handleDownloadTemplate}>
          Download template
        </Button>

        <div style={{ marginBottom: 15 }}>
          <Upload onUpload={submitFile} />
        </div>

        {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

        <Typography variant="subtitle2" component="p">
          Select the week to be considered in the dashboard
        </Typography>
        <Typography variant="subtitle2" component="p">
          If you don't select any week, the current week will be considered
        </Typography>
        <SelectBox
          dataSource={scheduleWeekSource}
          displayExpr="DisplayName"
          valueExpr="ID"
          placeholder="Start typing to search..."
          searchExpr={['DisplayName', 'Year']}
          required
          minSearchLength={3}
          showDataBeforeSearch
          searchEnabled
          value={week}
          onValueChanged={e => setWeek(e.value)}
        />

        <div style={{ position: "absolute", bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Import
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}
