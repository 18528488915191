import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Drawer, Typography } from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';
import GridLoader from './GridLoader.js';

import api from '../../services/api';
import DialogCreatePortal from './DialogCreatePortal';

interface DialogPortalProps {
  projectId: number;
  portalId: number;
  handleClose(): void;
  onSubmitted(): void;
  open: boolean;
}

const DialogPortal: React.FC<DialogPortalProps> = ({
  handleClose,
  open,
  projectId,
  portalId,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [openDialogCreatePortal, setOpenDialogCreatePortal] = useState(false);
  const [portalExists, setPortalExists] = useState(false);

  const checkIfPortalExists = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`scheduling/projects/${projectId}/portal`);
    if (!data) setPortalExists(false);
    else setPortalExists(true);
    setLoading(false);
  }, [projectId]);

  useEffect(() => {
    checkIfPortalExists();
  }, [checkIfPortalExists]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await api.patch(`scheduling/projects/${projectId}/portal`);

      handleClose();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [projectId, handleClose]);

  return (
    <>
      {loading && (
        <GridLoader>
          <WhisperSpinner size={50} frontColor="#8b0304" backColor="#8b0304" />
        </GridLoader>
      )}
      <Box m={2} style={{ minWidth: 350, maxWidth: '100%' }}>
        {/* <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Manage Portal project
        </Typography> */}

        {!loading && portalExists ? (
          <>
            <Button
              style={{ width: '100%', marginBottom: 15 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update Portal
            </Button>
            <Button
              style={{ width: '100%' }}
              variant="contained"
              onClick={() =>
                window.open(
                  `https://portal.integrationconsulting.com/project/${portalId}/home`,
                )
              }
            >
              Open Portal
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            style={{ width: '100%' }}
            variant="contained"
            onClick={() => setOpenDialogCreatePortal(true)}
          >
            CREATE PORTAL
          </Button>
        )}
      </Box>
      {openDialogCreatePortal && (
        <DialogCreatePortal
          projectId={projectId}
          open={openDialogCreatePortal}
          handleClose={() => setOpenDialogCreatePortal(false)}
          onSubmitted={onSubmitted}
        />
      )}
    </>
  );
};

export default DialogPortal;
