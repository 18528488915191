import React from 'react';
import { UserAgentApplication } from 'msal';
import {Client} from '@microsoft/microsoft-graph-client';

class MSAuth extends React.Component {
    constructor(props) {
        super(props);

        this.baseUri = `https://login.microsoftonline.com/${this.props.tenantId}/oauth2/v2.0`;
        this.clientId = this.props.clientId;
        this.tenantId = this.props.tenantId;
        this.redirectUri = this.props.redirectUri;
        this.scopes = this.props.scopes;
        this.nonce = this.props.nonce;
        this.redirectUriSignout = this.props.redirectUriSignout;
        
        // this.userAgentApplication = new UserAgentApplication({
        //     auth: {
        //         clientId: this.clientId,
        //         authority: 'https://login.microsoftonline.com/' + this.tenantId,
        //         redirectUri: this.redirectUri,
        //         navigateToLoginRequestUrl: false
        //     },
        //     cache: {
        //         cacheLocation: "localStorage",
        //         storeAuthStateInCookie: true
        //     }
        // });

        // this.userAgentApplication.handleRedirectCallback((err, response) => {
        //     // Dumb
        // });
    }

    login(response_type, prompt = null, login_hint = null) {
        const uriPieces = {
            client_id: this.clientId,
            response_type,
            redirect_uri: this.redirectUri,
            scope: this.scopes,
            response_mode: 'fragment',
            nonce: this.nonce
        }

        if (login_hint) uriPieces.login_hint = login_hint;
        if (prompt) uriPieces.prompt = prompt;

        const params = new URLSearchParams(uriPieces).toString();
        return `${this.baseUri}/authorize?${params}`;
        // try {
        //     this.userAgentApplication.loginRedirect(
        //     {
        //         scopes: scopes
        //     });
        // }
        // catch(err) {
        //     alert(err);
        // }
    }

    logout(){
        const uriPieces = {
            post_logout_redirect_uri: this.redirectUriSignout,
        }

        const params = new URLSearchParams(uriPieces).toString();
        return `${this.baseUri}/logout?${params}`;
    }

    // async getUserDetails(accessToken) {
    //     // Call graph api
    //     const client = Client.init({
    //         authProvider: (done) => {
    //           done(null, accessToken.accessToken);
    //         }
    //     });

    //     return await client.api('/me').get();
    // }

    // async getUserProfile(beforeCallback, authCallback) {
    //     try {
    //         // Call the function before get the token
    //         beforeCallback();

    //         const user = this.userAgentApplication.getAccount();
    //         console.log(user);

    //         if (user) {
    //             // Get the access token to consume api
    //             var accessToken = await this.userAgentApplication.acquireTokenSilent({
    //                 scopes: this.scopes
    //             });
    
    //             if (accessToken) {
    //                 // Get user information
    //                 const details = {};
                    
    //                 // After get the token, execute the callback below
    //                 authCallback(null, {
    //                     accessToken: accessToken,
    //                     userDetails: details
    //                 });
    //             }
    //         }

    //         authCallback(null, {
    //             accessToken: null,
    //             userDetails: null
    //         });
    //     } catch(err) {
    //         authCallback(err, {
    //             accessToken: null,
    //             userDetails: null
    //         });
    //     }
    // }
}

export default MSAuth;