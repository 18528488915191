import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import {FiChevronRight} from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    padding: '5px 16px 5px 16px'
  }
}));

export default function ControlList() {
  const classes = useStyles();
  const history = useHistory();

  const handleReasonNav = useCallback(() => {
    history.push('/admin/reasons');
  }, [history]);

  const handleClientNav = useCallback(() => {
    history.push('/administrator/clients');
  }, [history]);

  const handleProjectNav = useCallback(() => {
    history.push('/administrator/projects');
  }, [history]);

  const handleEconomicGroupNav = useCallback(() => {
    history.push('/administrator/economic-groups');
  }, [history]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button className={classes.listItem} key={0} onClick={handleReasonNav}>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Reasons
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </React.Fragment>
          }
          style={{ margin: 0 }}
        />
      </ListItem>
      {/* <ListItem button className={classes.listItem} key={0} onClick={handleClientNav}>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Clients
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </React.Fragment>
          }
          style={{ margin: 0 }}
        />
      </ListItem> */}
      {/* <ListItem button className={classes.listItem} key={2} onClick={handleProjectNav}>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Projects
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </React.Fragment>
          }
          style={{ margin: 0 }}
        />
      </ListItem> */}

      {/* <ListItem button className={classes.listItem} key={3} onClick={handleEconomicGroupNav}>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Economic Groups
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </React.Fragment>
          }
          style={{ margin: 0 }}
        />
      </ListItem> */}
    </List>
  )
}