import React from 'react';
import Box from '@material-ui/core/Box';

export default function TabPanel(props) {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3} {...rest}>{children}</Box>
      )}
    </div>
  );
}