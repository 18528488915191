import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Input, FormControlLabel, Switch } from '@material-ui/core';
import CustomStore from "devextreme/data/custom_store";
import { SelectBox } from 'devextreme-react';
import api from '../../services/api';

export default function DialogFilter({ open, handleClose, onFilter, filter: starterFilters }) {
  const [name, setName] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [practice, setPractice] = useState('');
  const [office, setOffice] = useState('');
  const [active, setActive] = useState(true);
  const [filter, setFilter] = useState(null);
  const [practicesSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('master/practices');
        return response.data;
      }
    }),
    paginate: true
  });
  const [officesSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('master/offices');
        return response.data;
      }
    }),
    paginate: true
  });

  useEffect(() => {
    if (starterFilters && starterFilters.Name) {
      setName(starterFilters.Name);
    }
    if (starterFilters && starterFilters.JobTitle) {
      setJobtitle(starterFilters.JobTitle);
    }
    if (starterFilters && starterFilters.Practice) {
      setPractice(starterFilters.Practice);
    }
    if (starterFilters && starterFilters.Office) {
      setOffice(starterFilters.Office);
    }
  }, [starterFilters]);

  const handleApplyFilters = useCallback(() => {
    if (name) {
      setFilter(state => ({...state, Name: name}));
    }
    if (jobtitle) {
      setFilter(state => ({...state, JobTitle: jobtitle}));
    }
    if (practice) {
      setFilter(state => ({...state, Practice: practice}));
    }
    if (office) {
      setFilter(state => ({...state, Office: office}));
    }


    setFilter(state => ({...state, IsActive: active}));
    
  }, [active, jobtitle, name, office, practice]);

  const handleClearFilters = useCallback(() => {
    setFilter({});
  }, []);

  useEffect(() => {
    onFilter(filter);
  }, [filter, onFilter]);

  return (
    <Drawer anchor="top" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Apply filters
        </Typography>

        <FormControl fullWidth>
          <InputLabel htmlFor="professional-name-filter-inside">Name</InputLabel>
          <Input
            id="professional-name-filter-inside"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 8 }}>
          <InputLabel htmlFor="professional-jobtitle-filter">Job Title</InputLabel>
          <Input
            id="professional-jobtitle-filter"
            type="text"
            value={jobtitle}
            onChange={e => setJobtitle(e.target.value)}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 24 }}>
          <SelectBox
            dataSource={practicesSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Practice"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={3}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={practice}
            onValueChanged={e => setPractice(e.value)}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 24 }}>
          <SelectBox
            dataSource={officesSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Office"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={office}
            onValueChanged={e => setOffice(e.value)}
          />
        </FormControl>

        <FormControlLabel
          control={<Switch color="primary" checked={active} onChange={(e) => setActive(e.target.checked)} name="professional-is-active" />}
          label="Active"
          style={{ width: '100%', marginTop: 8 }}
        />

        <div style={{ marginTop: 8, float: 'right' }}>
          <Button 
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button 
            onClick={handleClearFilters}
            color="primary" 
            size="small"
            style={{ marginRight: 15 }}
          >
            Clear filters
          </Button>

          <Button 
            onClick={handleApplyFilters} 
            variant="contained"
            color="primary" 
            size="small"
          >
            Apply filters
          </Button>
        </div>
      </Box>
    </Drawer>
  )
}