import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogDateOutTypeEdit({handleClose, handleOpen, open, id, callback}) {
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [dateOutTypeId] = useState(id);

  useEffect(() => {
    const loadDateOutType = async () => {
      try {
        const dateOutType = await api.get(`scheduling/date-out-types/${dateOutTypeId}`);
        setDescription(dateOutType.data.Description);
  
      } catch (err) {
        errorMessage$.next({open: true, message: 'Error to get date out type information'});
      }
    }

    loadDateOutType();
  }, [dateOutTypeId]);


  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const data = {
        Description: description,
      };

      handleClose();
      await api.put(`scheduling/date-out-types/${dateOutTypeId}`, data);
      
      successMessage$.next({open: true, message: 'Date out type updated successfully'});
      if (callback) callback();

    } catch (err) {
      console.log(err);
      errorMessage$.next({open: true, message: 'Error on update date out type'});
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit "{description}"</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            id="description"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
            style={{marginBottom: 8}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}