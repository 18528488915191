import React from 'react';

import './styles.css';

export default function GridToolbar({children, style}) {
  return (
    <div className="grid-toolbar" style={style}>
      {children}
    </div>
  );
}