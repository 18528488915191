import React, { useCallback, useState } from 'react';
import {
  Button,
  Drawer,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import Upload from '../../../components/Upload';
import api from '../../../services/api';
import FileList from './FileList';
import Loader from './Loader';
import { useAuth } from '../../../hooks/auth';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';

export default function DialogImport({ open, handleClose, handleOpen, type }) {
  const { user } = useAuth();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesRaw, setFilesRaw] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitFile = useCallback(files => {
    const filesProps = files.map(file => ({
      file,
      name: file.name,
      readableSize: file.size.toString(),
    }));

    setFilesRaw(files);

    setUploadedFiles(filesProps);
  }, []);

  const handleSubmit = useCallback(async () => {
    const data = new FormData();
    filesRaw.map(fileRaw => {
      data.append('file', fileRaw, fileRaw.name);
    });

    try {
      setLoading(true);
      setLoadingMessage('Importing... This could take a while');

      await api.post(`scheduling/finances/${type}/import`, data);

      successMessage$.next({ open: true, message: 'Imported with success' });
      handleClose();
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Error on import' });
    }

    setLoading(false);
    setLoadingMessage(null);
  }, [filesRaw, handleClose, type]);

  const handleDownloadTemplate = useCallback(async () => {
    const response = await api.get(
      `scheduling/finances/${type}/import/template`,
      {
        responseType: 'blob',
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `template-${type}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [type]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading ? (
        <Loader>
          <CircularProgress color="primary" />
          {!!loadingMessage && (
            <Typography component="p" style={{ marginTop: 15 }}>
              {loadingMessage}
            </Typography>
          )}
        </Loader>
      ) : null}

      <Box m={2} minWidth={300}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Import CSV
        </Typography>

        <Typography variant="subtitle2" component="p">
          To import your file please, follow the template below
        </Typography>

        <Button
          variant="text"
          fullWidth
          style={{ margin: '15px 0' }}
          onClick={handleDownloadTemplate}
        >
          Download template
        </Button>

        <div style={{ marginBottom: 15 }}>
          <Upload onUpload={submitFile} />
        </div>

        {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Import
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}
