import { Tooltip } from '@material-ui/core';
import React from 'react';

import './styles.css';

interface BoardDescriptionProps {
  description: string;
}

const BoardDescription: React.FC<BoardDescriptionProps> = ({ description }) => {
  return <div className="board-description">{description}</div>;
};

export default BoardDescription;
