import React, { useEffect, useState, useCallback } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { FiEdit2, FiPlus, FiTrash } from 'react-icons/fi';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useControlContent } from '../../../hooks/controlContent';

import ControlList from '../ControlList';
import GridToolbar from '../../../components/GridToolbar';
import GridLoader from '../../../components/GridLoader';

import { Board, BoardContent, BoardHeader } from '../../../components/Board';

import { DialogAuxJobTitle } from './DialogAuxJobTitle';
import { DialogAuxJobTitleEdit } from './DialogAuxJobTitleEdit';
import { DialogAuxJobTitleDelete } from './DialogAuxJobTitleDelete';

import api from '../../../services/api';

export default function AuxJobTitle() {
  const [dataSource, setDataSource] = useState<DataSource>({} as DataSource);
  const [loading, setLoading] = useState(false);
  const [auxJobTitleId, setAuxJobTitleId] = useState(0);
  const [openAddAuxJobTitle, setOpenAddAuxJobTitle] = useState(false);
  const [openEditAuxJobTitle, setOpenEditAuxJobTitle] = useState(false);
  const [openDeleteAuxJobTitle, setOpenDeleteAuxJobTitle] = useState(false);

  const { setControlContent, setPageTitle } = useControlContent();

  // Set control menu
  useEffect(() => {
    setPageTitle('Finance');
    setControlContent(<ControlList />);

    const auxJobTitleStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          setLoading(true);
          const response = await api.get('scheduling/professional-jobtitles');
          setLoading(false);
          return response.data;
        } catch (err) {
          console.log('load professional-jobtitles', err);
        }
      },
    });

    const auxJobTitleDataSource = new DataSource({
      store: auxJobTitleStore,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(auxJobTitleDataSource);
  }, [setControlContent, setPageTitle]);

  const commandColumnRender = useCallback(e => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit" placement="left">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setAuxJobTitleId(e.key);
              setOpenEditAuxJobTitle(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete" placement="left">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setAuxJobTitleId(e.key);
              setOpenDeleteAuxJobTitle(true);
            }}
          >
            <FiTrash size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  const handleAdd = useCallback(() => {
    dataSource.reload();
  }, [dataSource]);

  const handleEdit = useCallback(() => {
    dataSource.reload();
  }, [dataSource]);

  return (
    <Board>
      <BoardHeader
        title="Aux Jobtitles"
        description="Create, edit and delete aux jobtitles to replace the official professional's jobtitles"
      />

      <BoardContent style={{ padding: 0, margin: 0, position: 'relative' }}>
        <GridToolbar style={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Add new aux jobtitle">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              aria-label="Add new aux jobtitle"
              onClick={() => setOpenAddAuxJobTitle(true)}
              startIcon={<FiPlus />}
            >
              Add
            </Button>
          </Tooltip>
        </GridToolbar>

        {loading ? (
          <GridLoader>
            <CircularProgress color="primary" />
          </GridLoader>
        ) : null}

        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          height="100%"
        >
          <FilterRow visible />
          <HeaderFilter visible />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="ID" visible={false} />
          <Column dataField="Professional.Name" />
          <Column dataField="JobTitle.Name" />

          <Column type="buttons" cellRender={commandColumnRender} width={100} />
        </DataGrid>

        {openAddAuxJobTitle ? (
          <DialogAuxJobTitle
            open={openAddAuxJobTitle}
            handleOpen={() => setOpenAddAuxJobTitle(true)}
            handleClose={() => setOpenAddAuxJobTitle(false)}
            callback={handleAdd}
          />
        ) : null}

        {openEditAuxJobTitle ? (
          <DialogAuxJobTitleEdit
            open={openEditAuxJobTitle}
            handleOpen={() => setOpenEditAuxJobTitle(true)}
            handleClose={() => setOpenEditAuxJobTitle(false)}
            callback={handleEdit}
            professionalJobtitleId={auxJobTitleId}
          />
        ) : null}

        {openDeleteAuxJobTitle ? (
          <DialogAuxJobTitleDelete
            open={openDeleteAuxJobTitle}
            handleOpen={() => setOpenDeleteAuxJobTitle(true)}
            handleClose={() => setOpenDeleteAuxJobTitle(false)}
            callback={handleEdit}
            professionalJobtitleId={auxJobTitleId}
          />
        ) : null}
      </BoardContent>
    </Board>
  );
}
