import React, { useState, useCallback } from 'react';
import {
  Drawer,
  Typography,
  CircularProgress,
  Box,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import GridLoader from './GridLoader';

import api from '../../services/api';

import { errorMessage$ } from '../../utils/subjects';

import { Dialog, Slide } from '@material-ui/core';

export default function DialogCreateSimulation({
  projectId,
  handleClose,
  handleOpen,
  open,
  onCreated,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const project = await api.post(
        `scheduling/simulation/projects/${projectId}/copy`,
      );

      setLoading(false);
      onCreated(project.data.ID);
    } catch (err) {
      setLoading(false);
      errorMessage$.next({
        open: true,
        message: err.response && err.response.data.message,
      });
    }
  }, [onCreated, projectId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ padding: 30 }}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Create new simulation
        </Typography>
        <Alert severity="info" style={{ marginTop: 15 }}>
          <Typography component="p">
            The simulation of a project can be changed without impacting the
            actual project. Also, you can access it any time on tab "My
            Simulations"
          </Typography>
        </Alert>
        {loading && (
          <GridLoader>
            <CircularProgress color="primary" />
            <Typography component="p" style={{ marginTop: 15 }}>
              Creating simulation...
            </Typography>
          </GridLoader>
        )}
        <div
          classname="buttons-row"
          style={{ textAlign: 'center', marginTop: 30 }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Create simulation
          </Button>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
