import React, { useState, useCallback } from 'react';

import { Button, Drawer, Box, Typography, FormControl } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import api from '../../../services/api';

import { errorMessage$, successMessage$, infoMessage$ } from '../../../utils/subjects';

export default function ProfessionalProductivity({ open, handleClose, handleOpen }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState(null);

  const handleGenerate = useCallback(async () => {
    try {
      setErrorMessage(null);
      if (!startDate || !endDate) {
        setErrorMessage('Please select the period');
        return;
      }

      infoMessage$.next({ open: true, message: 'We generating your report. It could take a while' });

      const response = await api.get('scheduling/reports/finances/professionals-productivity', {
        params: {
          startDate,
          endDate,
        },
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-professional-productivity.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      successMessage$.next({ open: true, message: 'Your report is ready. Open or save it below' });
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Something were wrong with your report. Try again please.' });
    }
  }, [endDate, startDate]);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2} minWidth={300}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Allocations per professionals
        </Typography>

        {errorMessage && (
          <Alert severity="error" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">{errorMessage}</Typography>
          </Alert>
        )}

        <FormControl fullWidth style={{ marginTop: 15 }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MMM/yyyy"
            margin="normal"
            id="date-picker-start-date"
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 15 }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MMM/yyyy"
            margin="normal"
            id="date-picker-end-date"
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>



        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button
            onClick={handleGenerate}
            variant="contained"
            color="primary"
            size="small"
          >
            Generate
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}