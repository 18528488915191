import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Button, CircularProgress, Badge, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FiClock } from 'react-icons/fi';
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  RangeRule,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { getWeek, getWeekYear } from '../../utils/IntegrationDates';
import ProfessionaLookup from '../../components/ProfessionalLookup/index.tsx';
import WeekHeader from '../Allocation/WeekHeader';
import DialogResource from '../Allocation/DialogResource';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import GridLoaderNew from '../Allocation/GridLoaderNew';

import { errorMessage$, successMessage$ } from '../../utils/subjects';

import schedulingGroups from '../../config/schedulingGroups';
import { on } from 'devextreme/events';

export default function Grid({
  onEditProject,
  projectStatus: _projectStatus,
  onUpdatedMargin,
  onUpdatingMargin,
  projectComanagement: _projectComanagement,
  projectSupportComanagement: _projectSupportComanagement,
  officeId: _officeId,
  portalId,
  showDeallocated,
  setShowDeallocated,
  onReload,
  type, //if it's simulation, allocation or professionaL
  refreshCount,
}) {
  const getCurrentWeek = useCallback(() => {
    const currentWeekNumber = getWeek(new Date());
    const currentWeekYear = getWeekYear(new Date());

    return { WeekNumber: currentWeekNumber, Year: currentWeekYear };
  }, []);

  const gridRef = useRef();
  const history = useHistory();
  const { projectId } = useParams();

  const { user, hasScope } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.CoManager,
    schedulingGroups.Manager,
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
    schedulingGroups.TechLab,
  ]);

  // convert num to string
  const [projectStatus] = useState(() => _projectStatus.toString());
  // const [projectComanagement] = useState(() => _projectComanagement.toString());
  // const [projectSupportComanagement] = useState(() =>
  //   _projectSupportComanagement.toString(),
  // );

  const [currentWeek] = useState(getCurrentWeek().WeekNumber);
  const [closureId, setClosureId] = useState('');
  const [disabledRows, setDisabledRows] = useState([]);
  const [allowEdit, setAllowEdit] = useState(true);
  const [allowChangeStatus, setAllowChangeStatus] = useState(() =>
    userScopes.some(scope => allowEditScopes.includes(scope)),
  );
  const [currentYear] = useState(getCurrentWeek().Year);

  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openDialogLoadConflict, setOpenDialogLoadConflict] = useState(false);
  const [openResource, setOpenResource] = useState(false);
  const [openDeleteResource, setOpenDeleteResource] = useState(false);
  const [openDialogSuggestion, setOpenDialogSuggestion] = useState(false);
  const [professionalId, setProfessionalId] = useState(null);
  const [jobTitleId, setJobTitleId] = useState(null);
  const [allowDelete, setAllowDelete] = useState(true);
  const [professionalAllocationId, setProfessionalAllocationId] =
    useState(null);
  const [totalConflicts, setTotalConflicts] = useState(0);
  const [conflictCells, setConflictCells] = useState([]);
  const [modifiedCells, setModifiedCells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weeks, setWeeks] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [visibleProfessionals, setVisibleProfessionals] = useState([]);
  const [allProfessionals, setAllProfessionals] = useState([]);
  const [isTBD, setIsTBD] = useState();
  const [tooltip, setTooltip] = useState(false);

  const persistAllocation = useCallback(
    async ({ professionalAllocationId: key, allocations }) => {
      try {
        if (onUpdatingMargin) onUpdatingMargin();

        await api.patch(
          `${type}/projects/${projectId}/professionals-allocations/${key}/allocations`,
          allocations,
        );

        if (onUpdatedMargin) onUpdatedMargin();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: err.response && err.response.data.message,
        });
      }
    },
    [onUpdatedMargin, onUpdatingMargin, projectId],
  );

  // Convert allocation json retrieve by api into a valid json to put in the grid
  const allocationResponse = useCallback(professionalsAllocations => {
    const rows = [];
    const modifiedCells = [];

    professionalsAllocations.map(professionalAllocation => {
      if (!professionalAllocation.AllowEdit) {
        setDisabledRows(state => [...state, professionalAllocation.ID]);
      }

      const row = {};

      row.ID = professionalAllocation.ID;
      row.Professional = professionalAllocation.Professional;
      row.JobTitle = professionalAllocation.Position?.Name;
      row.AllowDelete = professionalAllocation.AllowDelete;
      row.AllowEdit = professionalAllocation.AllowEdit;
      row.IsTBD = professionalAllocation.IsTBD;
      row.HasTutor = professionalAllocation.HasTutor;
      row.Allocations = [];

      professionalAllocation.Allocations.map(allocation => {
        const SimulationProjectScheduleWeekId =
          typeof allocation.SimulationProjectScheduleWeek === 'object'
            ? allocation.SimulationProjectScheduleWeek.ID.toString()
            : allocation.SimulationProjectScheduleWeek.toString();
        row[SimulationProjectScheduleWeekId] = allocation.TotalDays;

        row.Allocations.push({
          SimulationProjectScheduleWeekID: Number(
            SimulationProjectScheduleWeekId,
          ),
          TotalDays: allocation.TotalDays,
        });

        if (allocation.IsModified) {
          modifiedCells.push({
            rowKey: professionalAllocation.ID,
            columnKey: SimulationProjectScheduleWeekId,
            previousTotalDays: allocation.PreviousTotalDays,
          });
        }
      });

      rows.push(row);
    });

    setModifiedCells(modifiedCells);

    return rows;
  }, []);

  // const handleEditAllocation = useCallback(
  //   async (key, data) => {
  //     const allocations = Object.keys(data).map(projectScheduleWeek => ({
  //       ProjectScheduleWeek: Number(projectScheduleWeek),
  //       TotalDays: data[projectScheduleWeek],
  //     }));
  //     await persistAllocation({
  //       allocations,
  //       professionalAllocationId: key,
  //     });
  //   },
  //   [persistAllocation],
  // );

  // use effect para filtrar profissionais alocados e nao alocados
  useEffect(() => {
    if (allProfessionals && allProfessionals.length > 0) {
      const filteredAllocations = allProfessionals
        .filter(
          professional =>
            professional.AllowDelete ||
            professional.Allocations.some(
              allocation => allocation.TotalDays !== 0,
            ),
        )
        .filter(professional => professional.AllowEdit);

      setAllocations(filteredAllocations);
    }
  }, [allProfessionals]);

  const handleEditAllocation = useCallback(
    async (key, data) => {
      const newAllocations = Object.keys(data).map(
        SimulationProjectScheduleWeek => ({
          SimulationProjectScheduleWeek: Number(SimulationProjectScheduleWeek),
          TotalDays: data[SimulationProjectScheduleWeek],
        }),
      );

      setAllProfessionals(prevAllocations => {
        const updatedAllocations = prevAllocations.map(allocation => {
          if (allocation.ID === key) {
            newAllocations.map(newAllocation => {
              allocation[
                newAllocation.SimulationSimulationProjectScheduleWeek
              ] = newAllocation.TotalDays;

              const index = allocation.Allocations.findIndex(
                x =>
                  x.SimulationProjectScheduleWeekID ===
                  newAllocation.SimulationProjectScheduleWeek,
              );
              if (allocation.Allocations[index])
                allocation.Allocations[index].TotalDays =
                  newAllocation.TotalDays;
              else {
                allocation.Allocations.push({
                  SimulationProjectScheduleWeekID:
                    newAllocation.SimulationProjectScheduleWeek,
                  TotalDays: newAllocation.TotalDays,
                });
              }
            });
          }

          return allocation;
        });
        return updatedAllocations;
      });

      try {
        await persistAllocation({
          allocations: newAllocations,
          professionalAllocationId: key,
        });
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: err.response && err.response.data.message,
        });
      }
    },
    [persistAllocation],
  );

  const scheduleStore = useMemo(() => {
    return new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: () => {
        return allocations;
      },
      update: (key, data) => {
        scheduleStore.push([
          {
            type: 'update',
            key,
            data,
          },
        ]);
        handleEditAllocation(key, data);
      },
      insert: data => {
        console.log('insert: ', data);
      },
      byKey: (key, data) => {
        console.log(key, data);
      },
    });
  }, [allocations, handleEditAllocation]);

  const handleCloseTooltip = useCallback(() => {
    setTooltip(false);
  }, [setTooltip]);

  const handleDeleteResource = useCallback(
    key => {
      setConflictCells(prevState =>
        prevState.filter(conflictCell => {
          return Number(conflictCell.rowKey) !== Number(key);
        }),
      );

      scheduleStore.push([
        {
          type: 'remove',
          key,
        },
      ]);

      if (onUpdatedMargin) onUpdatedMargin();
      onReload();
    },
    [onUpdatedMargin, scheduleStore],
  );

  // const lastClickedCellEventRef = useRef(null);

  // const handleResourceCellClick = useCallback(
  //   e => {
  //     lastClickedCellEventRef.current = e;
  //     if (
  //       e.rowType === 'data' &&
  //       e.column.dataField === 'Professional' &&
  //       closureId === '' &&
  //       e.data.IsTBD
  //     ) {
  //       setProfessionalAllocationId(e.key);
  //       setOpenDeleteResource(true);
  //       return;
  //     }
  //     if (
  //       e.rowType === 'data' &&
  //       e.column.dataField === 'Professional' &&
  //       closureId === ''
  //     ) {
  //       setJobTitleId(e.data.JobTitle);
  //       setAllowDelete(e.data.AllowDelete);
  //       setProfessionalId(e.data.Professional.ID);
  //       setProfessionalAllocationId(e.key);
  //       setOpenResource(true);
  //       setClickPosition({ x: e.event.pageX, y: e.event.pageY });
  //       setClosedByOutsideClick(false);
  //     }
  //   },
  //   [closureId],
  // );

  // Refresh the closure filter
  useEffect(() => {
    setClosureId('');
    setDisabledRows([]);
  }, [projectId]);

  const loadAllocation = useCallback(async () => {
    setLoading(true);
    if (onUpdatingMargin) onUpdatingMargin();
    setAllocations([]);
    setConflictCells([]);

    // let filter = '';
    // if (closureId !== '') {
    //   filter = `?closureId=${closureId}`;
    // }

    Promise.all([
      api.get(`${type}/projects/${projectId}/schedule-weeks`),
      // api.get(`${type}/projects/${projectId}/conflicts`),
      api.get(`${type}/projects/${projectId}/professionals-allocations`),
    ])
      .then(([weeks, allocations]) => {
        const items = weeks.data.map(item => ({
          ID: item.ID.toString(),
          StartDate: item.ScheduleWeek.WeekStartDate,
          EndDate: item.ScheduleWeek.WeekEndDate,
          WeekNumber: item.ScheduleWeek.WeekNumber,
          Year: item.ScheduleWeek.Year,
          AllowEdit: item.AllowEdit,
        }));

        // const conflictCells = [];

        // conflicts.data.map(conflict => {
        //   conflictCells.push({
        //     rowKey: conflict.ProfessionalAllocation.ID,
        //     columnKey: conflict.ProjectScheduleWeek.ID,
        //   });
        // });

        setWeeks(items);
        if (closureId === '') {
          setConflictCells(conflictCells);
        }

        const allProfessionals = allocations.data;
        setAllProfessionals(allocationResponse(allProfessionals));

        if (onUpdatedMargin) onUpdatedMargin(closureId);
        setShowDeallocated(false);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, [
    allocationResponse,
    closureId,
    onUpdatedMargin,
    onUpdatingMargin,
    projectId,
  ]);

  // Load week on load components
  useEffect(() => {
    loadAllocation();
  }, [loadAllocation]);

  useEffect(() => {
    setTotalConflicts(conflictCells.length);
  }, [conflictCells]);

  const focusOnCurrentWeek = useCallback(() => {
    const { WeekNumber: currentWeekNumber, Year: currentWeekYear } =
      getCurrentWeek();
    const currentWeek = weeks.find(
      week =>
        week.WeekNumber === currentWeekNumber && week.Year === currentWeekYear,
    );

    if (currentWeek && gridRef.current) {
      const grid = gridRef.current.instance;
      const cell = grid.getCellElement(0, currentWeek.ID);
      grid.focus(cell);
    }
  }, [getCurrentWeek, weeks]);

  useEffect(() => {
    setTimeout(focusOnCurrentWeek, 1000);
  }, [focusOnCurrentWeek, weeks]);

  useEffect(() => {
    setAllowEdit(
      _projectStatus !== 'Approved' &&
        _projectStatus !== 'Canceled' &&
        _projectStatus !== 'Closed',
    );
  }, [_projectStatus]);

  function createVisibleProfessionalStore(
    visibleProfessionals,
    handleEditAllocation,
  ) {
    return new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: () => {
        return visibleProfessionals;
      },
      update: (key, data) => {
        scheduleStore.push([
          {
            type: 'update',
            key,
            data,
          },
        ]);
        handleEditAllocation(key, data);
      },
      insert: data => {
        console.log('insert: ', data);
      },
      byKey: (key, data) => {
        console.log(key, data);
      },
    });
  }

  const visibleProfessionalStore = useMemo(() => {
    return createVisibleProfessionalStore(
      visibleProfessionals,
      handleEditAllocation,
    );
  }, [
    createVisibleProfessionalStore,
    visibleProfessionals,
    handleEditAllocation,
  ]);

  useEffect(() => {
    if (showDeallocated) {
      const deallocated = allProfessionals.filter(professional => {
        const hasAllocation = professional.Allocation?.some(
          allocation =>
            professional.AllowEdit === true ||
            (allocation.TotalDays !== 0 && professional.AllowDelete === false),
        );

        const isAlreadyAllocated = allocations.some(
          a => a.ID === professional.ID,
        );
        const shouldBeDisplayed = !hasAllocation && !isAlreadyAllocated;
        return shouldBeDisplayed;
      });
      setVisibleProfessionals([
        ...allocations,
        ...deallocated.map(professional => ({
          ...professional,
          AllocationID: null, // set AllocationID to null for deallocated professionals
          isDeallocated: true, // add a new property to identify deallocated professionals
        })),
      ]);
    } else {
      setVisibleProfessionals([]);
    }
  }, [allProfessionals, allocations, loading, showDeallocated]);

  const handleRowPrepared = e => {
    if (e.data && showDeallocated && e.data.isDeallocated) {
      e.rowElement.classList.add('deallocated-row');
    }
  };

  const isTodayCell = week => {
    const today = new Date();
    const weekStartDate = new Date(week.StartDate);
    const weekEndDate = new Date(week.EndDate);

    return today >= weekStartDate && today <= weekEndDate;
  };

  // const cellRender = useCallback(
  //   e => {
  //     if (e.rowType === 'data') {
  //       const isToday = weeks.some(
  //         week => isTodayCell(week) && week.ID === e.column.dataField,
  //       );
  //       const cellClassName = isToday ? 'today-cell' : '';
  //       const conflictBadge = false;

  //       if (conflictCells.length > 0 || modifiedCells.length > 0) {
  //         const conflictCellIndex = conflictCells.find(
  //           conflictCell =>
  //             conflictCell.rowKey === e.key &&
  //             conflictCell.columnKey === e.column.caption,
  //         );
  //         const modifiedCellIndex = modifiedCells.find(
  //           modifiedCell =>
  //             modifiedCell.rowKey === e.key &&
  //             modifiedCell.columnKey === e.column.caption,
  //         );

  //         if (conflictCellIndex && modifiedCellIndex) {
  //           return (
  //             <Tooltip
  //               title={`Previous value: ${modifiedCellIndex.previousTotalDays} and conflicted`}
  //               placement="top-start"
  //             >
  //               <div className={`modified-cell ${cellClassName}`}>
  //                 <span className="cell-badge conflict-badge" />
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }

  //         if (conflictCellIndex) {
  //           return (
  //             <Tooltip title="Conflicted" placement="top-start">
  //               <div className={cellClassName}>
  //                 <span className="cell-badge conflict-badge" />
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }

  //         if (modifiedCellIndex) {
  //           return (
  //             <Tooltip
  //               title={`Previous value: ${modifiedCellIndex.previousTotalDays}`}
  //               placement="top-start"
  //             >
  //               <div className={`modified-cell ${cellClassName}`}>
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }
  //       }

  //       return (
  //         <div className={cellClassName}>
  //           <span>{e.value}</span>
  //         </div>
  //       );
  //     }

  //     return e.value;
  //   },
  //   [conflictCells, modifiedCells, weeks],
  // );

  const cellRender = useCallback(
    e => {
      if (e.rowType === 'data') {
        const isEditable = e.column.allowEditing; // Get the allowEditing property of the column
        if (conflictCells.length > 0 || modifiedCells.length > 0) {
          const conflictCellIndex = conflictCells.find(
            conflictCell =>
              conflictCell.rowKey == e.key &&
              conflictCell.columnKey == e.column.caption,
          );
          const modifiedCellIndex = modifiedCells.find(
            modifiedCell =>
              modifiedCell.rowKey == e.key &&
              modifiedCell.columnKey == e.column.caption,
          );

          if (conflictCellIndex && modifiedCellIndex) {
            return (
              <Tooltip
                title={`Previous value: ${modifiedCellIndex.previousTotalDays} and conflicted`}
                placement="top-start"
              >
                <div className="modified-cell">
                  <span className="cell-badge conflict-badge"></span>
                  <span className={!isEditable && 'not-editable-cell'}>
                    {e.value}
                  </span>
                </div>
              </Tooltip>
            );
          }

          if (conflictCellIndex) {
            return (
              <Tooltip title="Conflicted" placement="top-start">
                <div>
                  <span className="cell-badge conflict-badge"></span>
                  <span className={!isEditable && 'not-editable-cell'}>
                    {e.value}
                  </span>
                </div>
              </Tooltip>
            );
          }

          if (modifiedCellIndex) {
            return (
              <Tooltip
                title={`Previous value: ${modifiedCellIndex.previousTotalDays}`}
                placement="top-start"
              >
                <div className="modified-cell">
                  <span>{e.value}</span>
                </div>
              </Tooltip>
            );
          }
        }
        const isToday = weeks.some(
          week => isTodayCell(week) && week.ID === e.column.dataField,
        );
        const cellClassName = isToday ? 'today-cell' : '';
        const conflictBadge = false;

        if (!isEditable) {
          // Add a class when the cell is not editable
          return (
            <div className={`${cellClassName} not-editable-cell`}>
              <span>{e.value}</span>
            </div>
          );
        }
      }

      return e.value;
    },
    [conflictCells, modifiedCells],
  );

  const onSubmittedWeeks = useCallback(
    weeks => {
      loadAllocation();
    },
    [loadAllocation],
  );

  const onStatusChanged = useCallback(
    async status => {
      setOpenChangeStatus(false);

      // reload allocation to update de modified cells
      const allocations = await api.get(
        `${type}/projects/${projectId}/professionals-allocations`,
      );

      setAllocations(allocationResponse(allocations.data));

      setAllowEdit(
        status !== 'Approved' && status !== 'Canceled' && status !== 'Closed',
      );
      onEditProject({
        ID: Number(projectId),
        ProjectStatus: status,
      });
    },
    [allocationResponse, onEditProject, projectId],
  );
  const tooltipHeight = 300;
  const [cellElement, setCellElement] = useState();
  // const handleCellPrepared = e => {
  //   if (
  //     e.rowType === 'data' &&
  //     e.column.dataField === 'Professional' &&
  //     closureId === '' &&
  //     e.data.IsTBD
  //   ) {
  //     e.cellElement.onclick = () => {
  //       const rect = e.cellElement.getBoundingClientRect();
  //       setProfessionalAllocationId(e.key);
  //       setTooltip({
  //         x: rect.left + window.scrollX,
  //         y: rect.top + window.scrollY,
  //       });
  //     };
  //   }

  //   if (
  //     e.rowType === 'data' &&
  //     e.column.dataField === 'Professional' &&
  //     closureId === ''
  //   ) {
  //     e.cellElement.onclick = () => {
  //       setCellElement(e.cellElement);
  //       const rect = e.cellElement.getBoundingClientRect();
  //       setJobTitleId(e.data.JobTitle);
  //       setAllowDelete(e.data.AllowDelete);
  //       setProfessionalId(e.data.Professional.ID);
  //       setProfessionalAllocationId(e.key);
  //       setTooltip({
  //         x: rect.left + window.scrollX,
  //         y: rect.top + window.scrollY,
  //       });
  //     };
  //   }

  //   // e.cellElement.onmouseleave = () => {
  //   //   setTooltip(null);
  //   // };
  // };

  const handleCellClick = useCallback(
    e => {
      if (
        e.rowType === 'data' &&
        e.column.dataField === 'Professional' &&
        closureId === ''
      ) {
        e.event.stopPropagation();
        const rect = e.cellElement.getBoundingClientRect();
        setJobTitleId(e.data.JobTitle);
        setAllowDelete(e.data.AllowDelete);
        setProfessionalId(e.data.Professional.ID);
        setProfessionalAllocationId(e.key);
        setIsTBD(e.data.IsTBD);
        setTooltip(true);
      }
    },
    [
      setJobTitleId,
      setAllowDelete,
      setProfessionalId,
      setProfessionalAllocationId,
      setTooltip,
    ],
  );

  const handleClickOutside = useCallback(
    event => {
      const tooltipElement = document.querySelector('.resource-dialog');
      const deleteDialog = document.querySelector('.delete-dialog');

      if (
        tooltipElement &&
        !tooltipElement.contains(event.target) &&
        tooltip &&
        !deleteDialog
      ) {
        setTooltip(false);
      }
    },
    [setTooltip, tooltip],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  // useEffect(() => {
  //   const handleClick = event => {
  //     // Check if the click happened inside the cell or the tooltip
  //     console.log(event);
  //     if (
  //       event.target.closest('.dx-datagrid-content') === cellElement ||
  //       event.target('.resource-dialog')
  //     ) {
  //       return;
  //     }

  //     // Click happened outside the cell and the tooltip, so remove the tooltip
  //     setTooltip(null);
  //   };

  //   document.addEventListener('click', handleClick);

  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, [cellElement]);

  // const handleMouseOver = (e, content) => {
  //   console.log(e)
  //   const rect = e.target.getBoundingClientRect();
  //   setTooltip({
  //     x: rect.left + window.scrollX,
  //     y: rect.top + window.scrollY,
  //   });
  // };

  // const handleMouseOut = () => {
  //   setTooltip(null);
  // };

  const onCreatedSimulation = useCallback(
    simulationProjectId => {
      history.push(`/simulation/${simulationProjectId}/allocations`);
    },
    [history],
  );
  // Check permissions (scopes)
  useEffect(() => {
    const found = userScopes.some(scope => allowEditScopes.includes(scope));

    if (!found) {
      setAllowChangeStatus(false);
      setAllowEdit(false);
    } else {
      const isCoManager = userScopes.includes(schedulingGroups.CoManager);
      const isTechLab = userScopes.includes(schedulingGroups.TechLab);

      if (isCoManager) {
        const isEditableStatus =
          _projectStatus !== 'Approved' &&
          _projectStatus !== 'Canceled' &&
          _projectStatus !== 'Closed' &&
          _projectStatus !== 'Waiting validation';
        console.log(
          isEditableStatus,
          user,
          _projectComanagement,
          _projectSupportComanagement,
          _officeId,
        );
        setAllowChangeStatus(
          isEditableStatus &&
            (user.comanagementsIds.includes(_projectComanagement) ||
              (_projectSupportComanagement &&
                user.comanagementsIds.includes(
                  Number(_projectSupportComanagement),
                )) ||
              user.officeId.includes(_officeId)),
        );
        setAllowEdit(
          isEditableStatus &&
            (user.comanagementsIds.includes(_projectComanagement) ||
              (_projectSupportComanagement &&
                user.comanagementsIds.includes(
                  Number(_projectSupportComanagement),
                )) ||
              user.officeId.includes(_officeId)),
        );
      }

      if (isTechLab) {
        setAllowChangeStatus(false);
      }
    }
  }, [
    _projectComanagement,
    _projectStatus,
    _projectSupportComanagement,
    _officeId,
    allowEdit,
    allowEditScopes,
    user,
    userScopes,
  ]);

  useEffect(() => {
    loadAllocation();
  }, [loadAllocation, refreshCount]);

  // const [gridHeight, setGridHeight] = useState(window.innerHeight - 330);

  // useEffect(() => {
  //   const handleResize = () => setGridHeight(window.innerHeight - 330);
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <>
      <section id="allocation">
        {loading ? (
          <GridLoaderNew style={{ paddingTop: 30 }} />
        ) : (
          <>
            <Tooltip
              title="Go to current week"
              placement="top"
              arrow
              followCursor
            >
              <Button
                className="current-button"
                size="small"
                onClick={focusOnCurrentWeek}
              >
                <FiClock size={16} />
              </Button>
            </Tooltip>
            <DataGrid
              onRowPrepared={handleRowPrepared}
              ref={gridRef}
              scrolling="standard"
              className={tooltip ? 'disable-scroll' : ''}
              key={allocations.length}
              dataSource={
                showDeallocated ? visibleProfessionalStore : scheduleStore
              }
              showBorders={false}
              remoteOperations
              // rowAlternationEnabled
              height="100%"
              showColumnLines
              id="simulation-grid"
              onCellClick={handleCellClick}
              allowColumnResizing={false}
              loadPanel={null}
              // onCellPrepared={handleCellPrepared}
              onEditingStart={e => {
                if (disabledRows.includes(e.key)) {
                  e.cancel = true;
                }
              }}
            >
              <Paging enabled={false} />
              {/* <Scrolling columnRenderingMode="standard" mode="standard" /> */}
              <Editing
                mode="cell"
                allowUpdating={allowEdit}
                selectTextOnEditStart
                startEditAction="click"
              />

              <Column
                dataField="Professional"
                fixed
                allowEditing={false}
                calculateSortValue="Professional.Name"
                defaultSortOrder="asc"
                cellRender={data =>
                  ProfessionaLookup({
                    Name: data.value.Name,
                    JobTitle: {
                      Name: data.data.Position?.Name
                        ? data.data.Position.Name
                        : data.data.JobTitle,
                    },
                    ID: data.value.ID,
                    Login: data.value.Login,
                    IsTBD: data.value.IsTBD,
                    HasClientAgreement: data.value.Skills?.some(
                      x => x.IDSkillType === 6,
                    ),
                    HasTutor: data.data.HasTutor,
                    // IsTooltipOpen: isTooltipOpen,
                    // ProjectId: projectId,
                    // ProfessionalAllocationId: professionalAllocationId,
                    OnResourceDeleted: handleDeleteResource,
                    // AllowEdit: allowEdit,
                    // AllowDelete: allowDelete,
                  })
                }
                cssClass="cursor-pointer"
                width={250}
              />

              {/* <Column dataField="JobTitle" visible={false} />
<Column dataField="AllowDelete" visible={false} /> */}

              {weeks.map((week, index) => (
                <Column
                  alignment="center"
                  key={week.ID}
                  dataField={week.ID}
                  caption={week.caption}
                  allowSorting={false}
                  headerCellRender={() => (
                    <WeekHeader
                      isToday={
                        currentWeek === week.WeekNumber &&
                        week.Year === currentYear
                          ? true
                          : !week.AllowEdit
                          ? 'disabled-column'
                          : false
                      }
                      week={week}
                      index={index}
                      isEditable={
                        allowEdit && week.AllowEdit && closureId === ''
                      }
                    />
                  )}
                  width={65}
                  cellRender={cellRender}
                  allowEditing={allowEdit && week.AllowEdit && closureId === ''}
                  cssClass={
                    currentWeek === week.WeekNumber && week.Year === currentYear
                      ? 'highlight-cell'
                      : null
                  }
                >
                  <RangeRule max={5} min={0} message="" />
                </Column>
              ))}

              <Column caption="" />
            </DataGrid>
          </>
        )}
      </section>
      {tooltip && (
        <div
          className="resource-dialog"
          // style={{
          //   height: tooltipHeight,
          //   left: tooltip.x - 50,
          //   top: tooltip.y - tooltipHeight,
          // }}
        >
          <DialogResource
            professionalId={professionalId}
            jobTitleId={jobTitleId}
            projectId={projectId}
            professionalAllocationId={professionalAllocationId}
            onResourceDeleted={handleDeleteResource}
            allowEdit={allowEdit}
            allowDelete={allowDelete}
            isTBD={isTBD}
            loadAllocation={loadAllocation}
            handleCloseTooltip={handleCloseTooltip}
            type={type}
          />
        </div>
      )}
    </>
  );
}
