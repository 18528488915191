import React, { useEffect, useState, useCallback } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { FiEdit2, FiPlus, FiTrash } from 'react-icons/fi';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useControlContent } from '../../../hooks/controlContent';

import ControlList from '../ControlList';
import GridToolbar from '../../../components/GridToolbar';
import GridLoader from '../../../components/GridLoader';

import { Board, BoardContent, BoardHeader } from '../../../components/Board';

import DialogPracticeMargin from './DialogPracticeMargin';
import DialogPracticeMarginEdit from './DialogPracticeMarginEdit';
import DialogPracticeMarginDelete from './DialogPracticeMarginDelete';

import api from '../../../services/api';

export default function Currency() {
  const [dataSource, setDataSource] = useState([]);
  const [practiceMarginId, setPracticeMarginId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddPracticeMargin, setOpenAddPracticeMargin] = useState(false);
  const [openEditPracticeMargin, setOpenEditPracticeMargin] = useState(false);
  const [openDeletePracticeMargin, setOpenDeletePracticeMargin] =
    useState(false);

  const { setControlContent, setPageTitle } = useControlContent();

  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/tax-periods');
        const taxPeriodsWithDisplayValue = [];
        response.data.map(taxPeriod => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });
  const [practiceSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/comanagements');

        return response.data;
      },
    }),
    paginate: true,
  });

  // Set control menu
  useEffect(() => {
    setPageTitle('Finance');
    setControlContent(<ControlList />);

    const practiceMarginSource = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          setLoading(true);
          const response = await api.get('scheduling/practices-margins');

          setLoading(false);
          return response.data;
        } catch (err) {
          console.log('practice margin rates', err);
        }
      },
    });

    const practiceMarginDataSource = new DataSource({
      store: practiceMarginSource,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(practiceMarginDataSource);
  }, [setControlContent, setPageTitle]);

  const commandColumnRender = useCallback(e => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit" placement="left">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setPracticeMarginId(e.key);
              setOpenEditPracticeMargin(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete" placement="left">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setPracticeMarginId(e.key);
              setOpenDeletePracticeMargin(true);
            }}
          >
            <FiTrash size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  const handleAdd = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  const handleEdit = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  return (
    <Board>
      <BoardHeader
        title="Practices margins"
        description="Create, edit and delete practice margin"
      />

      <BoardContent style={{ padding: 0, margin: 0, position: 'relative' }}>
        <GridToolbar style={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Add new practice margin">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              aria-label="Add new practice margin"
              onClick={() => setOpenAddPracticeMargin(true)}
              startIcon={<FiPlus />}
            >
              Add
            </Button>
          </Tooltip>
        </GridToolbar>

        {loading ? (
          <GridLoader>
            <CircularProgress color="primary" />
          </GridLoader>
        ) : null}

        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          key="ID"
          height="100%"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="Practice" caption="Practice">
            <Lookup
              dataSource={practiceSource}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column dataField="MarginValue" caption="Margin" />
          <Column dataField="TaxPeriod.ID" caption="Tax Period">
            <Lookup
              dataSource={taxPeriodSource}
              displayExpr="DisplayValue"
              valueExpr="ID"
            />
          </Column>

          <Column
            type="buttons"
            cellRender={commandColumnRender}
            width={100}
          ></Column>
        </DataGrid>

        {openAddPracticeMargin ? (
          <DialogPracticeMargin
            open={openAddPracticeMargin}
            handleOpen={() => setOpenAddPracticeMargin(true)}
            handleClose={() => setOpenAddPracticeMargin(false)}
            callback={handleAdd}
          />
        ) : null}

        {openEditPracticeMargin ? (
          <DialogPracticeMarginEdit
            open={openEditPracticeMargin}
            handleOpen={() => setOpenEditPracticeMargin(true)}
            handleClose={() => setOpenEditPracticeMargin(false)}
            callback={handleEdit}
            id={practiceMarginId}
          />
        ) : null}

        {openDeletePracticeMargin ? (
          <DialogPracticeMarginDelete
            open={openDeletePracticeMargin}
            handleOpen={() => setOpenDeletePracticeMargin(true)}
            handleClose={() => setOpenDeletePracticeMargin(false)}
            callback={handleEdit}
            id={practiceMarginId}
          />
        ) : null}
      </BoardContent>
    </Board>
  );
}
