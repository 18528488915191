import React, { useEffect, useState, useCallback } from 'react';

import {
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';

import { useControlContent } from '../../../hooks/controlContent';
import ControlList from '../ControlList';
import LandingPage from '../../../components/LandingPage';
import DialogImport from './DialogImport';
import DialogImportOTC from './DialogImportOTC';

import './styles.css';

export default function ImportData() {
  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [openDialogImportOTC, setOpenDialogImportOTC] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const { setControlContent, setPageTitle } = useControlContent();

  // Build control menu
  useEffect(() => {
    setPageTitle('Finance');
    setControlContent(<ControlList />);
  }, [setControlContent, setPageTitle]);

  const handleOpenDialog = useCallback(type => {
    setOpenDialogImport(true);
    setDialogType(type);
  }, []);

  return (
    <>
      <LandingPage
        title="Import Data"
        subtitle="Import csv with OTC for dashboard, expenses, tax rate, etc"
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card>
              <CardActionArea onClick={() => setOpenDialogImportOTC(true)}>
                <CardContent style={{ minHeight: 130 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import OTC for Dashboard
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import OTC for Dashboard
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setOpenDialogImportOTC(true)}
                >
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardActionArea
                onClick={() => handleOpenDialog('sell-value-types')}
              >
                <CardContent style={{ minHeight: 130 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import expenses, ID One and ajustments
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import project expenses, ID One, extensions and more
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog('sell-value-types')}
                >
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardActionArea onClick={() => handleOpenDialog('tax-rates')}>
                <CardContent style={{ minHeight: 130 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import tax rate
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import professionals tax rates
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog('tax-rates')}
                >
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card>
              <CardActionArea onClick={() => handleOpenDialog('margin')}>
                <CardContent style={{ minHeight: 130 }}>
                  <Typography gutterBottom variant="body1" component="h2">
                    Import Margin
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Import SalesPipeline proposal margin
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ backgroundColor: '#f5f6f8' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog('margin')}
                >
                  Import
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </LandingPage>

      {openDialogImport ? (
        <DialogImport
          open={openDialogImport}
          handleClose={() => setOpenDialogImport(false)}
          handleOpen={() => handleOpenDialog(true)}
          type={dialogType}
        />
      ) : null}

      {openDialogImportOTC ? (
        <DialogImportOTC
          open={openDialogImportOTC}
          handleClose={() => setOpenDialogImportOTC(false)}
          handleOpen={() => setOpenDialogImportOTC(true)}
        />
      ) : null}
    </>
  );
}
