import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import { FiExternalLink } from 'react-icons/fi';

import api from '../../services/api';
import { errorMessage$ } from '../../utils/subjects';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
} from 'devextreme-react/data-grid';
import Currency from '../Finance/Currency';

export default function DialogAuditSellValueType({
  handleClose,
  handleOpen,
  open,
}) {
  const [sellValueTypes, setSellValueTypes] = useState([]);

  // const brlAmountFormat = useMemo(() => ({ style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }), []);

  const [professionalsSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals');
        console.log(api.get('master/professionals'));

        return response.data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    const sellValueTypesStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          const response = await api.get('scheduling/sell-value-types/new');

          return response.data;
        } catch (err) {
          errorMessage$.next({
            open: true,
            message: 'Error on list sell value types',
          });
        }
      },
    });

    const sellValueTypesSource = new DataSource({
      store: sellValueTypesStore,
      paginate: true,
      reshapeOnPush: true,
    });

    setSellValueTypes(sellValueTypesSource);
  }, []);

  const handleNavigationNewTab = useCallback(projectId => {
    window.open(`/projects/${projectId}/allocations`);
  }, []);

  const commandColumnRender = useCallback(
    e => (
      <>
        <Tooltip title="Open allocation" aria-label="open" placement="left">
          <IconButton
            aria-label="Open allocation"
            size="small"
            onClick={() => handleNavigationNewTab(e.data.Project.ID)}
          >
            <FiExternalLink size={18} />
          </IconButton>
        </Tooltip>
      </>
    ),
    [handleNavigationNewTab],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Audit value adjustments</DialogTitle>
      <DialogContent>
        <DataGrid
          dataSource={sellValueTypes}
          showBorders={false}
          remoteOperations={false}
          key="ID"
          height="100%"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="Project.ProjectCode" caption="Code" />
          <Column dataField="Currency" caption="Currency">
            <Lookup
              dataSource={currencySource}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>

          <Column
            dataField="MainAmount"
            caption="Amount"
            format="fixedPoint"
            formatOptions="{ precision: 2 }"
          />
          <Column dataField="Operation.Description" caption="Operation" />
          <Column
            dataField="SellValueType.Description"
            caption="Sell Value Type"
          />
          <Column
            dataField="UpdatedAt"
            caption="Date"
            dataType="date"
            format="dd/MMM/yyyy"
          />
          <Column dataField="UpdatedBy" caption="Updated By">
            <Lookup
              dataSource={professionalsSource}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>

          <Column
            type="buttons"
            cellRender={commandColumnRender}
            width={100}
          ></Column>
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
