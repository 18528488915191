import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import io from "socket.io-client";
import Grid from './Grid.js';
import {
  parseISO,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addDays,
} from 'date-fns';
import ContentLoader from 'react-content-loader';

import ControlList from './ControlList';
import ChipStatus from '../../components/ChipStatus';
import ProjectHeaderInfo from '../Allocation/ProjectHeaderInfo.js';
import { useControlContent } from '../../hooks/controlContent';
import Tools from '../../components/ToolsMenu/index.js';

import { errorMessage$ } from '../../utils/subjects';

import { Alert } from '@material-ui/lab';

import {
  Board,
  BoardContent,
  BoardHeader,
  BoardActions,
} from '../../components/Board';

import '../Allocation/styles.css';

import api from '../../services/api';

export default function Allocation() {
  const history = useHistory();
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [projectValueLocal, setProjectValueLocal] = useState('');
  const [currentMargin, setCurrentMargin] = useState('');
  const [firstAllocationMargin, setFirstAllocationMargin] = useState('');
  const [lastWeekMargin, setLastWeekMargin] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [marginLoading, setMarginLoading] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [missingDataInfo, setMissingDataInfo] = useState('');
  const [isLegacy, setIsLegacy] = useState(false);
  const [mainCurrencyId, setMainCurrencyId] = useState(2);
  const [comanagement, setComanagement] = useState('');
  const [supportComanagement, setSupportComanagement] = useState('');
  const [portalId, setPortalId] = useState('');

  const [office, setOffice] = useState(0);
  const [showDeallocated, setShowDeallocated] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const handleReloadComponent = () => {
    setRefreshCount(refreshCount + 1);
  };

  const handleNavigation = useCallback(
    projectId => {
      history.push(`/simulation/${projectId}/allocations`);
    },
    [history],
  );

  const handleBack = useCallback(() => {
    history.push('/allocations');
  }, [history]);

  const { setControlContent, setPageTitle, setGoBack } = useControlContent();

  // Build control menu
  useEffect(() => {
    setPageTitle('My Simulations');

    setControlContent(
      <ControlList
        editedProject={editedProject}
        selected={projectId}
        onSelectProject={handleNavigation}
      />,
    );
    // setGoBack(() => handleBack());
  }, [
    editedProject,
    handleBack,
    handleNavigation,
    projectId,
    setControlContent,
    setGoBack,
    setPageTitle,
  ]);

  const handleMarginUpdating = useCallback(() => {
    setMarginLoading(true);
  }, []);

  const handleMarginUpdated = useCallback(
    closureId => {
      const closure = closureId && closureId !== '' ? closureId : null;

      api
        .get(`scheduling/simulation/projects/${projectId}/margin`, {
          params: {
            closureId: closure,
          },
        })
        .then(project => {
          setMarginLoading(false);
          setCurrentMargin(project.data.MarginCurrent);
        })
        .catch(err => {
          setMarginLoading(false);
          errorMessage$.next({ open: true, message: 'Cannot update margin' });
        });
    },
    [projectId],
  );

  useEffect(() => {
    try {
      const loadProject = async () => {
        const project = await api.get(
          `scheduling/simulation/projects/${projectId}`,
        );
        setProjectName(project.data.ProjectName);
        setProjectCode(project.data.ProjectCode);
        setProjectStatus(project.data.ProjectStatus.Description);
        setMissingDataInfo(project.data.MissingFinancialDataInfo);
        setMainCurrencyId(project.data.IDMainCurrency);
        setComanagement(
          project.data.Comanagement && project.data.Comanagement.ID,
        );
        setSupportComanagement(
          project.data.SupportComanagement &&
            project.data.SupportComanagement.ID,
        );
        setOffice(project.data.Office && project.data.Office.ID);

        setProjectValue(
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: project.data?.MainCurrency?.Name || 'BRL',
          }).format(project.data.ProjectValueTotal),
        );
        setPortalId(project.data.IDPortal);

        setProjectValueLocal(
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: project.data?.Currency?.Name || 'BRL',
          }).format(project.data.ProjectValueTotalLocal),
        );
        setIsLegacy(project.data.IsLegacy);

        setCurrentMargin(project.data.MarginCurrent);
        setFirstAllocationMargin(project.data.MarginFirstAllocation);
        setLastWeekMargin(project.data.MarginLastWeek);

        if (project.data.StartDate && project.data.EndDate) {
          const startDate = parseISO(project.data.StartDate);
          const endDate = parseISO(project.data.EndDate);
          setStartDate(
            format(
              startOfWeek(addDays(startDate, 2), { weekStartsOn: 1 }),
              'dd MMM yyyy',
            ),
          );
          setEndDate(
            format(
              endOfWeek(subWeeks(endDate, 1), { weekStartsOn: 6 }),
              'dd MMM yyyy',
            ),
          );
        } else {
          setStartDate('-');
          setEndDate('-');
        }

        setLoading(false);
      };

      setLoading(true);
      loadProject();
    } catch (err) {
      setLoading(false);
      errorMessage$.next({ open: true, message: 'Error on load project info' });
    }
  }, [projectId]);

  function handleEditProject(projectInfo) {
    if (projectInfo.ProjectName) {
      setProjectName(projectInfo.ProjectName);
    }
    if (projectInfo.ProjectCode) {
      setProjectCode(projectInfo.ProjectCode);
    }
    if (projectInfo.ProjectStatus) {
      setProjectStatus(projectInfo.ProjectStatus);
    }

    setEditedProject(projectInfo);
  }

  return (
    <>
      <Board>
        {!loading ? (
          <BoardHeader
            title={projectName}
            masterTitle={projectCode}
            description={
              <div className="description">
                <p>
                  {startDate} — {endDate}
                </p>
              </div>
            }
            headerRight={
              <BoardActions>
                <ProjectHeaderInfo
                  projectValue={projectValue}
                  currentMargin={currentMargin}
                  firstAllocationMargin={firstAllocationMargin}
                  lastWeekMargin={lastWeekMargin}
                  loadingMargin={marginLoading}
                  projectValueLocal={projectValueLocal}
                  isLegacy={isLegacy}
                  mainCurrencyId={mainCurrencyId}
                  missingDataInfo={missingDataInfo}
                />
              </BoardActions>
            }
            chip={<ChipStatus status={projectStatus} />}
          >
            {missingDataInfo && (
              <Alert severity="warning" style={{ margin: '30px 30px 0 0' }}>
                <p>Financial data missing. Numbers may be incorrect.</p>
                <p>Warning: {missingDataInfo}</p>
              </Alert>
            )}
          </BoardHeader>
        ) : (
          <div>
            <ContentLoader
              speed={2}
              viewBox="0 0 900 120"
              backgroundColor="#f3f3f3"
              foregroundColor="#dedede"
            >
              <rect x="0" y="26" rx="3" ry="3" width="400" height="30" />
              <rect x="0" y="71" rx="3" ry="3" width="260" height="23" />
            </ContentLoader>
          </div>
        )}
        <Grid
          onEditProject={handleEditProject}
          projectStatus={projectStatus}
          onUpdatedMargin={handleMarginUpdated}
          onUpdatingMargin={handleMarginUpdating}
          projectComanagement={comanagement}
          projectSupportComanagement={supportComanagement}
          portalId={portalId}
          officeId={office}
          showDeallocated={showDeallocated}
          setShowDeallocated={setShowDeallocated}
          onReload={handleReloadComponent}
          type="scheduling/simulation"
          refreshCount={refreshCount}
        />
      </Board>
      <Tools
        onEditProject={handleEditProject}
        projectStatus={projectStatus}
        onUpdatedMargin={handleMarginUpdated}
        onUpdatingMargin={handleMarginUpdating}
        projectComanagement={comanagement}
        projectSupportComanagement={supportComanagement}
        portalId={portalId}
        officeId={office}
        showDeallocated={showDeallocated}
        setShowDeallocated={setShowDeallocated}
        onReload={handleReloadComponent}
        type="scheduling/simulation"
      />
    </>
  );
}
