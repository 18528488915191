import React, { useEffect, useState, useCallback } from 'react';

import { BiBuildings } from 'react-icons/bi';

import { format, parseISO } from 'date-fns';

import { Container, Timeline, TimelineItem } from './styles';

import api, { ISkill, IClient } from '../../../services/api';

interface TabClientAgreementProps {
  skills: ISkill[];
}

export const TabClientAgreement: React.FC<TabClientAgreementProps> = ({
  skills,
}) => {
  const [clients, setClients] = useState<IClient[]>([]);

  const loadClients = useCallback(async () => {
    const { data } = await api.get<IClient[]>('master/clients');

    setClients(
      data.map(item => ({
        ...item,
        Name: `${(!item.IsActive && '(Inactive)') || ''} ${item.Name}`,
      })),
    );
  }, []);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  return (
    <Container>
      <div className="dosdonts">
        <div className="dosDontsList">
          <Timeline>
            {skills
              .filter(x => x.IDSkillType === 6)
              .map(item => (
                <TimelineItem key={item.ID} iconBackgroud="#8b0304">
                  <div>
                    <div className="icon" style={{ color: '#fff' }}>
                      <BiBuildings />
                    </div>
                    <span className="hr" />
                  </div>

                  <div className="content">
                    <span>
                      {item.UpdatedAt &&
                        format(parseISO(item.UpdatedAt), 'dd MMM yyyy HH:mm')}
                    </span>
                    <div className="title">
                      <div>
                        <h4>{item.SkillType.CaptionDescription1}: </h4>
                        <p>
                          {
                            clients.find(
                              x => x.ID === Number(item.Description1),
                            )?.Name
                          }
                        </p>
                      </div>
                      <div>
                        <h4>{item.SkillType.CaptionDescription2}: </h4>
                        <p>
                          {item.Description2 === '1'
                            ? 'Exclusivity'
                            : 'Information Barrier'}
                        </p>
                      </div>
                      <div>
                        <h4>{item.SkillType.CaptionComment}:</h4>
                        <p>
                          {item.Comment &&
                            item.Comment.split('\n').map(str => <p>{str}</p>)}
                        </p>
                      </div>
                      <div>
                        <h4>{item.SkillType.CaptionExpiredAt}:</h4>
                        <p>
                          {item.ExpiredAt &&
                            format(parseISO(item.ExpiredAt), 'dd MMM yyyy')}
                        </p>
                      </div>
                    </div>
                  </div>
                </TimelineItem>
              ))}
          </Timeline>
        </div>
      </div>
    </Container>
  );
};
