import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Drawer,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ChipStatus from '../../components/ChipStatus';

import GridLoader from './GridLoader';

import { errorMessage$, successMessage$ } from '../../utils/subjects';

import api from '../../services/api';
import { addDays, format, parseISO, startOfWeek } from 'date-fns';

export default function DialogProjectStatus({
  projectId,
  handleClose,
  handleOpen,
  open,
  onStatusChanged,
  onLoadedConflicts,
  closureId,
  onStartDateChanged,
}) {
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectStatusDescription, setProjectStatusDescription] = useState('');
  const [projectStatusObservation, setProjectStatusObservation] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    Promise.all([
      api.get('scheduling/project-status'),
      api.get(`scheduling/projects/${projectId}`),
    ]).then(([statuses, project]) => {
      setStatuses(statuses.data);
      setProjectStatusDescription(project.data.ProjectStatus.Description || '');
      setProjectStatusObservation(project.data.ProjectStatusObservation || '');
      setProjectName(project.data.ProjectName);
      setProjectCode(project.data.ProjectCode);
      setStatus(project.data.ProjectStatus.ID);
      setStartDate(project.data.StartDateReal || project.data.StartDate);
      setWeeks(
        project.data.ProjectScheduleWeeks.map(
          x => x.ScheduleWeek.WeekStartDate,
        ),
      );
      setLoading(false);
    });
  }, [projectId]);

  useEffect(() => {
    if (projectId !== '' && closureId !== '') {
      api
        .get(`scheduling/schedule-weeks/${projectId}/closures/${closureId}`)
        .then(response => {
          setProjectStatusObservation(response.data.ProjectStatusObservation);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [projectId, closureId]);

  const handleChangeStatusConfirm = useCallback(async () => {
    try {
      setLoadingMessage('Changing the status...');
      setLoading(true);

      await api.put(`scheduling/projects/${projectId}`, {
        ProjectStatusObservation: projectStatusObservation,
        StartDateReal: startDate,
      });

      switch (status) {
        case 1:
          await api.patch(`scheduling/projects/${projectId}/status/draft`);

          successMessage$.next({ open: true, message: 'Status changed' });

          if (onStatusChanged) onStatusChanged('Draft');

          break;

        case 2:
          await api.patch(
            `scheduling/projects/${projectId}/status/waiting-validation`,
          );

          successMessage$.next({ open: true, message: 'Status changed' });

          if (onStatusChanged) onStatusChanged('Waiting validation');

          break;

        case 3:
          if (projectStatusDescription !== 'Waiting validation') {
            setErrorMessage(
              'Must be passed to Waiting validation before change to Approved',
            );
            setLoading(false);
            setLoadingMessage(null);
            return;
          }
          await api.patch(`scheduling/projects/${projectId}/status/approved`);

          successMessage$.next({ open: true, message: 'Status changed' });

          if (onStatusChanged) onStatusChanged('Approved');

          break;

        case 5:
          await api.patch(`scheduling/projects/${projectId}/status/closed`);

          successMessage$.next({ open: true, message: 'Status changed' });

          if (onStatusChanged) onStatusChanged('Closed');

          break;

        case 6:
          await api.patch(`scheduling/projects/${projectId}/status/canceled`);

          successMessage$.next({ open: true, message: 'Status changed' });

          if (onStatusChanged) onStatusChanged('Canceled');

          break;

        default:
          break;
      }
      handleClose();

      if (onStartDateChanged) onStartDateChanged(parseISO(startDate));
    } catch (err) {
      setLoading(false);
      if (err.response) {
        errorMessage$.next({ open: true, message: err.response.data.message });
      } else {
        errorMessage$.next({ open: true, message: 'Error' });
      }
    }
  }, [
    onStatusChanged,
    projectStatusDescription,
    projectId,
    projectStatusObservation,
    status,
    startDate,
  ]);

  const handleChangeStatus = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMessage(null);

      if (status === 1) {
        // draft
        handleChangeStatusConfirm();
      } else if (status === 2) {
        // waiting validation
        setLoadingMessage('Loading all conflicts...');
        const response = await api.post(
          `scheduling/projects/${projectId}/conflicts/validate`,
        );

        if (response.data.length > 0) {
          setConfirmMessage(true);
          onLoadedConflicts(response.data);
          setLoading(false);
          setLoadingMessage(null);
        } else {
          handleChangeStatusConfirm();
        }
      } else if (status === 3) {
        // approved
        setLoadingMessage('Loading all conflicts...');
        const response = await api.post(
          `scheduling/projects/${projectId}/conflicts/validate`,
        );

        if (response.data.length > 0) {
          setErrorMessage("Can't approved with conflicts");
          onLoadedConflicts(response.data);
          setLoading(false);
          setLoadingMessage(null);
        } else {
          handleChangeStatusConfirm();
        }
      } else if (status === 5) {
        // closed
        handleChangeStatusConfirm();
      } else if (status === 6) {
        // canceled
        handleChangeStatusConfirm();
      } else {
        errorMessage$.next({ open: true, message: 'Status not valid' });
      }
    } catch (err) {
      errorMessage$.next({
        open: true,
        message:
          (err.response && err.response.data && err.response.data.message) ||
          'Error',
      });
    }
  }, [handleChangeStatusConfirm, onLoadedConflicts, projectId, status]);

  const handleBeforeClose = useCallback(() => {
    api.put(`scheduling/projects/${projectId}`, {
      ProjectStatusObservation: projectStatusObservation,
    });

    handleClose();
  }, [handleClose, projectId, projectStatusObservation]);

  return (
    <>
      <Typography
        variant="subtitle1"
        component="h6"
        style={{ fontWeight: 500 }}
      >
        {projectName && projectCode && `${projectName} - ${projectCode}`}
      </Typography>

      <Typography
        variant="body2"
        component="p"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span>Current Status:</span>
        <ChipStatus status={projectStatusDescription} />
      </Typography>

      {!!errorMessage && (
        <Alert severity="error" style={{ marginTop: 15 }}>
          <Typography variant="inherit" component="p">
            {errorMessage}
          </Typography>
        </Alert>
      )}

      {!confirmMessage ? (
        <>
          {closureId === '' && (
            <FormControl style={{ width: '100%', marginTop: 15 }}>
              <InputLabel id="project-status-label">Project Status</InputLabel>
              <Select
                labelId="project-status-label"
                id="project-status-select"
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                {statuses.map(status => (
                  <MenuItem key={status.ID} value={status.ID}>
                    {status.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl style={{ width: '100%', marginTop: 15 }}>
            <Typography
              variant="inherit"
              component="p"
              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            >
              Is the project start date right?
            </Typography>
            <Select
              labelId="project-start-date-label"
              id="project-start-date-select"
              onChange={e => setStartDate(e.target.value)}
              value={startDate}
            >
              {weeks.map(week => (
                <MenuItem key={week} value={week}>
                  {format(
                    startOfWeek(addDays(parseISO(week), 2), {
                      weekStartsOn: 1,
                    }),
                    'dd MMM yyyy',
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Observation"
            id="project-status-observation"
            value={projectStatusObservation}
            type="text"
            onChange={e => setProjectStatusObservation(e.target.value)}
            style={{ marginTop: 16 }}
            multiline
            rows={6}
            variant="outlined"
            InputProps={{
              readOnly: closureId !== '',
            }}
          />

          <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
            <Button
              onClick={handleBeforeClose}
              size="small"
              style={{ marginRight: 15 }}
            >
              Cancel
            </Button>

            <Button
              onClick={handleChangeStatus}
              variant="contained"
              color="primary"
              size="small"
            >
              Change status
            </Button>
          </div>
        </>
      ) : (
        <>
          <Alert severity="warning" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">
              There is some allocations with conflicts
            </Typography>
            <Typography variant="inherit" component="p">
              You can confirm or cancel to check it out first
            </Typography>
          </Alert>

          <Button
            onClick={handleBeforeClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Check it out
          </Button>

          <Button
            onClick={handleChangeStatusConfirm}
            variant="contained"
            color="primary"
            size="small"
          >
            Confirm even with conflicts
          </Button>
        </>
      )}
    </>
  );
}
