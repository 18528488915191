import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogTaxRateDelete({handleClose, handleOpen, open, id, callback}) {
  const classes = useStyles();
  const [taxRateId] = useState(id);
  const [taxPeriod, setTaxPeriod] = useState('');
  const [jobtitle, setJobtitle] = useState('');

  useEffect(() => {
    const loadTaxRate = async () => {
      try {
        const taxRate = await api.get(`scheduling/tax-rates/${taxRateId}`);

        setTaxPeriod(`${taxRate.data.TaxPeriod.Period}Q - ${taxRate.data.TaxPeriod.Year}`);
        setJobtitle(taxRate.data.Position.Name);
      } catch (err) {
        errorMessage$.next({open: true, message: 'Error to get tax rate information'});
      }
    }

    loadTaxRate();
  }, [taxRateId]);


  async function handleSubmit(e) {
    e.preventDefault();

    try {
      handleClose();
      await api.delete(`scheduling/tax-rates/${taxRateId}`);
      
      successMessage$.next({open: true, message: 'Tax rate deleted successfully'});
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({open: true, message: 'Error on delete tax rate'});
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Do you confirm?</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent>
          <p>
            Do you would to like to delete the tax rate for 
            <strong style={{ fontWeight: 500 }}> "{jobtitle}" </strong> 
            for the period 
            <strong style={{ fontWeight: 500 }}> "{taxPeriod}" </strong>?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}