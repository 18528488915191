import React, { useEffect, useState, useCallback } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { FiEdit2, FiPlus, FiTrash } from 'react-icons/fi';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useControlContent } from '../../../hooks/controlContent';

import ControlList from '../ControlList';
import GridToolbar from '../../../components/GridToolbar';
import GridLoader from '../../../components/GridLoader';

import { Board, BoardContent, BoardHeader } from '../../../components/Board';

import DialogTaxRate from './DialogCostRate';
import DialogTaxRateEdit from './DialogCostRateEdit';
import DialogTaxRateDelete from './DialogCostRateDelete';

import api from '../../../services/api';

export default function CostRate() {
  const [dataSource, setDataSource] = useState([]);
  const [taxRateId, setTaxRateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddTaxRate, setOpenAddTaxRate] = useState(false);
  const [openEditTaxRate, setOpenEditTaxRate] = useState(false);
  const [openDeleteTaxRate, setOpenDeleteTaxRate] = useState(false);
  const [jobtitleSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/jobtitles');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [officeSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/offices');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/tax-periods');
        const taxPeriodsWithDisplayValue = [];
        response.data.map(taxPeriod => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });

  const { setControlContent, setPageTitle } = useControlContent();

  // Set control menu
  useEffect(() => {
    setPageTitle('Finance');
    setControlContent(<ControlList />);

    const costRateStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          setLoading(true);
          const response = await api.get('scheduling/cost-rates');

          setLoading(false);
          return response.data;
        } catch (err) {
          console.log('load cost-rates', err);
        }
      },
    });

    const costRateDataSource = new DataSource({
      store: costRateStore,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(costRateDataSource);
  }, [setControlContent, setPageTitle]);

  const commandColumnRender = useCallback(e => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit" placement="left">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setTaxRateId(e.key);
              setOpenEditTaxRate(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete" placement="left">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setTaxRateId(e.key);
              setOpenDeleteTaxRate(true);
            }}
          >
            <FiTrash size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  const handleAdd = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  const handleEdit = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  return (
    <Board>
      <BoardHeader
        title="Cost Rate"
        description="Create, edit and delete cost rates"
      />

      <BoardContent style={{ padding: 0, margin: 0, position: 'relative' }}>
        <GridToolbar style={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Add new tax rates">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              aria-label="Add new tax rates"
              onClick={() => setOpenAddTaxRate(true)}
              startIcon={<FiPlus />}
            >
              Add
            </Button>
          </Tooltip>
        </GridToolbar>

        {loading ? (
          <GridLoader>
            <CircularProgress color="primary" />
          </GridLoader>
        ) : null}

        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          key="ID"
          height="100%"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="IDPosition" caption="JobTitle">
            <Lookup
              dataSource={jobtitleSource}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column dataField="IDOffice" caption="Office">
            <Lookup
              dataSource={officeSource}
              displayExpr="Name"
              valueExpr="ID"
            />
          </Column>
          <Column dataField="MainValue" caption="Value (US Dollar)" />
          <Column dataField="Month" />
          <Column dataField="Year" />

          <Column
            type="buttons"
            cellRender={commandColumnRender}
            width={100}
          ></Column>
        </DataGrid>

        {openAddTaxRate ? (
          <DialogTaxRate
            open={openAddTaxRate}
            handleOpen={() => setOpenAddTaxRate(true)}
            handleClose={() => setOpenAddTaxRate(false)}
            callback={handleAdd}
          />
        ) : null}

        {openEditTaxRate ? (
          <DialogTaxRateEdit
            open={openEditTaxRate}
            handleOpen={() => setOpenEditTaxRate(true)}
            handleClose={() => setOpenEditTaxRate(false)}
            callback={handleEdit}
            id={taxRateId}
          />
        ) : null}

        {openDeleteTaxRate ? (
          <DialogTaxRateDelete
            open={openDeleteTaxRate}
            handleOpen={() => setOpenDeleteTaxRate(true)}
            handleClose={() => setOpenDeleteTaxRate(false)}
            callback={handleEdit}
            id={taxRateId}
          />
        ) : null}
      </BoardContent>
    </Board>
  );
}
