import React, { useEffect, useState, useCallback } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { FiEdit2, FiPlus, FiTrash } from 'react-icons/fi';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  Lookup,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useControlContent } from '../../../hooks/controlContent';

import ControlList from '../ControlList';
import GridToolbar from '../../../components/GridToolbar';
import GridLoader from '../../../components/GridLoader';

import { Board, BoardContent, BoardHeader } from '../../../components/Board';

import DialogCurrency from './DialogCurrency';
import DialogCurrencyEdit from './DialogCurrencyEdit';
import DialogCurrencyDelete from './DialogCurrencyDelete';

import api from '../../../services/api';

export default function Currency() {
  const [dataSource, setDataSource] = useState([]);
  const [currencyId, setCurrencyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAddCurrency, setOpenAddCurrency] = useState(false);
  const [openEditCurrency, setOpenEditCurrency] = useState(false);
  const [openDeleteCurrency, setOpenDeleteCurrency] = useState(false);

  const { setControlContent, setPageTitle } = useControlContent();

  // Set control menu
  useEffect(() => {
    setPageTitle('Finance');
    setControlContent(<ControlList />);

    const currencySource = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          setLoading(true);
          const response = await api.get('master/currencies');

          setLoading(false);
          return response.data;
        } catch (err) {
          console.log('currency rates', err);
        }
      },
    });

    const currencyDataSource = new DataSource({
      store: currencySource,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(currencyDataSource);
  }, [setControlContent, setPageTitle]);

  const commandColumnRender = useCallback(e => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit" placement="left">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setCurrencyId(e.key);
              setOpenEditCurrency(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete" placement="left">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setCurrencyId(e.key);
              setOpenDeleteCurrency(true);
            }}
          >
            <FiTrash size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  const handleAdd = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  const handleEdit = useCallback(
    data => {
      dataSource.reload();
    },
    [dataSource],
  );

  return (
    <Board>
      <BoardHeader
        title="Currencies"
        description="Create, edit and delete currency"
      />

      <BoardContent style={{ padding: 0, margin: 0, position: 'relative' }}>
        <GridToolbar style={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Add new currency">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              aria-label="Add new currency"
              onClick={() => setOpenAddCurrency(true)}
              startIcon={<FiPlus />}
            >
              Add
            </Button>
          </Tooltip>
        </GridToolbar>

        {loading ? (
          <GridLoader>
            <CircularProgress color="primary" />
          </GridLoader>
        ) : null}

        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          key="ID"
          height="100%"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="Name" caption="Currency (ISO)" />
          <Column dataField="Abbreviation" caption="Abbreviation" />

          <Column
            type="buttons"
            cellRender={commandColumnRender}
            width={100}
          ></Column>
        </DataGrid>

        {openAddCurrency ? (
          <DialogCurrency
            open={openAddCurrency}
            handleOpen={() => setOpenAddCurrency(true)}
            handleClose={() => setOpenAddCurrency(false)}
            callback={handleAdd}
          />
        ) : null}

        {openEditCurrency ? (
          <DialogCurrencyEdit
            open={openEditCurrency}
            handleOpen={() => setOpenEditCurrency(true)}
            handleClose={() => setOpenEditCurrency(false)}
            callback={handleEdit}
            id={currencyId}
          />
        ) : null}

        {openDeleteCurrency ? (
          <DialogCurrencyDelete
            open={openDeleteCurrency}
            handleOpen={() => setOpenDeleteCurrency(true)}
            handleClose={() => setOpenDeleteCurrency(false)}
            callback={handleEdit}
            id={currencyId}
          />
        ) : null}
      </BoardContent>
    </Board>
  );
}
