import React, { useEffect } from 'react';

import './styles.css';

import { Tooltip } from '@material-ui/core';
import userImage from '../../assets/images/user.png';

import { IJobTitle } from '../../services/api';

interface ProfessionaLookupProps {
  Name: string;
  Login: string;
  ID: number;
  JobTitle: IJobTitle;
  HasClientAgreement: boolean;
  HasTutor?: boolean;
}

const ProfessionaLookup: React.FC<ProfessionaLookupProps> = ({
  Name,
  Login,
  ID,
  JobTitle,
  HasClientAgreement = false,
  HasTutor = true,
}) => {
  //   useEffect(() => {
  // console.log()
  //   }, []);
  return (
    <div className="user-cell">
      <img
        className="user-image"
        alt={Name}
        src={
          Login
            ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${Login}_192_192.png`
            : `${userImage}`
        }
      />
      <div className="user-info">
        <div className="user-name">{Name}</div>
        <div className="secondary-info">{JobTitle.Name}</div>
      </div>

      {HasClientAgreement && (
        <Tooltip title="Client agreement" placement="right">
          <div className="user-cell-alert">!</div>
        </Tooltip>
      )}
      {HasTutor === false && (
        <Tooltip title="Missing Tutor" placement="right">
          <div className="user-cell-alert-tutor">?</div>
        </Tooltip>
      )}
    </div>
  );
};

export default ProfessionaLookup;
