import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import NumberFormatValue from '../../../components/NumberFormat';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CustomStore from "devextreme/data/custom_store";
import { SelectBox } from "devextreme-react";

import { errorMessage$, successMessage$ } from "../../../utils/subjects";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogTaxRateEdit({
  handleClose,
  handleOpen,
  open,
  callback,
  id
}) {
  const classes = useStyles();

  const [taxRateId] = useState(id);
  const [office, setOffice] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [workHours, setWorkHours] = useState('8');
  const [feeHourlyValue, setFeeHourlyValue] = useState('');
  const [feeDailyValue, setFeeDailyValue] = useState('');
  const [BRLValue, setBRLValue] = useState('');
  const [taxPeriod, setTaxPeriod] = useState(null);
  const [currency, setCurrency] = useState(1);
  const [currencyName, setCurrencyName] = useState(null);
  const [exchangeRates, setExchangeRates] = useState([]);

  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("scheduling/tax-periods");
        const taxPeriodsWithDisplayValue = [];
        response.data.map((taxPeriod) => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });
  const [jobtitleSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("master/jobtitles");

        return response.data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`
        }));

        return currencyWithDisplayName;
      }
    }),
    paginate: true
  });
  const [officeSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("master/offices");

        return response.data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    api.get('scheduling/exchange-rates', {
      params: {
        where: {
          TaxPeriod: taxPeriod,
        },
      }
    }).then(response => {
      setExchangeRates(response.data);
    });
  }, [taxPeriod]);

  useEffect(() => {
    setFeeDailyValue(feeHourlyValue * workHours);
  }, [feeHourlyValue, workHours]);

  useEffect(() => {
    const currencyValue = exchangeRates.find(item => item.Currency.ID === currency);
    console.log(currency, exchangeRates);

    if (currencyValue) {
      setBRLValue(feeDailyValue * currencyValue.BRLValue);
    } else {
      setBRLValue(feeDailyValue);
    }

  }, [currency, exchangeRates, feeDailyValue]);

  useEffect(() => {
    api.get(`scheduling/tax-rates/${taxRateId}`).then(response => {
      const taxRate = response.data;

      setOffice(taxRate.Office.ID);
      setJobtitle(taxRate.Position.ID);
      setWorkHours(taxRate.WorkHours);
      setCurrency(taxRate.Currency.ID);
      setCurrencyName(taxRate.Currency.Abbreviation);
      setFeeHourlyValue(taxRate.FeeHourlyValue);
      setTaxPeriod(taxRate.TaxPeriod.ID);
    }).catch(err => {
      errorMessage$.next({open: true, message: 'Error to get tax rate information'});
    });
  }, [taxRateId]);
  
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const data = {
        Office: office,
        Position: jobtitle,
        WorkHours: workHours,
        Currency: currency,
        TaxPeriod: taxPeriod,
        FeeHourlyValue: feeHourlyValue,
      };

      handleClose();
      await api.put(`scheduling/tax-rates/${taxRateId}`, data);

      successMessage$.next({
        open: true,
        message: "Tax rate updated successfully",
      });
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: "Error on update tax rate",
      });
    }
  }, [callback, currency, feeHourlyValue, handleClose, jobtitle, office, taxPeriod, taxRateId, workHours]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">Edit tax rate</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={jobtitleSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select jobtitle"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={jobtitle}
            required={true}
            onValueChanged={e => {
              setJobtitle(e.value);
            }}
            style={{marginBottom: 24}}
          />
          <SelectBox
            dataSource={officeSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the office"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={office}
            required={true}
            onValueChanged={(e) => {
              setOffice(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
          <SelectBox
            dataSource={taxPeriodSource}
            displayExpr="DisplayValue"
            valueExpr="ID"
            placeholder="Select the period"
            searchExpr={['DisplayValue', 'Period', 'Year']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={taxPeriod}
            required={true}
            onValueChanged={(e) => {
              setTaxPeriod(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <SelectBox
            dataSource={currencySource}
            displayExpr="DisplayName"
            valueExpr="ID"
            placeholder="Select a currency"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={currency}
            required={true}
            onValueChanged={e => {
              setCurrencyName(e.component.option("text").split('-')[1]);
              setCurrency(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <TextField
            fullWidth
            label="Work hours"
            id="work-hours"
            value={workHours}
            required={true}
            type="number"
            onChange={e => setWorkHours(e.target.value)}
            style={{ marginBottom: 24, marginTop: 8 }}
          />
          <TextField
            fullWidth
            label="Hourly value"
            id="hourly-value"
            value={feeHourlyValue}
            required={true}
            onChange={e => setFeeHourlyValue(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatValue,
              startAdornment: <InputAdornment position="start">{currencyName || 'R$'}</InputAdornment>,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Daily value"
            id="dayly-value"
            value={feeDailyValue}
            required={true}
            InputProps={{
              readOnly: true,
              inputComponent: NumberFormatValue,
              startAdornment: <InputAdornment position="start">{currencyName || 'R$'}</InputAdornment>,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="BRL value"
            id="brl-value"
            value={BRLValue}
            required={true}
            InputProps={{
              readOnly: true,
              inputComponent: NumberFormatValue,
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
