import React, { useState } from 'react';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import { FiDollarSign, FiPercent } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import schedulingGroups from '../../config/schedulingGroups';

export default function ProjectHeaderInfo({
  projectValue,
  currentMargin,
  firstAllocationMargin,
  lastWeekMargin,
  marginSales,
  loadingMargin,
  projectValueLocal,
  mainCurrencyId,
  isLegacy,
  missingDataInfo,
}) {
  const { user } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);

  return (
    <div className="project-header-info">
      {userScopes && !userScopes.includes(schedulingGroups.ReadAll) && (
        <>
          <div className="header-divider">
            <div className="project-border">
              <span>Value</span>
              {projectValue}
            </div>
            <div className="project-border">
              <span>1st allocation</span>
              {mainCurrencyId === 2 && isLegacy
                ? 'N/A'
                : `${firstAllocationMargin || '-'}%`}
            </div>
          </div>
          <div className="header-divider">
            <div className="project-border">
              <span>Local value</span>
              {projectValueLocal}
            </div>
            <div className="project-border">
              <span>Last week</span>
              {`${lastWeekMargin || '-'}%`}
            </div>
          </div>
        </>
      )}
      <div className="header-divider">
        <div className="project-border margin">
          <span>Margin</span>
          {loadingMargin ? (
            <ContentLoader
              speed={2}
              width={90}
              height={33}
              viewBox="0 0 90 33"
              backgroundColor="#f3f3f3"
              foregroundColor="#dedede"
            >
              <rect x="3" y="5" rx="3" ry="3" width="85" height="20" />
            </ContentLoader>
          ) : !missingDataInfo ? (
            `${currentMargin?.toFixed(2)}%`
          ) : (
            '-'
          )}
        </div>
        <div className="project-border">
          <span>Sales Margin</span>
          {`${marginSales || '-'}%`}
        </div>
      </div>
    </div>
  );
}
