import React, { useEffect, useCallbackm, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { useControlContent } from '../../hooks/controlContent';

import ControlList from './ControlList';

import LandingPage from '../../components/LandingPage';
import DialogSimulation from '../SimulationAllocation/DialogProject';

export default function SimulationHome() {
  const history = useHistory();
  const { setControlContent, setPageTitle, pageTitle, setGoBack } =
    useControlContent();

  const handleBack = useCallback(() => {
    history.push('/allocations');
  }, [history]);

  const handleNavigation = useCallback(
    projectId => {
      history.push(`/simulation/${projectId}/allocations`);
    },
    [history],
  );

  const [openDialogSimulation, setOpenDialogSimulation] = useState(false);
  const handleCreatedSimulation = useCallback(
    data => {
      history.push(`/simulation/${data.ID}/allocations`);
    },
    [history],
  );

  // Build control menu
  useEffect(() => {
    setPageTitle('My Simulations');
    setControlContent(<ControlList onSelectProject={handleNavigation} />);
    // setGoBack(() => handleBack());
  }, [
    handleBack,
    handleNavigation,
    setControlContent,
    setGoBack,
    setPageTitle,
  ]);

  return (
    <LandingPage
      title={pageTitle}
      subtitle="Use the menu on the left side to take a look on your simulations"
    >
      <div className="home-big-buttons-wrapper">
        <button
          onClick={() => setOpenDialogSimulation(true)}
          className="home-big-buttons"
          type="button"
        >
          Create simulation
        </button>
      </div>
      {openDialogSimulation && (
        <DialogSimulation
          open={openDialogSimulation}
          // projectId={projectId}
          handleClose={() => setOpenDialogSimulation(false)}
          handleOpen={() => setOpenDialogSimulation(true)}
          callback={handleCreatedSimulation}
        />
      )}
    </LandingPage>
  );
}
