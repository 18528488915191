import React, { useEffect } from 'react';
import { Drawer, Typography, CircularProgress, Box } from '@material-ui/core';

import GridLoader from './GridLoader';

import api from '../../services/api';

import { errorMessage$ } from '../../utils/subjects';

export default function DialogLoadConflict({
  projectId,
  handleClose,
  handleOpen,
  open,
  onLoadedConflicts,
  onReload,
}) {
  useEffect(() => {
    Promise.all([
      api.post(`scheduling/projects/${projectId}/conflicts/validate`),
      api.post(`scheduling/projects/${projectId}/modifieds/validate`),
    ])
      .then(() => {
        onLoadedConflicts();
        onReload();
      })
      .catch(() => {
        errorMessage$.next({ open: true, message: 'Error on load conflicts' });
      });
  }, [onLoadedConflicts, projectId, onReload]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
      disableBackdropClick
    >
      <Box m={2} style={{ minWidth: 250, maxWidth: '100%' }}>
        <GridLoader>
          <CircularProgress color="primary" />
          <div style={{ marginTop: 30, textAlign: 'center' }}>
            <Typography
              component="p"
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontFamily: 'Roboto Condensed',
              }}
            >
              Loading all conflicts
            </Typography>
            <Typography
              component="p"
              style={{ color: '#666', fontFamily: 'Roboto Condensed' }}
            >
              This could take a while
            </Typography>
          </div>
        </GridLoader>
      </Box>
    </Drawer>
  );
}
