import React from 'react';

import './styles.css';

export default function BoardHeaderLeft({ children }) {

  return (
    <div className="board-header-left">
      { children }
    </div>
  );
}