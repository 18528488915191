import React from 'react';

import './styles.css';

export default function LandingPage({ title, subtitle, children }) {
  return (
    <section className="landing-page">
      <div className="landing-page-header">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>

      {children}
    </section>
  );
}
