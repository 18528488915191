import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';

export default function ProjectLookup(data) {
  return (
    <div className="project-lookup">
      <p className="project-lookup-code">
        {data.value.DateOutType
          ? data.value.DateOutType.Description
          : data.value.ProjectCode || 'No code'}
      </p>

      <p className="project-lookup-name">
        {data.value.ProjectName ? data.value.ProjectName : ''}
      </p>
    </div>
  );
}
