import React, { useState, useCallback, useEffect } from 'react';

import {
  Button,
  Drawer,
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

import api from '../../services/api';

import {
  successMessage$,
  infoMessage$,
  errorMessage$,
} from '../../utils/subjects';

export default function ClosureDialog({ open, handleClose, handleOpen }) {
  const [splitPromotion, setSplitPromotion] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState('');
  const [isMonthlyClosure, setIsMonthlyClosure] = useState(false);
  const [
    openConfirmGenerateWeeklyClosure,
    setOpenConfirmGenerateWeeklyClosure,
  ] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      await api.post(`scheduling/schedule-weeks/closures`, {
        splitPromotion,
        isMonthlyClosure,
      });

      successMessage$.next({
        open: true,
        message: 'Success on weekly closure. Please, reload the page.',
      });
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: `Error: ${err.response}`,
      });
      return;
    }
  }, [splitPromotion, isMonthlyClosure]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        style={{ position: 'relative' }}
      >
        <Box m={2} width={300}>
          <Typography
            variant="h5"
            component="h5"
            style={{ fontWeight: 500, marginBottom: '15px' }}
          >
            Weekly Closure
          </Typography>

          <Alert severity="info" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">
              If don't have any promotions, this field will be ignored.
            </Typography>
          </Alert>

          <FormControl fullWidth style={{ marginTop: 15 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MMM/yyyy"
              margin="normal"
              id="date-picker-start-date"
              label="Split Promotion Date"
              value={splitPromotion}
              onChange={date => setSplitPromotion(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </FormControl>

          <FormControl fullWidth style={{ marginTop: 15 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMonthlyClosure}
                  onChange={event => setIsMonthlyClosure(event.target.checked)}
                  name="isMonthlyClosure"
                  color="primary"
                />
              }
              label="Monthly Closure"
            />
          </FormControl>

          {errorMessage !== '' && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              <Typography variant="inherit" component="p">
                {errorMessage}
              </Typography>
            </Alert>
          )}

          <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
            <Button
              onClick={handleClose}
              size="small"
              style={{ marginRight: 15 }}
            >
              Close
            </Button>

            <Button
              onClick={() => setOpenConfirmGenerateWeeklyClosure(true)}
              variant="contained"
              color="primary"
              size="small"
            >
              Generate
            </Button>
          </div>
        </Box>
      </Drawer>
      <Dialog
        open={openConfirmGenerateWeeklyClosure}
        onClose={() => setOpenConfirmGenerateWeeklyClosure(false)}
      >
        <DialogTitle>Confirm Weekly Closure</DialogTitle>
        <DialogContent>
          <p style={{ fontWeight: 500 }}>
            Are you sure you want to generate this week's closure? <br /> If we
            are in promotions phase, then please make sure that promotions are
            already in place with the IT Team before doing this action.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: 15 }}
            onClick={() => setOpenConfirmGenerateWeeklyClosure(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
