import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import NumberFormatValue from '../../../components/NumberFormat';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CustomStore from "devextreme/data/custom_store";
import { SelectBox } from "devextreme-react";

import { errorMessage$, successMessage$ } from "../../../utils/subjects";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogTransferPriceEdit({
  handleClose,
  handleOpen,
  open,
  callback,
  id,
}) {
  const classes = useStyles();

  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [aliquot, setAliquot] = useState('');
  const [taxPeriod, setTaxPeriod] = useState(null);
  const [transferPriceId] = useState(id);

  useEffect(() => {
    const loadTransferPrice = async () => {
      try {
        const dateOutType = await api.get(`scheduling/transfer-prices/${transferPriceId}`);

        if (dateOutType.data) {
          setOrigin(dateOutType.data.Origin && dateOutType.data.Origin.ID);
          setDestination(dateOutType.data.Destination && dateOutType.data.Destination.ID);
          setAliquot(dateOutType.data.Aliquot);
          setTaxPeriod(dateOutType.data.TaxPeriod.ID);
        }
      } catch (err) {
        errorMessage$.next({open: true, message: 'Error to get tranfer price information'});
      }
    }

    loadTransferPrice();
  }, [transferPriceId]);

  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("scheduling/tax-periods");
        const taxPeriodsWithDisplayValue = [];
        response.data.map((taxPeriod) => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}T - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });

  const [officeSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("master/offices");

        return response.data;
      },
    }),
    paginate: true,
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const data = {
        Origin: origin,
        Destination: destination,
        Aliquot: aliquot,
        TaxPeriod: taxPeriod,
      };

      handleClose();
      await api.put(`scheduling/transfer-prices/${id}`, data);

      successMessage$.next({
        open: true,
        message: "Transfer price updated successfully",
      });
      if (callback) callback();
    } catch (err) {
      console.log(err);
      errorMessage$.next({
        open: true,
        message: "Error on update transfer price",
      });
    }
  }, [aliquot, callback, destination, handleClose, id, origin, taxPeriod]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">Edit transfer price</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={officeSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the origin"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={origin}
            required={true}
            onValueChanged={(e) => {
              setOrigin(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
          <SelectBox
            dataSource={officeSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the destination"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={destination}
            required={true}
            onValueChanged={(e) => {
              setDestination(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 24 }}
          />
          <SelectBox
            dataSource={taxPeriodSource}
            displayExpr="DisplayValue"
            valueExpr="ID"
            placeholder="Select the period"
            searchExpr={['DisplayValue', 'Period', 'Year']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={taxPeriod}
            required={true}
            onValueChanged={(e) => {
              setTaxPeriod(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <TextField
            fullWidth
            label="Aliquot"
            id="aliquot"
            value={aliquot}
            required={true}
            onChange={e => setAliquot(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatValue,
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
