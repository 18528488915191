import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Switch, FormControlLabel } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogEconomicGroupEdit({handleClose, handleOpen, open, id, callback}) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(null);
  const [economicGroupId] = useState(id);

  useEffect(() => {
    const loadClient = async () => {
      try {
        const client = await api.get(`master/client-economic-groups/${economicGroupId}`);
        setName(client.data.Name);
        setIsActive(client.data.IsActive);
  
      } catch (err) {
        errorMessage$.next({open: true, message: 'Error to get client information'});
      }
    }

    loadClient();
  }, [economicGroupId])


  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const economicGroupData = {
        Name: name,
        IsActive: isActive
      };

      handleClose();
      await api.put(`master/client-economic-groups/${economicGroupId}`, economicGroupData);
      
      successMessage$.next({open: true, message: 'Economic group updated successfully'});
      if (callback) callback();

    } catch (err) {
      console.log(err);
      errorMessage$.next({open: true, message: 'Error on update economic group'});
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit {name}</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            style={{marginBottom: 8}}
          />

          <FormControlLabel
            control={<Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} name="client-is-active" color="primary" />}
            label="Active"
            style={{ width: '100%', marginBottom: 8 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}