import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

interface DialogAuxJobTitleProps {
  handleClose(): void;
  handleOpen(): void;
  callback(): void;
  open: boolean;
  professionalJobtitleId: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const DialogAuxJobTitleDelete: React.FC<DialogAuxJobTitleProps> = ({
  handleClose, handleOpen, open, professionalJobtitleId, callback
}) => {
  const classes = useStyles();
  const [professional, setProfessional] = useState('');
  const [jobtitle, setJobtitle] = useState('');

  useEffect(() => {
    const loadProfessionalJobtitle = async () => {
      try {
        const {data} = await api.get(`scheduling/professional-jobtitles/${professionalJobtitleId}`);
        setProfessional(data.Professional.Name);
        setJobtitle(data.JobTitle.Name);
  
      } catch (err) {
        errorMessage$.next({open: true, message: 'Error to get aux jobtitle information'});
      }
    }

    loadProfessionalJobtitle();
  }, [professionalJobtitleId]);


  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      handleClose();
      await api.delete(`scheduling/professional-jobtitles/${professionalJobtitleId}`);
      
      successMessage$.next({open: true, message: 'Transfer price deleted successfully'});
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({open: true, message: 'Error on delete aux jobtitle'});
    }
  }, [callback, handleClose, professionalJobtitleId]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Do you confirm?</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent>
          <p>Do you would to like to delete <strong style={{ fontWeight: 500 }}>"{`${professional} X ${jobtitle}`}"</strong>?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}