import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getWeek, getWeekYear } from '../../utils/IntegrationDates';
import { getYear } from 'date-fns';

import { FiClock, FiCalendar } from 'react-icons/fi';

import GridLoaderNew from '../Allocation/GridLoaderNew';

import {
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

import GridToolbar from '../../components/GridToolbar';
import GridLoader from './GridLoader';
import WeekHeader from './WeekHeader.tsx';
import ProjectLookup from './ProjectLookup';

import DialogSelectYear from './DialogSelectYear';

import api from '../../services/api';

export default function Grid() {
  const getCurrentWeek = useCallback(() => {
    const currentWeekNumber = getWeek(new Date());
    const currentWeekYear = getWeekYear(new Date());

    return { WeekNumber: currentWeekNumber, Year: currentWeekYear };
  }, []);

  const gridRef = useRef();
  const [selectedYear, setSelectedYear] = useState(() => {
    const today = new Date();
    return getYear(today);
  });
  const history = useHistory();
  const { professionalId } = useParams();
  const [currentWeek] = useState(getCurrentWeek().WeekNumber);
  const [currentYear] = useState(getCurrentWeek().Year);
  const [openDialogSelectYear, setOpenDialogSelectYear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allocations, setAllocations] = useState([]);
  const [dateOuts, setDateOuts] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const allocationResponse = useCallback(professionalsAllocations => {
    const rows = [];

    professionalsAllocations.map(professionalAllocation => {
      const row = {};

      row.ID = professionalAllocation.ID;
      row.Project = professionalAllocation.Project;
      professionalAllocation.Allocations.map(allocation => {
        const scheduleWeekId =
          allocation.ProjectScheduleWeek.ScheduleWeek.ID.toString();
        row[scheduleWeekId] = Number(allocation.TotalDays);
      });

      rows.push(row);
    });

    return rows;
  }, []);

  const dateOutResponse = useCallback(dateOuts => {
    const rows = [];

    dateOuts.map(dateOut => {
      const row = {};

      row.ID = `${dateOut.ID}-${dateOut.Professional}`;
      row.Project = dateOut;
      dateOut.Allocations.map(allocation => {
        const scheduleWeekId = allocation.ScheduleWeek.ID.toString();
        row[scheduleWeekId] = Number(allocation.TotalDays);
      });

      rows.push(row);
    });

    return rows;
  }, []);

  const scheduleStore = useMemo(() => {
    return new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: () => {
        return [...allocations, ...dateOuts];
      },
    });
  }, [allocations, dateOuts]);

  const onChangedYear = useCallback(year => {
    setOpenDialogSelectYear(false);
    setSelectedYear(year);
  }, []);

  const focusOnCurrentWeek = useCallback(() => {
    const { WeekNumber: currentWeekNumber, Year: currentWeekYear } =
      getCurrentWeek();
    const currentWeek = weeks.find(
      week =>
        week.WeekNumber === currentWeekNumber && week.Year === currentWeekYear,
    );

    if (currentWeek && gridRef.current) {
      const grid = gridRef.current.instance;
      const cell = grid.getCellElement(0, currentWeek.ID);
      grid.focus(cell);
    }
  }, [getCurrentWeek, weeks]);

  useEffect(() => {
    const today = new Date();
    const year = getYear(today);
    setSelectedYear(year);
  }, [professionalId]);

  const handleResourceCellClick = useCallback(
    e => {
      if (
        e.rowType === 'data' &&
        e.column.dataField === 'Project' &&
        !e.value.DateOutType
      ) {
        const projectId = e.value.ID;
        history.push(`/projects/${projectId}/allocations`);
      }
    },
    [history],
  );

  useEffect(() => {
    setLoading(true);
    setAllocations([]);
    setDateOuts([]);
    setWeeks([]);

    Promise.all([
      api.get(
        `scheduling/professionals/${professionalId}/schedule-weeks?year=${selectedYear}`,
      ),
      api.get(
        `scheduling/professionals/${professionalId}/professionals-allocations?year=${selectedYear}`,
      ),
      api.get(
        `scheduling/professionals/${professionalId}/date-out?year=${selectedYear}`,
      ),
    ]).then(([scheduleWeeks, professionalAllocations, dateOuts]) => {
      const weeks = scheduleWeeks.data.map(item => ({
        ID: item.ID.toString(),
        StartDate: item.WeekStartDate,
        EndDate: item.WeekEndDate,
        WeekNumber: item.WeekNumber,
        Year: item.Year,
      }));

      setDateOuts(dateOutResponse(dateOuts.data));
      setWeeks(weeks);
      setAllocations(allocationResponse(professionalAllocations.data));
      setLoading(false);
    });
  }, [allocationResponse, dateOutResponse, professionalId, selectedYear]);

  useEffect(() => {
    setTimeout(focusOnCurrentWeek, 2000);
  }, [focusOnCurrentWeek, weeks]);

  return (
    <section id="allocation">
      {loading ? (
        <GridLoaderNew style={{ paddingTop: 30 }} />
      ) : (
        <>
          <Tooltip
            title="Go to current week"
            placement="top"
            arrow
            followCursor
          >
            <Button className="current-button" onClick={focusOnCurrentWeek}>
              <FiClock size={16} />
            </Button>
          </Tooltip>
          <Tooltip title="Change year" placement="top" arrow followCursor>
            <Button
              className="change-year-button"
              onClick={() => setOpenDialogSelectYear(true)}
            >
              <FiCalendar size={16} />
            </Button>
          </Tooltip>
          <DataGrid
            ref={gridRef}
            dataSource={scheduleStore}
            showBorders={false}
            remoteOperations={false}
            showColumnLines={true}
            id="professional-grid"
            onCellClick={handleResourceCellClick}
            height="100%"
            allowColumnResizing={false}
            loadPanel={null}
          >
            <Paging enabled={false} />
            <Scrolling columnRenderingMode="infinite" />
            <Editing mode="cell" allowUpdating={false} />

            <Column
              dataField="Project"
              fixed={true}
              cssClass="cursor-pointer"
              width={250}
              cellRender={ProjectLookup}
              caption="Project/Date Out"
            />

            {weeks.map((week, index) => (
              <Column
                alignment="center"
                key={week.ID}
                dataField={week.ID}
                caption={week.caption}
                allowSorting={false}
                headerCellRender={() => (
                  <WeekHeader
                    isToday={
                      currentWeek === week.WeekNumber &&
                      week.Year === currentYear
                        ? true
                        : !week.AllowEdit
                        ? 'disabled-column'
                        : false
                    }
                    week={week}
                    index={index}
                    isEditable={false}
                    cssClass="cursor-none"
                  />
                )}
                width={65}
                cssClass={
                  currentWeek === week.WeekNumber && week.Year === currentYear
                    ? 'highlight-cell'
                    : null
                }
              />
            ))}

            <Column caption="" />

            <Summary recalculateWhileEditing>
              <TotalItem
                column="Project"
                summaryType="count"
                customizeText={data => 'Total allocation:'}
                cssClass="cursor-none totals"
              />
              {weeks.map(week => (
                <TotalItem
                  key={week.ID}
                  column={week.ID}
                  summaryType="sum"
                  customizeText={data => data.value}
                  cssClass="cursor-none totals"
                />
              ))}
            </Summary>
          </DataGrid>
        </>
      )}

      {openDialogSelectYear ? (
        <DialogSelectYear
          open={openDialogSelectYear}
          handleClose={() => setOpenDialogSelectYear(false)}
          handleOpen={() => setOpenDialogSelectYear(true)}
          professionalId={professionalId}
          year={selectedYear}
          onChangedYear={onChangedYear}
        />
      ) : null}
    </section>
  );
}
