import React from 'react';
import FirstLevelControl from '../FirstLevelControl';
import FirstLevelContent from '../FirstLevelContent';
import { useControlContent } from '../../hooks/controlContent';

import './styles.css';

export default function FirstLevel({ children }) {
  const { hasControl, collapse } = useControlContent();

  return (
    <div className="first-level">
      <FirstLevelControl />
      <FirstLevelContent collapse={collapse} hasControl={hasControl}>
        {children}
      </FirstLevelContent>
    </div>
  );
}
