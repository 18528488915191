import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { successMessage$, errorMessage$ } from '../../utils/subjects';
import {
  FiCalendar,
  FiUsers,
  FiSettings,
  FiDollarSign,
  FiCoffee,
  FiBarChart2,
  FiFolder,
  FiInfo,
  FiCheck,
  FiUserPlus,
  FiAlertTriangle,
  FiMessageCircle,
  FiCheckSquare,
  FiUpload,
  FiArrowUpCircle,
  FiCpu,
  FiUserX,
  FiRefreshCcw,
  FiRefreshCw,
  FiLayout,
} from 'react-icons/fi';
import './styles.css';
import { useParams, useHistory } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';
// import { WhisperSpinner } from 'react-spinners-kit';
// import { withStyles } from '@material-ui/core/styles';
// import { BiComment } from 'react-icons/bi';
// import ContentLoader from 'react-content-loader';
// import DataGrid, {
//   Column,
//   Editing,
//   Paging,
//   Scrolling,
//   RangeRule,
// } from 'devextreme-react/data-grid';
import { Tooltip } from '@material-ui/core';
import AddProfessional from './Tabs/AddProfessional';
import ProjectInfo from './Tabs/ProjectInfo';
import api from '../../services/api';
import { useControlContent } from '../../hooks/controlContent';

import { getWeek, getWeekYear } from '../../utils/IntegrationDates';
import DialogCreatePortal from '../../pages/Allocation/DialogCreatePortal';
import DialogCreateResource from '../../pages/Allocation/DialogCreateResource';
import ProfessionaLookup from '../ProfessionalLookup/index.tsx';
import WeekHeader from '../../pages/Allocation/WeekHeader.tsx';
import DialogResource from '../../pages/Allocation/DialogResource';
import DialogWeeks from '../../pages/Allocation/DialogWeeks';
import GridToolbar from '../GridToolbar';
import DialogProjectStatus from '../../pages/Allocation/DialogProjectStatus';
import DialogWeekClosures from '../../pages/Allocation/DialogWeekClosures';
import DialogConflicts from '../../pages/Allocation/DialogConflicts';
import DialogDelete from '../../pages/Allocation/DialogDelete';
import DialogCreateSimulation from '../../pages/Allocation/DialogCreateSimulation';
import DialogSuggestions from '../../pages/Allocation/DialogSuggestions.tsx';
import DialogPortal from '../../pages/Allocation/DialogPortal.tsx';
import PortalLogo from '../../assets/images/portal-logo.svg';
import { useAuth } from '../../hooks/auth';
import GridLoader from '../../pages/Allocation/GridLoader';
import schedulingGroups from '../../config/schedulingGroups';
import DialogLoadConflict from '../../pages/Allocation/DialogLoadConflict';
import DialogIncreaseWeeks from '../../pages/SimulationAllocation/DialogIncreaseWeeks';

export default function Tools({
  onEditProject,
  projectStatus: _projectStatus,
  onUpdatedMargin,
  onUpdatingMargin,
  projectComanagement: _projectComanagement,
  projectSupportComanagement: _projectSupportComanagement,
  officeId: _officeId,
  portalId,
  showDeallocated,
  setShowDeallocated,
  onReload,
  type, //if it's simulation, allocation or professionaL
}) {
  const containerRef = useRef(null);
  const [openProjectInfo, setOpenProjectInfo] = useState(false);
  const initialMargin = 53;
  const marginTop = 48;
  const getCurrentWeek = useCallback(() => {
    const currentWeekNumber = getWeek(new Date());
    const currentWeekYear = getWeekYear(new Date());

    return { WeekNumber: currentWeekNumber, Year: currentWeekYear };
  }, []);

  const gridRef = useRef();
  const history = useHistory();
  const { projectId } = useParams();

  const { user, hasScope } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.CoManager,
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
    schedulingGroups.TechLab,
  ]);

  const [currentWeek] = useState(getCurrentWeek().WeekNumber);
  const [closureId, setClosureId] = useState('');
  const [disabledRows, setDisabledRows] = useState([]);
  const [allowEdit, setAllowEdit] = useState(true);
  const [allowChangeStatus, setAllowChangeStatus] = useState(() =>
    userScopes.some(scope => allowEditScopes.includes(scope)),
  );
  const [currentYear] = useState(getCurrentWeek().Year);
  const [openCreate, setOpenCreate] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openDialogCreatePortal, setOpenDialogCreatePortal] = useState(false);
  const [openChangeClosure, setOpenChangeClosure] = useState(false);
  const [openDialogConflict, setOpenDialogConflict] = useState(false);
  const [openDialogCreateSimulation, setOpenCreateSimulation] = useState(false);
  const [openDialogLoadConflict, setOpenDialogLoadConflict] = useState(false);
  const [openResource, setOpenResource] = useState(false);
  const [openDeleteResource, setOpenDeleteResource] = useState(false);
  const [openDialogWeeks, setOpenDialogWeeks] = useState(false);
  const [openDialogSuggestion, setOpenDialogSuggestion] = useState(false);
  const [openDialogPortal, setOpenDialogPortal] = useState(false);
  const [openDialogIncreaseWeeks, setOpenDialogIncreaseWeeks] = useState(false);
  const [professionalId, setProfessionalId] = useState(null);
  const [jobTitleId, setJobTitleId] = useState(null);
  const [allowDelete, setAllowDelete] = useState(true);
  const [professionalAllocationId, setProfessionalAllocationId] =
    useState(null);
  const [filter, setFilter] = useState('');
  const [totalConflicts, setTotalConflicts] = useState(0);
  const [conflictCells, setConflictCells] = useState([]);
  const [modifiedCells, setModifiedCells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [weeks, setWeeks] = useState([]);
  const [allocations, setAllocations] = useState([]);
  // const [visibleProfessionals, setVisibleProfessionals] = useState([]);
  const [allProfessionals, setAllProfessionals] = useState([]);

  // const handleNavigationProjectPage = useCallback(() => {
  //   history.push(`/projects/${projectId}`);
  // }, [history, projectId]);

  const persistAllocation = useCallback(
    async ({ professionalAllocationId: key, allocations }) => {
      try {
        if (onUpdatingMargin) onUpdatingMargin();

        await api.patch(
          `${type}/projects/${projectId}/professionals-allocations/${key}/allocations`,
          allocations,
        );

        if (onUpdatedMargin) onUpdatedMargin();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: err.response && err.response.data.message,
        });
      }
    },
    [onUpdatedMargin, onUpdatingMargin, projectId],
  );

  // Convert allocation json retrieve by api into a valid json to put in the grid
  const allocationResponse = useCallback(professionalsAllocations => {
    const rows = [];
    const modifiedCells = [];

    professionalsAllocations.map(professionalAllocation => {
      if (!professionalAllocation.AllowEdit) {
        setDisabledRows(state => [...state, professionalAllocation.ID]);
      }

      const row = {};

      row.ID = professionalAllocation.ID;
      row.Professional = professionalAllocation.Professional;
      row.JobTitle = professionalAllocation.Position?.Name;
      row.AllowDelete = professionalAllocation.AllowDelete;
      row.AllowEdit = professionalAllocation.AllowEdit;
      row.IsTBD = professionalAllocation.IsTBD;
      row.HasTutor = professionalAllocation.HasTutor;
      row.Allocations = [];

      if (type === 'scheduling/simulation') {
        professionalAllocation.Allocations.map(allocation => {
          const projectScheduleWeekId =
            typeof allocation.ProjectScheduleWeek === 'object'
              ? allocation.ProjectScheduleWeek.ID.toString()
              : allocation.ProjectScheduleWeek.toString();
          row[projectScheduleWeekId] = allocation.TotalDays;

          row.Allocations.push({
            ProjectScheduleWeekID: Number(projectScheduleWeekId),
            TotalDays: allocation.TotalDays,
          });

          if (allocation.IsModified) {
            modifiedCells.push({
              rowKey: professionalAllocation.ID,
              columnKey: projectScheduleWeekId,
              previousTotalDays: allocation.PreviousTotalDays,
            });
          }
        });
      }
      if (type === 'simulation') {
        professionalAllocation.Allocations.map(allocation => {
          const projectScheduleWeekId =
            typeof allocation.SimulationProjectScheduleWeek === 'object'
              ? allocation.SimulationProjectScheduleWeek.ID.toString()
              : allocation.SimulationProjectScheduleWeek.toString();
          row[projectScheduleWeekId] = allocation.TotalDays;

          row.Allocations.push({
            SimulationProjectScheduleWeekID: Number(projectScheduleWeekId),
            TotalDays: allocation.TotalDays,
          });

          if (allocation.IsModified) {
            modifiedCells.push({
              rowKey: professionalAllocation.ID,
              columnKey: projectScheduleWeekId,
              previousTotalDays: allocation.PreviousTotalDays,
            });
          }
        });
      }
      rows.push(row);
    });

    setModifiedCells(modifiedCells);

    return rows;
  }, []);

  // const handleEditAllocation = useCallback(
  //   async (key, data) => {
  //     const allocations = Object.keys(data).map(projectScheduleWeek => ({
  //       ProjectScheduleWeek: Number(projectScheduleWeek),
  //       TotalDays: data[projectScheduleWeek],
  //     }));
  //     await persistAllocation({
  //       allocations,
  //       professionalAllocationId: key,
  //     });
  //   },
  //   [persistAllocation],
  // );

  // use effect para filtrar profissionais alocados e nao alocados
  useEffect(() => {
    if (allProfessionals && allProfessionals.length > 0) {
      const filteredAllocations = allProfessionals
        .filter(
          professional =>
            professional.AllowDelete ||
            professional.Allocations.some(
              allocation => allocation.TotalDays !== 0,
            ),
        )
        .filter(professional => professional.AllowEdit);

      setAllocations(filteredAllocations);
    }
  }, [allProfessionals]);

  const handleEditAllocation = useCallback(
    async (key, data) => {
      const newAllocations = Object.keys(data).map(projectScheduleWeek => ({
        ProjectScheduleWeek: Number(projectScheduleWeek),
        TotalDays: data[projectScheduleWeek],
      }));

      setAllProfessionals(prevAllocations => {
        const updatedAllocations = prevAllocations.map(allocation => {
          if (allocation.ID === key) {
            newAllocations.map(newAllocation => {
              allocation[newAllocation.ProjectScheduleWeek] =
                newAllocation.TotalDays;

              const index = allocation.Allocations.findIndex(
                x =>
                  x.ProjectScheduleWeekID === newAllocation.ProjectScheduleWeek,
              );
              if (allocation.Allocations[index])
                allocation.Allocations[index].TotalDays =
                  newAllocation.TotalDays;
              else {
                allocation.Allocations.push({
                  ProjectScheduleWeekID: newAllocation.ProjectScheduleWeek,
                  TotalDays: newAllocation.TotalDays,
                });
              }
            });
          }

          return allocation;
        });
        return updatedAllocations;
      });

      try {
        await persistAllocation({
          allocations: newAllocations,
          professionalAllocationId: key,
        });
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: err.response && err.response.data.message,
        });
      }
    },
    [persistAllocation],
  );

  const scheduleStore = useMemo(() => {
    return new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: () => {
        return allocations;
      },
      update: (key, data) => {
        scheduleStore.push([
          {
            type: 'update',
            key,
            data,
          },
        ]);
        handleEditAllocation(key, data);
      },
      insert: data => {
        console.log('insert: ', data);
      },
      byKey: (key, data) => {
        console.log(key, data);
      },
    });
  }, [allocations, handleEditAllocation]);

  const handleDeleteResource = useCallback(
    key => {
      setOpenResource(false);

      setConflictCells(prevState =>
        prevState.filter(conflictCell => {
          return Number(conflictCell.rowKey) !== Number(key);
        }),
      );

      scheduleStore.push([
        {
          type: 'remove',
          key,
        },
      ]);

      if (onUpdatedMargin) onUpdatedMargin();
    },
    [onUpdatedMargin, scheduleStore],
  );

  // const handleResourceCellClick = useCallback(
  //   e => {
  //     if (
  //       e.rowType === 'data' &&
  //       e.column.dataField === 'Professional' &&
  //       closureId === '' &&
  //       e.data.IsTBD
  //     ) {
  //       setProfessionalAllocationId(e.key);
  //       setOpenDeleteResource(true);
  //       return;
  //     }
  //     if (
  //       e.rowType === 'data' &&
  //       e.column.dataField === 'Professional' &&
  //       closureId === ''
  //     ) {
  //       setJobTitleId(e.data.JobTitle);
  //       setAllowDelete(e.data.AllowDelete);
  //       setProfessionalId(e.data.Professional.ID);
  //       setProfessionalAllocationId(e.key);
  //       setOpenResource(true);
  //     }
  //   },
  //   [closureId],
  // );

  // Refresh the closure filter
  useEffect(() => {
    setClosureId('');
    setDisabledRows([]);
  }, [projectId]);

  const loadAllocation = useCallback(async () => {
    setLoading(true);
    if (onUpdatingMargin) onUpdatingMargin();
    setAllocations([]);

    setConflictCells([]);
    let filter = '';
    if (closureId !== '') {
      filter = `?closureId=${closureId}`;
    }

    let promises = [
      api.get(`${type}/projects/${projectId}/schedule-weeks`),
      api.get(
        `${type}/projects/${projectId}/professionals-allocations${
          type === 'scheduling' ? filter : ''
        }`,
      ),
    ];

    if (type === 'scheduling') {
      promises.push(api.get(`${type}/projects/${projectId}/conflicts`));
    }

    Promise.all(promises)
      .then(([weeks, allocations, conflicts]) => {
        const items = weeks.data.map(item => ({
          ID: item.ID.toString(),
          StartDate: item.ScheduleWeek.WeekStartDate,
          EndDate: item.ScheduleWeek.WeekEndDate,
          WeekNumber: item.ScheduleWeek.WeekNumber,
          Year: item.ScheduleWeek.Year,
          AllowEdit: item.AllowEdit,
        }));

        setWeeks(items);

        const allProfessionals = allocations.data;
        setAllProfessionals(allocationResponse(allProfessionals));

        if (type === 'scheduling') {
          const conflictCells = [];
          conflicts.data.map(conflict => {
            conflictCells.push({
              rowKey: conflict.ProfessionalAllocation.ID,
              columnKey: conflict.ProjectScheduleWeek.ID,
            });
          });
          if (closureId === '') {
            setConflictCells(conflictCells);
          }
        }

        if (onUpdatedMargin) onUpdatedMargin(closureId);
        setShowDeallocated(false);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, [
    allocationResponse,
    closureId,
    onUpdatedMargin,
    onUpdatingMargin,
    projectId,
    type,
    filter,
  ]);

  // Load week on load components
  useEffect(() => {
    loadAllocation();
  }, [loadAllocation]);

  const handleAddResource = useCallback(
    async data => {
      try {
        if (data && data.length > 0) {
          await Promise.all(
            data.map(async professionalAllocations => {
              await persistAllocation({
                allocations: professionalAllocations.Allocations,
                professionalAllocationId: professionalAllocations.ID,
              });
            }),
          );

          onReload();
        }
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: err.response && err.response.data.message,
        });
      }
    },
    [allocationResponse, allocations, persistAllocation, loadAllocation],
  );

  useEffect(() => {
    setTotalConflicts(conflictCells.length);
  }, [conflictCells]);

  const focusOnCurrentWeek = useCallback(() => {
    const { WeekNumber: currentWeekNumber, Year: currentWeekYear } =
      getCurrentWeek();
    const currentWeek = weeks.find(
      week =>
        week.WeekNumber === currentWeekNumber && week.Year === currentWeekYear,
    );

    if (currentWeek && gridRef.current) {
      const grid = gridRef.current.instance;
      const cell = grid.getCellElement(0, currentWeek.ID);
      grid.focus(cell);
    }
  }, [getCurrentWeek, weeks]);

  useEffect(() => {
    setTimeout(focusOnCurrentWeek, 1000);
  }, [focusOnCurrentWeek, weeks]);

  useEffect(() => {
    setAllowEdit(
      _projectStatus !== 'Approved' &&
        _projectStatus !== 'Canceled' &&
        _projectStatus !== 'Closed',
    );
  }, [_projectStatus]);

  // function createVisibleProfessionalStore(
  //   visibleProfessionals,
  //   handleEditAllocation,
  // ) {
  //   return new CustomStore({
  //     key: 'ID',
  //     loadMode: 'raw',
  //     load: () => {
  //       return visibleProfessionals;
  //     },
  //     update: (key, data) => {
  //       scheduleStore.push([
  //         {
  //           type: 'update',
  //           key,
  //           data,
  //         },
  //       ]);
  //       handleEditAllocation(key, data);
  //     },
  //     insert: data => {
  //       console.log('insert: ', data);
  //     },
  //     byKey: (key, data) => {
  //       console.log(key, data);
  //     },
  //   });
  // }

  const handleShowDeallocated = () => {
    setShowDeallocated(prevState => !prevState);
    if (!showDeallocated) {
      successMessage$.next({
        open: true,
        message: 'Deallocated professionals are now displayed',
      });
    }
  };

  // const visibleProfessionalStore = useMemo(() => {
  //   return createVisibleProfessionalStore(
  //     visibleProfessionals,
  //     handleEditAllocation,
  //   );
  // }, [visibleProfessionals, handleEditAllocation]);

  const handleOnLoadedConflicts = useCallback(async () => {
    // reload allocation to update de modified cells
    const [allocations, conflicts] = await Promise.all([
      api.get(`${type}/projects/${projectId}/professionals-allocations`),
      api.get(`${type}/projects/${projectId}/conflicts`),
    ]);

    const conflictsCells = conflicts.data.map(conflict => ({
      rowKey: conflict.ProfessionalAllocation.ID,
      columnKey: conflict.ProjectScheduleWeek.ID,
    }));

    setAllocations(allocationResponse(allocations.data));

    setOpenDialogLoadConflict(false);
    setConflictCells(conflictsCells);
  }, [allocationResponse, projectId]);

  const handleLoadConflicts = useCallback(async () => {
    setOpenDialogLoadConflict(true);
  }, []);

  const handleOpenCreatePortal = useCallback(async () => {
    setOpenDialogCreatePortal(true);
  }, []);

  const handleApplySuggestions = useCallback(() => {
    setOpenDialogSuggestion(false);
    loadAllocation();
    setOpenDialogSuggestion(true);
  }, [loadAllocation]);

  // const cellRender = useCallback(
  //   e => {
  //     if (e.rowType === 'data') {
  //       if (conflictCells.length > 0 || modifiedCells.length > 0) {
  //         const conflictCellIndex = conflictCells.find(
  //           conflictCell =>
  //             conflictCell.rowKey == e.key &&
  //             conflictCell.columnKey == e.column.caption,
  //         );
  //         const modifiedCellIndex = modifiedCells.find(
  //           modifiedCell =>
  //             modifiedCell.rowKey == e.key &&
  //             modifiedCell.columnKey == e.column.caption,
  //         );

  //         if (conflictCellIndex && modifiedCellIndex) {
  //           return (
  //             <Tooltip
  //               title={`Previous value: ${modifiedCellIndex.previousTotalDays} and conflicted`}
  //               placement="top-start"
  //             >
  //               <div className="modified-cell">
  //                 <span className="cell-badge conflict-badge" />
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }

  //         if (conflictCellIndex) {
  //           return (
  //             <Tooltip title="Conflicted" placement="top-start">
  //               <div>
  //                 <span className="cell-badge conflict-badge" />
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }

  //         if (modifiedCellIndex) {
  //           return (
  //             <Tooltip
  //               title={`Previous value: ${modifiedCellIndex.previousTotalDays}`}
  //               placement="top-start"
  //             >
  //               <div className="modified-cell">
  //                 <span>{e.value}</span>
  //               </div>
  //             </Tooltip>
  //           );
  //         }
  //       }
  //     }

  //     return e.value;
  //   },
  //   [conflictCells, modifiedCells],
  // );

  const onSubmittedWeeks = useCallback(
    weeks => {
      loadAllocation();
    },
    [loadAllocation],
  );

  const onStatusChanged = useCallback(
    async status => {
      setOpenChangeStatus(false);

      // reload allocation to update de modified cells
      const allocations = await api.get(
        `${type}/projects/${projectId}/professionals-allocations`,
      );

      setAllocations(allocationResponse(allocations.data));

      setAllowEdit(
        status !== 'Approved' && status !== 'Canceled' && status !== 'Closed',
      );
      onEditProject({
        ID: Number(projectId),
        ProjectStatus: status,
      });
    },
    [allocationResponse, onEditProject, projectId],
  );

  const onCreatedSimulation = useCallback(
    simulationProjectId => {
      history.push(`/simulation/${simulationProjectId}/allocations`);
    },
    [history],
  );
  // Check permissions (scopes)
  useEffect(() => {
    const found = userScopes.some(scope => allowEditScopes.includes(scope));

    if (!found) {
      setAllowChangeStatus(false);
      setAllowEdit(false);
    } else {
      const isCoManager = userScopes.includes(schedulingGroups.CoManager);
      const isTechLab = userScopes.includes(schedulingGroups.TechLab);

      if (isCoManager) {
        const isEditableStatus =
          _projectStatus !== 'Approved' &&
          _projectStatus !== 'Canceled' &&
          _projectStatus !== 'Closed' &&
          _projectStatus !== 'Waiting validation';
        console.log(
          isEditableStatus,
          user,
          _projectComanagement,
          _projectSupportComanagement,
          _officeId,
        );
        setAllowChangeStatus(
          isEditableStatus &&
            (user.comanagementsIds.includes(_projectComanagement) ||
              (_projectSupportComanagement &&
                user.comanagementsIds.includes(
                  Number(_projectSupportComanagement),
                )) ||
              user.officeId.includes(_officeId)),
        );
        setAllowEdit(
          isEditableStatus &&
            (user.comanagementsIds.includes(_projectComanagement) ||
              (_projectSupportComanagement &&
                user.comanagementsIds.includes(
                  Number(_projectSupportComanagement),
                )) ||
              user.officeId.includes(_officeId)),
        );
      }

      if (isTechLab) {
        setAllowChangeStatus(false);
      }
    }
  }, [
    _projectComanagement,
    _projectStatus,
    _projectSupportComanagement,
    _officeId,
    allowEdit,
    allowEditScopes,
    user,
    userScopes,
  ]);

  const statesToSetFalse = useMemo(
    () => [
      setOpenDialogConflict,
      setOpenCreate,
      setOpenDialogPortal,
      setOpenDialogLoadConflict,
      setOpenDialogSuggestion,
      setOpenDialogWeeks,
      setOpenProjectInfo,
      setOpenChangeClosure,
      setOpenChangeStatus,
      setOpenDialogIncreaseWeeks,
    ],
    [
      setOpenDialogConflict,
      setOpenCreate,
      setOpenDialogPortal,
      setOpenDialogLoadConflict,
      setOpenDialogSuggestion,
      setOpenDialogWeeks,
      setOpenProjectInfo,
      setOpenChangeClosure,
      setOpenChangeStatus,
      setOpenDialogIncreaseWeeks,
    ],
  );

  const handleCloseMenus = invertState => {
    statesToSetFalse.forEach(state => {
      if (state === invertState) {
        return;
      }
      state(false);
    });
    invertState(prevState => !prevState);
  };

  const onCreatedWeeks = useCallback(data => {
    const items = data.map(item => ({
      ID: item.ID.toString(),
      StartDate: item.ScheduleWeek.WeekStartDate,
      EndDate: item.ScheduleWeek.WeekEndDate,
      WeekNumber: item.ScheduleWeek.WeekNumber,
      Year: item.ScheduleWeek.Year,
      AllowEdit: item.AllowEdit,
    }));

    setWeeks(state => [...state, ...items]);
  }, []);

  useEffect(() => {
    const handleClickOutside = e => {
      if (!containerRef.current.contains(e.target)) {
        statesToSetFalse.forEach(state => state(false));
      }
    };

    const wrapperDiv = document.querySelector('.first-level-content-wrapper');
    wrapperDiv.style.marginRight = '58px';
    wrapperDiv.addEventListener('click', handleClickOutside);

    return () => {
      wrapperDiv.removeEventListener('click', handleClickOutside);
    };
  }, [containerRef, statesToSetFalse]);

  const tabWrappers = [
    ...(type === 'scheduling'
      ? [
          {
            condition: openProjectInfo,
            content: <ProjectInfo projectId={projectId} />,
          },
        ]
      : []),
    {
      condition: openCreate,
      content: (
        <DialogCreateResource
          weeks={weeks}
          open={openCreate}
          handleClose={() => setOpenCreate(false)}
          handleOpen={() => setOpenCreate(true)}
          onAddProfessionalAllocation={handleAddResource}
          projectId={projectId}
          onReload={onReload}
          type={type}
        />
      ),
    },
    ...(type === 'scheduling/simulation'
      ? [
          {
            condition: openDialogIncreaseWeeks,
            content: (
              <DialogIncreaseWeeks
                projectId={projectId}
                open={openDialogIncreaseWeeks}
                handleOpen={() => setOpenDialogIncreaseWeeks(true)}
                handleClose={() => setOpenDialogIncreaseWeeks(false)}
                onCreatedWeeks={onCreatedWeeks}
                weeks={weeks}
                onReload={onReload}
              />
            ),
          },
        ]
      : []),
    ...(type === 'scheduling'
      ? [
          {
            condition: openDialogWeeks,
            content: (
              <DialogWeeks
                projectId={projectId}
                open={openDialogWeeks}
                handleOpen={() => setOpenDialogWeeks(true)}
                handleClose={() => setOpenDialogWeeks(false)}
                onSubmitted={onSubmittedWeeks}
                weeks={weeks}
                onReload={onReload}
                type={type}
              />
            ),
          },
          {
            condition: openDialogConflict,
            content: (
              <DialogConflicts
                open={openDialogConflict}
                handleClose={() => setOpenDialogConflict(false)}
                handleOpen={() => setOpenDialogConflict(true)}
                projectId={projectId}
              />
            ),
          },
          {
            condition: openDialogSuggestion,
            content: (
              <DialogSuggestions
                projectId={projectId}
                open={openDialogSuggestion}
                handleOpen={() => setOpenDialogSuggestion(true)}
                handleClose={() => setOpenDialogSuggestion(false)}
                closureId={closureId}
                onSubmitted={handleApplySuggestions}
              />
            ),
          },
          {
            condition: openChangeClosure,
            content: (
              <DialogWeekClosures
                open={openChangeClosure}
                projectId={projectId}
                handleClose={() => setOpenChangeClosure(false)}
                handleOpen={() => setOpenChangeClosure(true)}
                onChangedClosure={closureId => setClosureId(closureId)}
                closureId={closureId}
              />
            ),
          },
          {
            condition: openDialogPortal,
            content: (
              <DialogPortal
                projectId={projectId}
                open={openDialogPortal}
                handleClose={() => setOpenDialogPortal(false)}
                portalId={portalId}
                onSubmitted={portalId => {
                  onEditProject({ IDPortal: portalId })
                  setOpenDialogPortal(false)
                 }}
              />
            ),
          },
          {
            condition: openChangeStatus,
            content: (
              <DialogProjectStatus
                open={openChangeStatus}
                projectId={projectId}
                handleClose={() => setOpenChangeStatus(false)}
                handleOpen={() => setOpenChangeStatus(true)}
                onStatusChanged={onStatusChanged}
                onLoadedConflicts={handleOnLoadedConflicts}
                closureId={closureId}
                onStartDateChanged={startDate =>
                  onEditProject({
                    ID: Number(projectId),
                    StartDate: startDate,
                  })
                }
              />
            ),
          },
        ]
      : []),
  ];

  const tabWrappersRendered = tabWrappers.map((wrapper, index) => {
    const { condition, content } = wrapper;
    return condition ? (
      <div
        key={index}
        className="tabwrapper"
        style={{
          width: condition ? '' : '0',
          marginTop: `${initialMargin + marginTop * index}px`,
        }}
      >
        {content}
      </div>
    ) : null;
  });

  function button(bgColor, title, openState, setOpenState, icon) {
    return (
      <Tooltip title={title} placement="left">
        <div
          className="tools-button"
          onClick={() => handleCloseMenus(setOpenState)}
          style={{ width: openState ? '630px' : '', backgroundColor: bgColor }}
        >
          {openState && title} {icon}
        </div>
      </Tooltip>
    );
  }

  function buttonFixed(bgColor, title, openState, setOpenState, icon) {
    return (
      <Tooltip title={title} placement="left">
        <div
          className="tools-button"
          style={{ backgroundColor: bgColor }}
          onClick={() => handleCloseMenus(setOpenState)}
        >
          {openState} {icon}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div className="toolswrapper" ref={containerRef}>
        {tabWrappersRendered}
        <div className="tools">
          <div>
            {type === 'scheduling' && (
              <>
                {button(
                  'white',
                  'Project Info',
                  openProjectInfo,
                  setOpenProjectInfo,
                  <FiInfo size={20} />,
                )}
              </>
            )}
            {button(
              'white',
              'Add Professional',
              openCreate,
              setOpenCreate,
              <FiUserPlus size={20} />,
            )}
            {type === 'scheduling/simulation' && (
              <>
                {button(
                  'white',
                  'Add Week',
                  openDialogIncreaseWeeks,
                  setOpenDialogIncreaseWeeks,
                  <FiCalendar size={20} />,
                )}
              </>
            )}
            {type === 'scheduling' && (
              <>
                {button(
                  'white',
                  'Add Week',
                  openDialogWeeks,
                  setOpenDialogWeeks,
                  <FiCalendar size={20} />,
                )}
                {button(
                  'white',
                  'Conflicts',
                  openDialogConflict,
                  setOpenDialogConflict,
                  <FiAlertTriangle size={20} />,
                )}
                {button(
                  'white',
                  'One Page Allocations',
                  openDialogSuggestion,
                  setOpenDialogSuggestion,
                  <FiMessageCircle size={20} />,
                )}
                {button(
                  'white',
                  'Change Week Closure',
                  openChangeClosure,
                  setOpenChangeClosure,
                  <FiCheckSquare size={20} />,
                )}
                {button(
                  'white',
                  'Manage Portal',
                  openDialogPortal,
                  setOpenDialogPortal,
                  <img
                    src={PortalLogo}
                    style={{ maxWidth: 20, margin: '5px 0' }}
                    alt="Portal logo"
                  />,
                )}
                {button(
                  openChangeStatus ? 'white' : '#8b0304',
                  'Change Status',
                  openChangeStatus,
                  setOpenChangeStatus,
                  <FiArrowUpCircle
                    size={20}
                    color={openChangeStatus ? '#8b0304' : 'white'}
                  />,
                )}
                <div className="separator" />
                {buttonFixed(
                  'white',
                  'Create Simulation',
                  openDialogCreateSimulation,
                  setOpenCreateSimulation,
                  <FiCpu size={20} />,
                )}
                {buttonFixed(
                  'white',
                  `${!showDeallocated ? 'Show' : 'Hide'} Deallocated`,
                  showDeallocated,
                  handleShowDeallocated,
                  <FiUserX
                    size={20}
                    color={showDeallocated ? '#8b0304' : ''}
                  />,
                )}
                {buttonFixed(
                  'white',
                  'Load Conflicts',
                  null,
                  handleLoadConflicts,
                  <FiRefreshCw size={20} />,
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {openDialogCreateSimulation ? (
        <DialogCreateSimulation
          open={openDialogCreateSimulation}
          projectId={projectId}
          handleClose={() => setOpenCreateSimulation(false)}
          handleOpen={() => setOpenCreateSimulation(true)}
          onCreated={onCreatedSimulation}
        />
      ) : null}

      {openDialogLoadConflict ? (
        <DialogLoadConflict
          open={openDialogLoadConflict}
          projectId={projectId}
          handleClose={() => setOpenDialogLoadConflict(false)}
          handleOpen={() => setOpenDialogLoadConflict(true)}
          onLoadedConflicts={handleOnLoadedConflicts}
          onReload={onReload}
        />
      ) : null}

      {openResource ? (
        <DialogResource
          open={openResource}
          handleClose={() => setOpenResource(false)}
          handleOpen={() => setOpenResource(true)}
          professionalId={professionalId}
          jobTitleId={jobTitleId}
          projectId={projectId}
          professionalAllocationId={professionalAllocationId}
          onResourceDeleted={handleDeleteResource}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
          type={type}
        />
      ) : null}

      {openDeleteResource ? (
        <DialogDelete
          projectId={projectId}
          open={openDeleteResource}
          handleOpen={() => setOpenDeleteResource(true)}
          handleClose={() => setOpenDeleteResource(false)}
          id={professionalAllocationId}
          callback={handleDeleteResource}
          type={type}
        />
      ) : null}
    </>
  );
}
