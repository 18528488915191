import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Paper } from '@material-ui/core';
import { FiActivity, FiStar, FiZap } from 'react-icons/fi';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    fontSize: 14,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));


export default function DialogFeature({ handleClose, handleOpen, open }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">Devlog news</DialogTitle>
      <DialogContent>
        <Timeline align="alternate">
        <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                26 NOV 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Project suggestions from Portal</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Create and open Portal from Scheduling</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- New report "Project status observations"</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Remove weeks from project</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- New conflict method</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                8 JUL 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Allow a support co-management to manage a project of another co-management</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                8 JUL 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: 'rgb(158, 158, 158)' }}>
                <FiZap />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  Bug fix
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Show professional practice on conflicts page</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Adjustments on allocation reports</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                19 MAY 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- New report "Unallocated professionals"</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- New column on Allocation home "Weeks left"</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                15 APR 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Geographic and City on create project</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                09 FEV 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Add values to the project (e.g. extensions)</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Add weeks at start of the project</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Consolidated report</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                29 JAN 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Hyperlink on professional page</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- New column on Allocation home (Has modified)</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- (For admins) On finance area, new button to Weekly Closure</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                29 JAN 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: 'rgb(158, 158, 158)' }}>
                <FiZap />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  Bug fix
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Adjustments on allocation reports</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                14 JAN 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: '#8b0304' }}>
                <FiStar />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  New Feature
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Create simulation from project</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                14 JAN 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: 'rgb(158, 158, 158)' }}>
                <FiZap />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  Bug fix
                </Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Adjustments on reports</Typography>
                <Typography component="p" style={{ fontSize: 14 }}>- Adjustments on Scheduling closure task</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                04 JAN 2021
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot style={{ backgroundColor: 'rgb(162, 207, 110)' }}>
                <FiActivity />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1" style={{ fontSize: 14 }}>
                  Launch of new Scheduling
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}