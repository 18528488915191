import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../config/msAuth';
import MSAuth from '../../services/ms-auth';
import {
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  Container,
  Fab,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  FiLogOut,
  FiHelpCircle,
  FiGrid,
  FiCalendar,
  FiDollarSign,
  FiChevronDown,
} from 'react-icons/fi';

import { useControlContent } from '../../hooks/controlContent';
import DialogAuditSellValueType from '../ProjectHome/DialogAuditSellValueType';

import api from '../../services/api';

import './styles.css';
import PieChart, { Label, Connector } from 'devextreme-react/pie-chart';

import {
  Chart,
  CommonSeriesSettings,
  Series,
  Export,
  Tooltip,
} from 'devextreme-react/chart';

import DialogFeature from './DialogFeature';
// import {
//   Chart,
//   CommonSeriesSettings,
//   SeriesTemplate,
//   Format,
// } from 'devextreme-react/chart';

import { useAuth } from '../../hooks/auth';
import schedulingGroups from '../../config/schedulingGroups';

export default function Home() {
  const { hasScope } = useAuth();
  const [allowValueAdjustments] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
  ]);
  const history = useHistory();
  const { setHasControl } = useControlContent();
  const allocationRef = useRef(null);
  const [openDialogFeature, setOpenDialogFeature] = useState(false);
  const [userName, setUserName] = useState('');
  const [activeProjects, setActiveProjects] = useState([]);
  const [endingProjects, setEndingProjects] = useState([]);
  const [officesAllocations, setOfficesAllocations] = useState([]);
  const [aboveMargin, setAboveMargin] = useState([]);
  const [belowMargin, setBelowMargin] = useState([]);
  const [hasValueAdjustment, setHasValueAdjustment] = useState(false);
  const customPalette = ['#8b0304'];
  const [openAuditSellValueType, setOpenAuditSellValueType] = useState(false);
  const [loading, setLoading] = useState(true);

  const chartSize = {
    width: 500,
    height: 200,
  };

  const tooltip = {
    enabled: true,
    format: 'fixedPoint',
    font: { size: 12, family: 'Roboto Condensed' },
  };

  const series = {
    type: 'bar',
    argumentField: 'comanagement',
    valueField: 'total',
    color: '#8b0304',
  };

  const font = {
    font: {
      size: 16,
      family: 'Roboto Condensed',
      color: '#000',
      weight: 'bold',
    },
  };

  useEffect(() => {
    setHasControl(false);

    setUserName(localStorage.getItem('userName'));

    Promise.all([
      api.get('session/dashboard'),
      api.get('master/comanagements', {
        params: {
          IsActive: true,
        },
      }),
    ]).then(([dashboard, comanagements]) => {
      // Active projects
      const activeProjects = dashboard.data.ActiveProjects;
      const activeProjectsData = {};
      const activeProjectsDataName = [];
      comanagements.data.map(comanagement => {
        activeProjectsData[comanagement.ID] = 0;
      });

      activeProjects.map(item => {
        activeProjectsData[item.Comanagement]++;
      });

      comanagements.data.map(comanagement => {
        if (activeProjectsData[comanagement.ID] > 0) {
          activeProjectsDataName.push({
            comanagement: comanagement.Abbreviation,
            total: activeProjectsData[comanagement.ID],
          });
        }
      });
      setActiveProjects(activeProjectsDataName);

      // Ending projects
      const endingProjects = dashboard.data.EndingProjects;
      const endingProjectsData = {};
      const endingProjectsDataName = [];
      comanagements.data.map(comanagement => {
        endingProjectsData[comanagement.ID] = 0;
      });

      endingProjects.map(item => {
        endingProjectsData[item.Comanagement]++;
      });

      comanagements.data.map(comanagement => {
        if (endingProjectsData[comanagement.ID] > 0) {
          endingProjectsDataName.push({
            comanagement: comanagement.Abbreviation,
            total: endingProjectsData[comanagement.ID],
          });
        }
      });
      setEndingProjects(endingProjectsDataName);

      // Projects margin below
      const belowProjects = dashboard.data.BelowMargin;
      const belowProjectsData = {};
      const belowProjectsDataName = [];
      comanagements.data.map(comanagement => {
        belowProjectsData[comanagement.ID] = 0;
      });

      belowProjects.map(item => {
        belowProjectsData[item.Comanagement]++;
      });

      comanagements.data.map(comanagement => {
        if (belowProjectsData[comanagement.ID] > 0) {
          belowProjectsDataName.push({
            comanagement: comanagement.Abbreviation,
            total: belowProjectsData[comanagement.ID],
          });
        }
      });
      setBelowMargin(belowProjectsDataName);

      // Projects margin above
      const aboveProjects = dashboard.data.AboveMargin;
      const aboveProjectsData = {};
      const aboveProjectsDataName = [];
      comanagements.data.map(comanagement => {
        aboveProjectsData[comanagement.ID] = 0;
      });

      aboveProjects.map(item => {
        aboveProjectsData[item.Comanagement]++;
      });

      comanagements.data.map(comanagement => {
        if (aboveProjectsData[comanagement.ID] > 0) {
          aboveProjectsDataName.push({
            comanagement: comanagement.Abbreviation,
            total: aboveProjectsData[comanagement.ID],
          });
        }
      });
      setAboveMargin(aboveProjectsDataName);

      // Percentage offices
      const officesPercentage = dashboard.data.OfficeDesallocationPercentage;
      const officesPercentageData = [];

      officesPercentage.map(office => {
        officesPercentageData.push({
          office: office.OfficeName,
          total: Number(office.DesallocationPercentage),
        });
      });
      setOfficesAllocations(officesPercentageData);

      // Has value adjustment
      setHasValueAdjustment(dashboard.data.HasValueAdjustment);
      setLoading(false);
    });
  }, [setHasControl]);

  const logout = useCallback(() => {
    const msAuth = new MSAuth(config);

    localStorage.clear();
    const uri = msAuth.logout();
    window.location.href = uri;
  }, []);

  const scrollDown = useCallback(() => {
    allocationRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }, []);

  // const handleNavigateProjectPage = useCallback(() => {
  //   history.push('/allocations');
  // }, [history]);

  return (
    <div className="home-kpi">
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress size={64} style={{ color: '#8b0304' }} />
        </div>
      ) : (
        <>
          <div className="home-header">
            <div className="home-header-left">
              <h2>Welcome,</h2>
              <h1>{userName}!</h1>
            </div>
            <div className="home-header-right">
              {hasValueAdjustment && hasScope(allowValueAdjustments) && (
                <Grid container style={{ marginBottom: 15 }}>
                  <Grid item xs={12}>
                    <Alert
                      severity="warning"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setOpenAuditSellValueType(true)}
                    >
                      <Typography component="p">
                        There is some project value adjustment
                      </Typography>
                    </Alert>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
          <div className="charts-wrapper">
            <Card>
              <CardContent>
                <Chart
                  id="active-projects"
                  className="home-charts"
                  dataSource={activeProjects}
                  size={chartSize}
                  tooltip={tooltip}
                  type="bar"
                  series={series}
                  legend={{ visible: false }}
                  title={{
                    horizontalAlignment: 'center',
                    text: `Active projects (current week)`,
                    subtitle: {
                      text: `(Total: ${
                        activeProjects &&
                        activeProjects.reduce(
                          (prev, current) => prev + current.total,
                          0,
                        )
                      })`,
                      font: {
                        size: 14,
                        family: 'Roboto Condensed',
                        color: '#999',
                        weight: 'bold',
                      },
                    },
                    font: {
                      size: 16,
                      family: 'Roboto Condensed',
                      color: '#000',
                      weight: 'bold',
                    },
                  }}
                ></Chart>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Chart
                  id="ending-projects"
                  className="home-charts"
                  dataSource={endingProjects}
                  size={chartSize}
                  tooltip={{
                    enabled: true,
                    format: 'fixedPoint',
                    font: { size: 12, family: 'Roboto Condensed' },
                  }}
                  type="bar"
                  valueAxis={{ valueMarginsEnabled: false }}
                  series={{
                    type: 'bar',
                    argumentField: 'comanagement',
                    valueField: 'total',
                    color: '#8b0304',
                  }}
                  legend={{ visible: false }}
                  title={{
                    horizontalAlignment: 'center',
                    text: `Ending projects (current week)`,
                    subtitle: {
                      text: `(Total: ${
                        endingProjects &&
                        endingProjects.reduce(
                          (prev, current) => prev + current.total,
                          0,
                        )
                      })`,
                      font: {
                        size: 14,
                        family: 'Roboto Condensed',
                        color: '#999',
                        weight: 'bold',
                      },
                    },
                    font: {
                      size: 16,
                      family: 'Roboto Condensed',
                      color: '#000',
                      weight: 'bold',
                    },
                  }}
                ></Chart>
              </CardContent>
            </Card>
            {/* <Card>
              <CardContent>
                <Chart
                  id="below-margin"
                  dataSource={belowMargin}
                  className="home-charts"
                  size={chartSize}
                  tooltip={{
                    enabled: true,
                    format: 'fixedPoint',
                    font: { size: 12, family: 'Roboto Condensed' },
                  }}
                  type="bar"
                  series={{
                    type: 'bar',
                    argumentField: 'comanagement',
                    valueField: 'total',
                    color: '#8b0304',
                  }}
                  legend={{ visible: false }}
                  title={{
                    horizontalAlignment: 'center',

                    text: `Projects with margin below`,
                    subtitle: {
                      text: `(Total: ${
                        belowMargin &&
                        belowMargin.reduce(
                          (prev, current) => prev + current.total,
                          0,
                        )
                      })`,
                      font: {
                        size: 14,
                        family: 'Roboto Condensed',
                        color: '#999',
                        weight: 'bold',
                      },
                    },
                    font: {
                      size: 16,
                      family: 'Roboto Condensed',
                      color: '#000',
                      weight: 'bold',
                    },
                  }}
                ></Chart>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Chart
                  id="above-margin"
                  dataSource={aboveMargin}
                  className="home-charts"
                  size={chartSize}
                  tooltip={{
                    enabled: true,
                    format: 'fixedPoint',
                    font: { size: 12, family: 'Roboto Condensed' },
                  }}
                  type="bar"
                  series={{
                    type: 'bar',
                    argumentField: 'comanagement',
                    valueField: 'total',
                    color: '#8b0304',
                  }}
                  legend={{ visible: false }}
                  title={{
                    horizontalAlignment: 'center',

                    text: `Projects with margin above`,
                    subtitle: {
                      text: `(Total: ${
                        aboveMargin &&
                        aboveMargin.reduce(
                          (prev, current) => prev + current.total,
                          0,
                        )
                      })`,
                      font: {
                        size: 14,
                        family: 'Roboto Condensed',
                        color: '#999',
                        weight: 'bold',
                      },
                    },
                    font: {
                      size: 16,
                      family: 'Roboto Condensed',
                      color: '#000',
                      weight: 'bold',
                    },
                  }}
                ></Chart>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Chart
                  id="office-allocations"
                  dataSource={officesAllocations}
                  className="home-charts"
                  size={chartSize}
                  tooltip={{
                    enabled: true,
                    format: 'fixedPoint',
                    font: { size: 12, family: 'Roboto Condensed' },
                  }}
                  type="bar"
                  series={{
                    type: 'bar',
                    argumentField: 'office',
                    valueField: 'total',
                    color: '#8b0304',
                  }}
                  legend={{ visible: false }}
                  title={{
                    horizontalAlignment: 'center',
                    text: `Unallocated professionals`,
                    subtitle: {
                      text: 'Current week unallocated professionals by office (less than 2.5 days)',
                      font: {
                        size: 14,
                        family: 'Roboto Condensed',
                        color: '#999',
                        weight: 'bold',
                      },
                    },
                    font: {
                      size: 16,
                      family: 'Roboto Condensed',
                      color: '#000',
                      weight: 'bold',
                    },
                  }}
                ></Chart>
              </CardContent>
            </Card> */}
          </div>
        </>
      )}

      {openDialogFeature && (
        <DialogFeature
          open={openDialogFeature}
          handleOpen={() => setOpenDialogFeature(true)}
          handleClose={() => setOpenDialogFeature(false)}
        />
      )}
      {openAuditSellValueType && (
        <DialogAuditSellValueType
          open={openAuditSellValueType}
          handleOpen={() => setOpenAuditSellValueType(true)}
          handleClose={() => setOpenAuditSellValueType(false)}
        />
      )}
    </div>
  );
}
