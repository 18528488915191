import React from 'react';

import './styles.css';

export default function GridLoader({ children }) {
  return (
    <div className="grid-loader">
      { children }
    </div>
  );
}