import React, { useEffect } from 'react';

import { Drawer, Box } from '@material-ui/core';

import { WhisperSpinner } from 'react-spinners-kit';
import { errorMessage$ } from '../../utils/subjects.js';
import GridLoader from './GridLoader.js';
import api from '../../services/api';

interface DialogLoadConflictsProps {
  projectIds: number[];
  handleClose(): void;
  open: boolean;
  onSubmitted(): void;
}

const DialogLoadConflicts: React.FC<DialogLoadConflictsProps> = ({
  handleClose,
  onSubmitted,
  open,
  projectIds,
}) => {
  useEffect(() => {
    Promise.all([
      api.post(`scheduling/projects/conflicts/validate/bulk`, {
        projectIds,
      }),
    ])
      .then(() => {
        handleClose();
        onSubmitted();
      })
      .catch(() => {
        errorMessage$.next({ open: true, message: 'Error on load conflicts' });
      });
  }, [projectIds, onSubmitted, handleClose]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      <Box m={2} style={{ minWidth: 350, maxWidth: '100%' }}>
        <GridLoader>
          <WhisperSpinner size={50} frontColor="#8b0304" backColor="#8b0304" />
        </GridLoader>
      </Box>
    </Drawer>
  );
};

export default DialogLoadConflicts;
