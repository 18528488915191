import React from 'react';
import { FiChevronRight, FiArrowLeft } from 'react-icons/fi';
import { useControlContent } from '../../hooks/controlContent';
import Surface from '../Surface';

import './styles.css';

export default function FirstLevelControl() {
  const {
    collapse,
    hasControl,
    setCollapse,
    controlContent,
    pageTitle,
    goBack,
  } = useControlContent();

  return (
    <>
      {hasControl && (
        <div className="first-level-control">
          <div
            className={`first-level-control-component ${
              !collapse && hasControl ? 'is-expanded is-pinned' : ''
            }`}
          >
            <div className="collapse-first-level-button-component-wrapper">
              <div
                disabled=""
                className={`collapse-first-leve-button-component ${
                  !collapse && hasControl ? 'is-pinned' : ''
                }`}
                onClick={() => setCollapse(collapse ? false : true)}
              >
                <FiChevronRight className="collapse-icon" />
              </div>
            </div>

            <div className="first-level-control-content">
              <div className="home-control-component">
                <div className="home-collapsible-item-component boards is-selected">
                  <div className="home-collapsible-item-header">
                    {goBack && typeof goBack === 'function' ? (
                      <button
                        className="home-collapsible-back"
                        onClick={() => goBack()}
                      >
                        <FiArrowLeft size={18} />
                      </button>
                    ) : null}
                    <div className="item-title">{pageTitle}</div>
                  </div>

                  <div className="home-item-content-wrapper">
                    {controlContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
