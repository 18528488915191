import React, { useEffect, useState, useCallback, useRef } from 'react';

import { SelectBox } from 'devextreme-react';

import './styles.css';

export default function SelectInlineEdit({
  value: _value,
  caption: _caption,
  dataField: _dataField,
  dataSource: _dataSource,
  displayExpr: _displayExpr,
  valueExpr: _valueExpr,
  displayValue: _displayValue,
  onChange,
  onChangeDisplayName,
}) {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(true);
  const [displayValue, setDisplayValue] = useState('');
  const [caption, setCaption] = useState('');
  const [dataField, setDataField] = useState('');
  const [showSelect, setShowSelect] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [displayExpr, setDisplayExpr] = useState([]);
  const [valueExpr, setValueExpr] = useState('');
  const popupRef = useRef(null);

  useEffect(() => {
    setOpen(showSelect);
  }, [showSelect]);

  useEffect(() => {
    setValue(_value);
    setCaption(_caption);
    setDataField(_dataField);
    setDataSource(_dataSource);
    setDisplayExpr(_displayExpr);
    setValueExpr(_valueExpr);
    setDisplayValue(_displayValue);
  }, [
    _caption,
    _dataField,
    _dataSource,
    _displayExpr,
    _displayValue,
    _value,
    _valueExpr,
  ]);

  const handleSubmit = useCallback(() => {
    setShowSelect(false);

    if (onChange) {
      onChange({ [dataField]: value });
    }
    if (onChangeDisplayName) {
      onChangeDisplayName({ [dataField]: displayValue });
    }
  }, [dataField, displayValue, onChange, onChangeDisplayName, value]);

  return (
    <div
      className={`inline-edit-container ${showSelect && 'show-select'}`}
      onClick={() => setShowSelect(true)}
    >
      {!showSelect ? (
        <>
          {!!caption && <span className="secondary-text">{caption}: </span>}
          <span>{displayValue}</span>
        </>
      ) : (
        <div style={{ position: 'relative' }}>
          <SelectBox
            dataSource={dataSource}
            displayExpr={displayExpr}
            valueExpr={valueExpr}
            placeholder={caption}
            searchExpr={[displayExpr]}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            value={value}
            opened={open}
            onOpened={e => {
              e.component.focus();
            }}
            onFocusOut={() => {
              handleSubmit();
            }}
            onValueChanged={e => {
              setValue(e.value);
              setDisplayValue(e.component.option('text'));
            }}
            container="pop-container"
          />
          <div
            className="pop-container"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 999,
            }}
          />
        </div>
      )}
    </div>
  );
}
