import React, { BaseHTMLAttributes } from 'react';

import './styles.css';

const BoardActions: React.FC<BaseHTMLAttributes<HTMLDivElement>> = ({
  style,
  children,
}) => {
  return <div className="board-actions-v2">{children}</div>;
};

export default BoardActions;
