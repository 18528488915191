import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import NumberFormatValue from "../../../components/NumberFormat";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CustomStore from "devextreme/data/custom_store";
import { SelectBox } from "devextreme-react";

import { errorMessage$, successMessage$ } from "../../../utils/subjects";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogCurrency({
  handleClose,
  handleOpen,
  open,
  callback,
}) {
  const classes = useStyles();

  const [practice, setPractice] = useState('');
  const [margin, setMargin] = useState('');
  const [taxPeriod, setTaxPeriod] = useState('');

  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("scheduling/tax-periods");
        const taxPeriodsWithDisplayValue = [];
        response.data.map((taxPeriod) => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });
  const [practiceSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("master/comanagements", {
          params: {
            where: {
              IsActive: true,
            }
          }
        });

        return response.data;
      },
    }),
    paginate: true,
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const data = {
        Practice: practice,
        MarginValue: margin,
        TaxPeriod: taxPeriod,
      };

      handleClose();
      await api.post(`scheduling/practices-margins`, data);

      successMessage$.next({
        open: true,
        message: "Practice margin created successfully",
      });
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: "Error on create practice margin",
      });
    }
  }, [callback, handleClose, margin, practice, taxPeriod]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">+ Add practice margin</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={practiceSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the practice"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={practice}
            required={true}
            onValueChanged={(e) => {
              setPractice(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <TextField
            fullWidth
            label="Margin"
            id="margin-value"
            value={margin}
            required={true}
            onChange={e => setMargin(e.target.value)}
            style={{ marginBottom: 24 }}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
          />
          <SelectBox
            dataSource={taxPeriodSource}
            displayExpr="DisplayValue"
            valueExpr="ID"
            placeholder="Select the period"
            searchExpr={["DisplayValue", "Period", "Year"]}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={taxPeriod}
            required={true}
            onValueChanged={(e) => {
              setTaxPeriod(e.value);
            }}
            style={{ marginBottom: 8 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
