import React, { useState, useEffect, useCallback } from 'react';
import { addDays, format, parseISO, startOfWeek } from 'date-fns';

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
} from '@material-ui/core';
import { WhisperSpinner } from 'react-spinners-kit';
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Paging,
  SearchPanel,
  Lookup,
  Summary,
  GroupItem,
  Scrolling,
} from 'devextreme-react/data-grid';

import ContentLoader from 'react-content-loader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FiArrowDown } from 'react-icons/fi';

import CustomStore from 'devextreme/data/custom_store';

import GridLoader from './GridLoader';

import api from '../../services/api';

export default function DialogConflicts({
  projectId: _projectId,
  handleClose,
  handleOpen,
  open,
}) {
  const [projectId] = useState(_projectId);
  const [conflicts, setConflicts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [comanagementsSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/comanagements');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [professionalsSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals');

        return response.data;
      },
    }),
    paginate: true,
  });

  // useEffect(() => {
  //   const loadConflicts = async () => {
  //     try {
  //       const response = await api.get(
  //         `scheduling/projects/${projectId}/conflicts`,
  //       );

  //       const filterOptions = [];
  //       response.data.map(conflict => {
  //         if (
  //           !filterOptions.some(
  //             option => option.ID === conflict.Professional.ID,
  //           )
  //         ) {
  //           filterOptions.push({
  //             ID: conflict.Professional.ID,
  //             Name: conflict.Professional.Name,
  //           });
  //         }
  //       });

  //       const allocationConflicts = [];

  //       response.data.map(conflict => {
  //         const hasProfessional = allocationConflicts.some(
  //           x => x.Professional === conflict.Professional.ID,
  //         );

  //         if (!hasProfessional) {
  //           if (filter !== '') {
  //             if (Number(filter) === Number(conflict.Professional.ID)) {
  //               allocationConflicts.push({
  //                 Professional: conflict.Professional.ID,
  //                 ProfessionalName: conflict.Professional.Name,
  //                 ProfessionalLogin: conflict.Professional.Login,
  //                 JobTitle:
  //                   conflict.Professional.JobTitle &&
  //                   conflict.Professional.JobTitle.Name,
  //                 Practices:
  //                   conflict.Professional.Practices &&
  //                   conflict.Professional.Practices.map(
  //                     practice => practice.Name,
  //                   ).join(', '),
  //                 Conflicts: [],
  //               });
  //             }
  //           } else {
  //             allocationConflicts.push({
  //               Professional: conflict.Professional.ID,
  //               ProfessionalName: conflict.Professional.Name,
  //               ProfessionalLogin: conflict.Professional.Login,
  //               JobTitle:
  //                 conflict.Professional.JobTitle &&
  //                 conflict.Professional.JobTitle.Name,
  //               Practices:
  //                 conflict.Professional.Practices &&
  //                 conflict.Professional.Practices.map(
  //                   practice => practice.Name,
  //                 ).join(', '),
  //               Conflicts: [],
  //             });
  //           }
  //         }

  //         const key = allocationConflicts.findIndex(
  //           x => x.Professional === conflict.Professional.ID,
  //         );

  //         if (allocationConflicts[key]) {
  //           allocationConflicts[key].Conflicts.push({
  //             Description: conflict.Description,
  //             ScheduleWeek: format(
  //               startOfWeek(
  //                 addDays(
  //                   parseISO(
  //                     conflict.ProjectScheduleWeek.ScheduleWeek.WeekStartDate,
  //                   ),
  //                   2,
  //                 ),
  //                 { weekStartsOn: 1 },
  //               ),
  //               'dd MMM yyyy',
  //             ),
  //             TotalDays: conflict.TotalDays,
  //             Comanagement:
  //               (conflict.DescriptionInfo &&
  //                 conflict.DescriptionInfo.Comanagement) ||
  //               0,
  //             ProjectManager:
  //               (conflict.DescriptionInfo &&
  //                 conflict.DescriptionInfo.ProfessionalManager) ||
  //               0,
  //           });
  //         }
  //       });

  //       const allocationConflictsWithSum = allocationConflicts.map(
  //         allocationConflict => ({
  //           ...allocationConflict,
  //           TotalAllocation: allocationConflict.Conflicts.reduce(
  //             (total, amount) => total + amount.TotalDays,
  //             0,
  //           ),
  //         }),
  //       );

  //       setFilterOptions(filterOptions);
  //       setConflicts(allocationConflictsWithSum);
  //       setLoading(false);
  //     } catch (err) {
  //       console.log('loadConflicts', err);
  //     }
  //   };

  //   loadConflicts();
  // }, [filter, projectId]);

  // const handleChange = useCallback(e => {
  //   setFilter(e.target.value);
  // }, []);

  useEffect(() => {
    const loadConflicts = async () => {
      try {
        const response = await api.get(
          `scheduling/projects/${projectId}/conflicts`,
        );

        const filterOptions = [];
        response.data.map(conflict => {
          if (
            !filterOptions.some(
              option => option.ID === conflict.Professional.ID,
            )
          ) {
            filterOptions.push({
              ID: conflict.Professional.ID,
              Name: conflict.Professional.Name,
            });
          }
        });

        const allocationConflicts = [];

        response.data.map(conflict => {
          const hasProfessional = allocationConflicts.some(
            x => x.Professional === conflict.Professional.ID,
          );

          if (!hasProfessional) {
            if (filter !== '') {
              if (Number(filter) === Number(conflict.Professional.ID)) {
                allocationConflicts.push({
                  Professional: conflict.Professional.ID,
                  ProfessionalName: conflict.Professional.Name,
                  ProfessionalLogin: conflict.Professional.Login,
                  JobTitle:
                    conflict.Professional.JobTitle &&
                    conflict.Professional.JobTitle.Name,
                  Practices:
                    conflict.Professional.Practices &&
                    conflict.Professional.Practices.map(
                      practice => practice.Name,
                    ).join(', '),
                  Conflicts: [],
                });
              }
            } else {
              allocationConflicts.push({
                Professional: conflict.Professional.ID,
                ProfessionalName: conflict.Professional.Name,
                ProfessionalLogin: conflict.Professional.Login,
                JobTitle:
                  conflict.Professional.JobTitle &&
                  conflict.Professional.JobTitle.Name,
                Practices:
                  conflict.Professional.Practices &&
                  conflict.Professional.Practices.map(
                    practice => practice.Name,
                  ).join(', '),
                Conflicts: [],
              });
            }
          }

          const key = allocationConflicts.findIndex(
            x => x.Professional === conflict.Professional.ID,
          );

          if (allocationConflicts[key]) {
            allocationConflicts[key].Conflicts.push({
              Description: conflict.Description,
              ScheduleWeek: format(
                startOfWeek(
                  addDays(
                    parseISO(
                      conflict.ProjectScheduleWeek.ScheduleWeek.WeekStartDate,
                    ),
                    2,
                  ),
                  { weekStartsOn: 1 },
                ),
                'dd MMM yyyy',
              ),
              TotalDays: conflict.TotalDays,
              Comanagement:
                (conflict.DescriptionInfo &&
                  conflict.DescriptionInfo.Comanagement) ||
                0,
              ProjectManager:
                (conflict.DescriptionInfo &&
                  conflict.DescriptionInfo.ProfessionalManager) ||
                0,
            });
          }
        });

        const allocationConflictsWithSum = allocationConflicts.map(
          allocationConflict => ({
            ...allocationConflict,
            TotalAllocation: allocationConflict.Conflicts.reduce(
              (total, amount) => total + amount.TotalDays,
              0,
            ),
          }),
        );

        setFilterOptions(filterOptions);
        setConflicts(allocationConflictsWithSum);
        setLoading(false);
      } catch (err) {
        console.log('loadConflicts', err);
      }
    };

    loadConflicts();
  }, [filter, projectId]);

  const handleChange = useCallback(e => {
    setFilter(e.target.value);
  });

  return (
    <>
      {loading ? (
        <ContentLoader
          speed={2}
          width={540}
          height={300}
          viewBox={`0 0 540 300`}
          backgroundColor="#f3f3f3"
          foregroundColor="#dedede"
        >
          <rect x="0" y="0" rx="7" ry="7" width="540" height="30" />
          <rect x="0" y="60" rx="7" ry="7" width="540" height="100" />
          <rect x="0" y="190" rx="7" ry="7" width="540" height="100" />
        </ContentLoader>
      ) : (
        <FormGroup style={{ marginTop: 15 }}>
          <FormControl style={{ marginBottom: 15 }} fullWidth>
            <InputLabel id="filter-professional">
              Filter professional
            </InputLabel>
            <Select
              labelId="filter-professional"
              id="filter-professional-select"
              value={filter}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="" selected>
                (All)
              </MenuItem>
              {filterOptions.map(option => (
                <MenuItem key={option.ID} value={option.ID}>
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {conflicts.map(conflict => (
            <Accordion key={conflict.Professional}>
              <AccordionSummary
                expandIcon={<FiArrowDown />}
                className="professional-dialog"
              >
                <div
                  className="professional-dialog-header"
                  style={{ padding: 0 }}
                >
                  <img
                    className="professional-image"
                    alt={conflict.ProfessionalLogin}
                    src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${conflict.ProfessionalLogin}_192_192.png`}
                  />
                  <div className="professional-dialog-header-info">
                    <h2>{conflict.ProfessionalName}</h2>
                    <p>
                      {conflict.JobTitle} | {conflict.Practices}{' '}
                    </p>
                    <p>
                      <b>Total allocation: {conflict.TotalAllocation}</b>
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <DataGrid
                  dataSource={conflict.Conflicts}
                  allowColumnReordering={false}
                  showBorders={false}
                  className="primary-header"
                  height="100%"
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={false} />
                  <Grouping autoExpandAll={false} />
                  <Paging enabled={false} />

                  <Column dataField="Description" dataType="string" />
                  {/* <Column dataField="Comanagement" dataType="string">
                  <Lookup dataSource={comanagementsSource} displayExpr="Name" valueExpr="ID" />
                </Column> */}
                  <Column dataField="ProjectManager" dataType="string">
                    <Lookup
                      dataSource={professionalsSource}
                      displayExpr="Name"
                      valueExpr="ID"
                    />
                  </Column>
                  <Column dataField="TotalDays" dataType="string" />
                  <Column
                    dataField="ScheduleWeek"
                    dataType="string"
                    groupIndex={0}
                  />

                  <Summary>
                    <GroupItem
                      column="TotalDays"
                      summaryType="sum"
                      displayFormat="{0} days allocated"
                    />
                  </Summary>
                </DataGrid>
              </AccordionDetails>
            </Accordion>
          ))}
        </FormGroup>
      )}
    </>
  );
}
