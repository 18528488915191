import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';

import { errorMessage$, successMessage$ } from '../../../utils/subjects.js';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface DialogAuxJobTitleProps {
  handleClose(): void;
  handleOpen(): void;
  callback(): void;
  open: boolean;
}

export const DialogAuxJobTitle: React.FC<DialogAuxJobTitleProps> = ({
  handleClose,
  handleOpen,
  open,
  callback,
}) => {
  const classes = useStyles();

  const [professional, setProfessional] = useState(0);
  const [jobtitle, setJobtitle] = useState(0);

  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/professionals');

        return data;
      },
    }),
    paginate: true,
  });

  const [jobtitleSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('master/jobtitles');

        return data;
      },
    }),
    paginate: true,
  });

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        const data = {
          Professional: professional,
          Position: jobtitle,
        };

        handleClose();
        await api.post(`scheduling/professional-jobtitles`, data);

        successMessage$.next({
          open: true,
          message: 'Aux Jobtitle created successfully',
        });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on create aux jobtitle',
        });
      }
    },
    [callback, handleClose, professional, jobtitle],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">+ Add aux jobtitle</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={professionalSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the professional"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={professional}
            onValueChanged={e => {
              setProfessional(e.value);
            }}
            style={{ marginBottom: 24 }}
          />

          <SelectBox
            dataSource={jobtitleSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the jobtitle"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={jobtitle}
            onValueChanged={e => {
              setJobtitle(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
