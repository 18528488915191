import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogCostRateDelete({
  handleClose,
  handleOpen,
  open,
  id,
  callback,
}) {
  const classes = useStyles();
  const [costRateId] = useState(id);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      handleClose();
      await api.delete(`scheduling/cost-rates/${costRateId}`);

      successMessage$.next({
        open: true,
        message: 'Cost rate deleted successfully',
      });
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Error on delete cost rate' });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Do you confirm?</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent>
          <p>Do you would to like to delete the cost rate</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
