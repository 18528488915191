import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Drawer,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import api from '../../services/api';

export default function DialogSelectYear({
  professionalId,
  open,
  handleClose,
  handleOpen,
  onChangedYear,
  year: _year,
}) {
  const [year, setYear] = useState(_year);
  const [years, setYears] = useState([]);

  useEffect(() => {
    Promise.all([
      api.get(
        `scheduling/professionals/${professionalId}/schedule-weeks/years`,
      ),
    ]).then(([years]) => {
      setYears(years.data);
    });
  }, [professionalId]);

  const handleChangeYear = useCallback(() => {
    if (onChangedYear) onChangedYear(year);
  }, [onChangedYear, year]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      <div className="change-year-dialog">
        <h2>Change Year</h2>
        <div className="change-year-select">
          <Select
            labelId="years-label"
            id="years-select"
            value={year}
            onChange={e => setYear(e.target.value)}
          >
            {years.map(year => (
              <MenuItem key={year.Year} value={year.Year}>
                {year.Year}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="buttons-row">
          <Button onClick={handleChangeYear} color="primary">
            Change year
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </div>
    </Drawer>
  );
}
