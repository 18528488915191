import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import NumberFormatValue from '../../../components/NumberFormat';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogExchangeRate({
  handleClose,
  handleOpen,
  open,
  callback,
}) {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [taxPeriod, setTaxPeriod] = useState(null);
  const [currencyTo, setCurrencyTo] = useState(1);
  const [currencyFrom, setCurrencyFrom] = useState(1);

  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/tax-periods');
        const taxPeriodsWithDisplayValue = [];
        response.data.map(taxPeriod => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencies = response.data;
        const currencyWithDisplayName = currencies.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        const data = {
          IDCurrencyTo: currencyTo,
          IDCurrencyFrom: currencyFrom,
          TaxPeriod: taxPeriod,
          ConversionRateValue: value,
        };

        handleClose();
        await api.post(`scheduling/exchange-rates`, data);

        successMessage$.next({
          open: true,
          message: 'Exchange rate created successfully',
        });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on create exchange rate',
        });
      }
    },
    [value, callback, currencyFrom, currencyTo, handleClose, taxPeriod],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">+ Add exchange rate</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <SelectBox
            dataSource={taxPeriodSource}
            displayExpr="DisplayValue"
            valueExpr="ID"
            placeholder="Select the period"
            searchExpr={['DisplayValue', 'Period', 'Year']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={taxPeriod}
            required={true}
            onValueChanged={e => {
              setTaxPeriod(e.value);
            }}
            style={{ marginBottom: 8 }}
          />
          <SelectBox
            dataSource={currencySource}
            displayExpr="DisplayName"
            valueExpr="ID"
            placeholder="Select a currency from"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={currencyFrom}
            required={true}
            onValueChanged={e => {
              setCurrencyFrom(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <SelectBox
            dataSource={currencySource}
            displayExpr="DisplayName"
            valueExpr="ID"
            placeholder="Select a currency to"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={currencyTo}
            required={true}
            onValueChanged={e => {
              setCurrencyTo(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <TextField
            fullWidth
            label="Exchange value"
            id="exchange-value"
            value={value}
            required={true}
            onChange={e => setValue(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
            style={{ marginBottom: 24 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
