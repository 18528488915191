import React, { useState, useEffect, useCallback } from 'react';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TextField, FormGroup } from '@material-ui/core';

import api from '../../services/api';

export default function DialogIncreaseWeeks({
  projectId: _projectId,
  handleClose,
  handleOpen,
  open,
  weeks,
  onCreatedWeeks,
  onReload,
}) {
  const [projectId] = useState(_projectId);
  const [weeksToIncrease, setWeeksToIncrease] = useState(1);
  const [totalWeeks, setTotalWeeks] = useState(weeks.length);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (weeksToIncrease < 1 && weeksToIncrease !== '') setWeeksToIncrease(1);

    setTotalWeeks(weeks.length + Number(weeksToIncrease));
  }, [weeks, weeksToIncrease]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const response = await api.patch(
      `scheduling/simulation/projects/${projectId}/schedule-weeks/increase`,
      {
        ScheduleWeeks: Number(weeksToIncrease),
      },
    );

    if (onCreatedWeeks) {
      onCreatedWeeks(response.data);
    }
    onReload();
    setIsLoading(false);
    handleClose();
  }, [handleClose, onCreatedWeeks, projectId, weeksToIncrease]);

  return (
    <>
      <p
        style={{
          marginBottom: 15,
          textAlign: 'center',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '0.05em',
        }}
      >
        Total of weeks: <strong>{totalWeeks}</strong>
      </p>
      <FormGroup style={{ marginTop: 15 }}>
        <TextField
          id="increase-weeks"
          label="How many weeks do you want to increase?"
          type="number"
          fullWidth
          value={weeksToIncrease}
          onChange={e => setWeeksToIncrease(e.target.value)}
        />
      </FormGroup>
      <div className="buttons-row">
        <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Increase'
          )}
        </Button>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
      </div>
    </>
  );
}
