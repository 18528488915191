import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Switch, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogClientEdit({handleClose, handleOpen, open, id, callback}) {
  const classes = useStyles();
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  // const [openPrimarySegment, setOpenPrimarySegment] = useState(false);
  // const [openSecondarySegment, setOpenSecondarySegment] = useState(false);

  const [clientId] = useState(id);
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);

  // const [primarySegments, setPrimarySegments] = useState([]);
  // const [secondarySegments, setSecondarySegments] = useState([]);
  const [economicGroup, setEconomicGroup] = useState(null);

  // const [segments, setSegments] = useState([]);
  const [economicsGroups, setEconomicsGroups] = useState([]);

  useEffect(() => {
    loadEconomicsGroups();
    // loadSegments();
  }, []);

  useEffect(() => {
    if (economicsGroups.length > 0) {
      const loadClient = async () => {
        try {
          const client = await api.get(`master/clients/${clientId}`);
          setName(client.data.Name);
          setIsActive(client.data.IsActive);
    
          setEconomicGroup(client.data.EconomicGroup);
          // Map primary and secondary segments
          // don't save client segments on scheduling.
          // just KNM should do it on Portal
          // const clientSegments = client.data.ClientSegments;
          // const primarySegments = [];
          // const secundarySegments = [];
          // clientSegments.map(clientSegment => {
          //   if (clientSegment.SegmentLevel === 1) primarySegments.push(clientSegment.Segment);
          //   if (clientSegment.SegmentLevel === 2) secundarySegments.push(clientSegment.Segment);
          // });

          // setPrimarySegments(primarySegments);
          // setSecondarySegments(secundarySegments);
    
        } catch (err) {
          errorMessage$.next({open: true, message: 'Error to get client information'});
        }
      }

      loadClient();
    }
  }, [clientId, economicsGroups]);


  async function loadEconomicsGroups() {
    try {
      const response = await api.get('master/client-economic-groups');
      setEconomicsGroups(Object.keys(response.data).map((key) => response.data[key]));
    } catch (err) {
      errorMessage$.next({open: true, message: 'Error on listing economics groups'});
    }
  }

  // async function loadSegments() {
  //   try {
  //     const response = await api.get('master/segments');
  //     setSegments(Object.keys(response.data).map((key) => response.data[key]));
  //   } catch (err) {
  //     errorMessage$.next({open: true, message: 'Error on listing segments'});
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const clientData = {
        Name: name,
        IsActive: isActive, 
        EconomicGroup: economicGroup.ID,
        // ClientSegments: []
      };

      // primarySegments.map(segment => {
      //   clientData.ClientSegments.push({ SegmentLevel: 1, Segment: segment.ID });
      // });

      // secondarySegments.map(segment => {
      //   clientData.ClientSegments.push({ SegmentLevel: 2, Segment: segment.ID });
      // });

      handleClose();
      await api.put(`master/clients/${clientId}`, clientData);
      
      successMessage$.next({open: true, message: 'Client updated successfully'});
      if (callback) callback();
    } catch (err) {
      console.log(err);
      errorMessage$.next({open: true, message: 'Error on update client'});
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit client {name}</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            style={{marginBottom: 8}}
          />

          <Autocomplete
            id="client-economic-group-autocomplete"
            style={{ width: '100%', marginBottom: 8 }}
            open={openAutoComplete}
            onOpen={() => {
              setOpenAutoComplete(true);
            }}
            onClose={() => {
              setOpenAutoComplete(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setEconomicGroup(value)}
            options={economicsGroups}
            value={economicGroup}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Economic Group"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          {/* <Autocomplete
            id="client-primary-group-autocomplete"
            multiple
            style={{ width: '100%', marginBottom: 8 }}
            open={openPrimarySegment}
            onOpen={() => {
              setOpenPrimarySegment(true);
            }}
            onClose={() => {
              setOpenPrimarySegment(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setPrimarySegments(value)}
            options={segments}
            value={primarySegments}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Primary Segment"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />


          <Autocomplete
            id="client-secondary-group-autocomplete"
            multiple
            style={{ width: '100%', marginBottom: 8 }}
            open={openSecondarySegment}
            onOpen={() => {
              setOpenSecondarySegment(true);
            }}
            onClose={() => {
              setOpenSecondarySegment(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setSecondarySegments(value)}
            options={segments}
            value={secondarySegments}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary Segment"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          /> */}

          <FormControlLabel
            control={<Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} name="client-is-active" color="primary" />}
            label="Active"
            style={{ width: '100%', marginBottom: 8 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}