import { Tooltip } from '@material-ui/core';
import React, { BaseHTMLAttributes } from 'react';

import './styles.css';

interface BoardDescriptionProps extends BaseHTMLAttributes<HTMLDivElement> {
  title: string;
}

const BoardTitle: React.FC<BoardDescriptionProps> = ({ title, style }) => {
  return (
    <div className="board-name" style={style}>
      <div className="main-title">
        <span>{title}</span>
      </div>
    </div>
  );
};

export default BoardTitle;
