import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

export const PublicRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useAuth();

	return (
		<Route
			{...rest}
			render={props => (
				!isAuthenticated() ? (
					<Component {...props} />
				) : (
						<Redirect to={{ pathname: '/app', state: { from: props.location } }} />
					)
			)
			}
		/>
	)
};