import React, { useCallback } from 'react';

import { Button, Drawer, Box, Typography } from '@material-ui/core';

import api from '../../../services/api';

import { errorMessage$, successMessage$, infoMessage$ } from '../../../utils/subjects';

export default function ConsolidatedMoreWeeks({ open, handleClose, handleOpen }) {
  const handleGenerate = useCallback(async () => {
    try {
      infoMessage$.next({ open: true, message: 'We generating your report. It could take a while' });

      const response = await api.get('scheduling/reports/allocations/consolidated-more-weeks', {
        responseType: 'blob',
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-consolidated.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      successMessage$.next({ open: true, message: 'Your report is ready. Open or save it below' });
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Something were wrong with your report. Try again please.' });
    }
  }, []);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2} minWidth={300}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Consolidated More Weeks report
        </Typography>

        <Typography variant="p" component="p">
          Click on "Generate" and wait.
        </Typography>

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button
            onClick={handleGenerate}
            variant="contained"
            color="primary"
            size="small"
          >
            Generate
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}