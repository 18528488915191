import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Drawer,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { startOfWeek, format } from 'date-fns';

import api from '../../services/api';
import { setWeekYear, setWeek } from '../../utils/IntegrationDates';

export default function DialogWeekClosures({
  projectId,
  open,
  handleClose,
  handleOpen,
  onChangedClosure,
  closureId: _closureId,
}) {
  const [closureId, setClosureId] = useState(
    _closureId === '' ? 'current' : _closureId,
  );
  const [closures, setClosures] = useState([]);

  useEffect(() => {
    Promise.all([
      api.get(`scheduling/schedule-weeks/${projectId}/closures`),
    ]).then(([closures]) => {
      setClosures(closures.data);
    });
  }, [projectId]);

  const handleChangeClosure = useCallback(() => {
    handleClose();
    if (onChangedClosure) {
      onChangedClosure(closureId === 'current' ? '' : closureId);
    }
  }, [closureId, handleClose, onChangedClosure]);

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="week-closures-label">Week closures</InputLabel>
          <Select
            labelId="week-closures-label"
            id="week-closures-select"
            value={closureId}
            onChange={e => setClosureId(e.target.value)}
          >
            <MenuItem key="" value="current">
              Current week
            </MenuItem>
            {closures.map(closure => {
              let date = new Date();
              date = setWeekYear(date, closure.Year);
              date = setWeek(date, closure.Week);
              date = startOfWeek(date);

              return (
                <MenuItem key={closure.ID} value={closure.ID}>
                  {format(date, 'dd MMM yyyy')}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className="buttons-row">
        <Button
          onClick={handleChangeClosure}
          variant="contained"
          color="primary"
          size="small"
        >
          Apply closure
        </Button>

        <Button onClick={handleClose} size="small">
          Cancel
        </Button>
      </div>
    </>
  );
}
