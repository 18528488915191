import React, { createContext, useContext, useCallback, useState, useEffect } from 'react';

interface ControlContentContextData {
  setControlContent(component: any): void;
  controlContent: any;
  collapse: boolean;
  setCollapse(collapse: boolean): void;
  hasControl: boolean;
  setHasControl(hasControl: boolean): void;
  pageTitle: string;
  setPageTitle(pageTitle: string): void;
  setGoBack(goBack: any): void;
  goBack: any;
}

const ControlContentContext = createContext<ControlContentContextData>({} as ControlContentContextData);

export const ControlContentProvider: React.FC = ({ children }) => {
  const [controlContent, setControlContent] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [hasControl, setHasControl] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [goBack, setGoBack] = useState(null);

  useEffect(() => {
    const savedCollapse = localStorage.getItem('controlContentCollapse');

    localStorage.setItem(
      'controlContentCollapse', 
      (savedCollapse === undefined ? false : !!savedCollapse).toString()
    );
  }, []);

  const handleChangeControlContent = useCallback((component) => {
    if (component) {
      setCollapse(false);
      setHasControl(true);
      setGoBack(null);
      setControlContent(component);
    } else {
      setHasControl(false);
    }
  }, []);

  const handleSetCollapse = useCallback((value) => {
    setCollapse(value);
    localStorage.setItem('controlContentCollapse', value);
  }, []);

  const handleSetGoBack = useCallback((value) => {
    if (value && typeof value === 'function') {
      setGoBack(() => value);
    }
  }, []);

  const handleSetHasControl = useCallback((value) => {
    setHasControl(value);
  }, []);

  const handleChangePageTitle = useCallback((value) => {
    setPageTitle(value);
  }, []);

  return (
    <ControlContentContext.Provider value={{ 
      setControlContent: handleChangeControlContent, 
      controlContent, 
      collapse, 
      setCollapse: handleSetCollapse,
      hasControl,
      setHasControl: handleSetHasControl,
      setPageTitle: handleChangePageTitle,
      pageTitle,
      goBack,
      setGoBack: handleSetGoBack,
    }}>
      { children }
    </ControlContentContext.Provider>
  );
}

export function useControlContent(): ControlContentContextData {
  const context = useContext(ControlContentContext);

  if (!context) {
    throw new Error('useControlContent must be used within an ControlContentProvider');
  }

  return context;
}