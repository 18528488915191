import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { errorMessage$, successMessage$ } from "../../../utils/subjects";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogCurrency({
  handleClose,
  handleOpen,
  open,
  callback,
}) {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const data = {
        Name: name,
        Abbreviation: abbreviation,
      };

      handleClose();
      await api.post(`master/currencies`, data);

      successMessage$.next({
        open: true,
        message: "Currency created successfully",
      });
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: "Error on create currency",
      });
    }
  }, [abbreviation, callback, handleClose, name]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">+ Add currency</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            fullWidth
            label="Currency (ISO)"
            id="currency-name"
            value={name}
            required={true}
            onChange={e => setName(e.target.value)}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Abbreviation"
            id="currency-abbreviation"
            value={abbreviation}
            required={true}
            onChange={e => setAbbreviation(e.target.value)}
            style={{ marginBottom: 24 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
