import React, { useEffect, useCallback } from 'react';

import { useHistory } from "react-router-dom";

import { useControlContent } from "../../../hooks/controlContent";

import ProfessionalControlContentList from '../../../components/ProfessionalControlContentList';

import LandingPage from '../../../components/LandingPage';

export default function ProfessionalHome() {
  const history = useHistory();
  const { setControlContent, setPageTitle, setGoBack, pageTitle } = useControlContent();

  const handleBack = useCallback(() => {
    history.push('/hr');
  }, [history]);

  const handleNavigationAllocation = useCallback((professionalId) => {
    history.push(`/hr/professionals/${professionalId}`);
  }, [history]);

  // Build control menu
  useEffect(() => {
    setControlContent(<ProfessionalControlContentList onSelectProfessional={handleNavigationAllocation} />);
    setPageTitle('HR - Professionals');
    setGoBack(() => handleBack());
  }, [handleBack, handleNavigationAllocation, setControlContent, setGoBack, setPageTitle]);

  return (
    <LandingPage title={pageTitle} subtitle="Select a professional to edit holidays, licences, vacation, etc" />
  );
}