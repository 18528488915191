import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Input, FormControlLabel, Switch } from '@material-ui/core';
import api from '../../services/api';
import CustomStore from "devextreme/data/custom_store";
import { SelectBox } from 'devextreme-react';

export default function DialogFilter({ open, handleClose, onFilter, filter: starterFilters }) {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [client, setClient] = useState('');
  const [comanagement, setComanagement] = useState('');
  const [status, setStatus] = useState('');
  const [filter, setFilter] = useState(null);
  const [clientSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('master/clients');
        return response.data;
      }
    }),
    paginate: true
  });
  const [comanagementSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('master/comanagements');
        return response.data;
      }
    }),
    paginate: true
  });
  const [statusSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get('scheduling/project-status');
        return response.data;
      }
    }),
    paginate: true
  });

  useEffect(() => {
    if (starterFilters && starterFilters.ProjectName) {
      setName(starterFilters.ProjectName);
    }
    if (starterFilters && starterFilters.ProjectCode) {
      setCode(starterFilters.ProjectCode);
    }
    if (starterFilters && starterFilters.Client) {
      setClient(starterFilters.Client);
    }
    if (starterFilters && starterFilters.Comanagement) {
      setComanagement(starterFilters.Comanagement);
    }
    if (starterFilters && starterFilters.ProjectStatus) {
      setStatus(starterFilters.ProjectStatus);
    }
  }, [starterFilters]);

  const handleApplyFilters = useCallback(() => {
    if (name) {
      setFilter(state => ({...state, ProjectName: name}));
    }
    if (code) {
      setFilter(state => ({...state, ProjectCode: code}));
    }
    if (client) {
      setFilter(state => ({...state, Client: client}));
    }
    if (comanagement) {
      setFilter(state => ({...state, Comanagement: comanagement}));
    }
    if (status) {
      setFilter(state => ({...state, ProjectStatus: status}));
    }

    console.log(client, code, comanagement, name, status)

  }, [client, code, comanagement, name, status]);

  const handleClearFilters = useCallback(() => {
    setFilter({});
  }, []);

  useEffect(() => {
    onFilter(filter);
  }, [filter, onFilter]);

  return (
    <Drawer anchor="top" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Apply filters
        </Typography>

        <FormControl fullWidth>
          <InputLabel htmlFor="project-name-filter">Name</InputLabel>
          <Input
            id="project-name-filter"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 8 }}>
          <InputLabel htmlFor="project-code-inside-filter">Code</InputLabel>
          <Input
            id="project-code-inside-filter"
            type="text"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginTop: 24 }}>
          <SelectBox
            dataSource={clientSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Client"
            searchExpr={['Name']}
            minSearchLength={3}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={client}
            onValueChanged={e => setClient(e.value)}
          />
        </FormControl>

        {/* <FormControl fullWidth style={{ marginTop: 24 }}>
          <SelectBox
            dataSource={comanagementSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Co-Management"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={comanagement}
            onValueChanged={e => setComanagement(e.value)}
          />
        </FormControl> */}

        <FormControl fullWidth style={{ marginTop: 24 }}>
          <SelectBox
            dataSource={statusSource}
            displayExpr="Description"
            valueExpr="ID"
            placeholder="Status"
            searchExpr={['Description']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={status}
            onValueChanged={e => setStatus(e.value)}
          />
        </FormControl>

        <div style={{ marginTop: 8, float: 'right' }}>
          <Button 
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button 
            onClick={handleClearFilters}
            color="primary" 
            size="small"
            style={{ marginRight: 15 }}
          >
            Clear filters
          </Button>

          <Button 
            onClick={handleApplyFilters} 
            variant="contained"
            color="primary" 
            size="small"
          >
            Apply filters
          </Button>
        </div>
      </Box>
    </Drawer>
  )
}