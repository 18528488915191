import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  FormGroup,
  Typography,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClassicSpinner } from 'react-spinners-kit';

import { AxiosError } from 'axios';
import CustomStore from 'devextreme/data/custom_store.js';
import { SelectBox } from 'devextreme-react';
import { errorMessage$, successMessage$ } from '../../utils/subjects.js';
import TabPanel from '../../components/TabPanel/index.js';

import GridLoader from './GridLoader.js';

import api, { IReason } from '../../services/api';

interface DialogWeeksProps {
  projectId: number;
  handleClose(): void;
  open: boolean;
  onSubmitted(): void;
  onReload(): void;
  type: string;
}

const DialogWeeks: React.FC<DialogWeeksProps> = ({
  projectId,
  handleClose,
  open,
  onSubmitted,
  onReload,
  type,
}) => {
  const [reasons] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/reasons');
        return response.data.filter(
          (x: IReason) => x.ReasonType === 3 || x.ReasonType === 4,
        );
      },
    }),
    paginate: true,
  });

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [totalWeeks, setTotalWeeks] = useState(0);
  const [weeksToManage, setWeeksToManage] = useState(1);
  const [direction, setDirection] = useState('end');
  const [reason, setReason] = useState(0);

  // useEffect(() => {
  //   setLoading(true);
  //   console.log(`${type}/${projectId}/schedule-weeks`);
  //   api
  //     .get(`${type}/${projectId}/schedule-weeks`)
  //     .then(response => {
  //       setTotalWeeks(response.data.length);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       errorMessage$.next({
  //         open: true,
  //         message: 'Something went wrong when loading weeks',
  //       });
  //     });
  // }, [projectId]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`scheduling/projects/${projectId}/schedule-weeks`)
      .then(response => {
        setTotalWeeks(response.data.length);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        errorMessage$.next({
          open: true,
          message: 'Something went wrong when loading weeks',
        });
      });
  }, [projectId]);

  // const handleAdd = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     await api.post(`${type}/${projectId}/schedule-weeks`, {
  //       ScheduleWeeks: Number(weeksToManage),
  //       Direction: direction,
  //       Reason: reason,
  //     });
  //   } catch (err) {
  //     setLoading(false);
  //     return;
  //   }

  //   setLoading(false);
  //   successMessage$.next({
  //     open: true,
  //     message: 'Week(s) added!',
  //   });
  //   onSubmitted();
  //   onReload();
  //   handleClose();
  // }, [onSubmitted, handleClose, projectId, weeksToManage, direction, reason]);

  // const handleRemove = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     await api.delete(`${type}/projects/${projectId}/schedule-weeks`, {
  //       params: {
  //         ScheduleWeeks: Number(weeksToManage),
  //         Direction: direction,
  //       },
  //     });
  //   } catch (err) {
  //     setLoading(false);
  //     return;
  //   }

  //   setLoading(false);
  //   successMessage$.next({
  //     open: true,
  //     message: 'Week(s) removed!',
  //   });
  //   onSubmitted();
  //   onReload();
  //   handleClose();
  // }, [onSubmitted, handleClose, projectId, direction, weeksToManage]);

  const handleAdd = useCallback(async () => {
    setLoading(true);
    try {
      await api.post(`scheduling/projects/${projectId}/schedule-weeks`, {
        ScheduleWeeks: Number(weeksToManage),
        Direction: direction,
        Reason: reason,
      });
    } catch (err) {
      setLoading(false);
      return;
    }

    setLoading(false);
    successMessage$.next({
      open: true,
      message: 'Week(s) added!',
    });
    onSubmitted();
    onReload();
    handleClose();
  }, [
    onSubmitted,
    onReload,
    handleClose,
    projectId,
    weeksToManage,
    direction,
    reason,
  ]);

  const handleRemove = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`scheduling/projects/${projectId}/schedule-weeks`, {
        params: {
          ScheduleWeeks: Number(weeksToManage),
          Direction: direction,
        },
      });
    } catch (err) {
      setLoading(false);
      return;
    }

    setLoading(false);
    successMessage$.next({
      open: true,
      message: 'Week(s) removed!',
    });
    onSubmitted();
    onReload();
    handleClose();
  }, [onSubmitted, onReload, handleClose, projectId, weeksToManage, direction]);

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, newValue) => setTab(newValue)}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
      >
        <Tab label="Add" />
        <Tab label="Remove" />
      </Tabs>
      <TabPanel value={tab} index={0} style={{ padding: 0 }}>
        {loading && <ClassicSpinner size={50} />}
        <p
          style={{
            marginBottom: 15,
            textAlign: 'center',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
          }}
        >
          Total of weeks: <strong>{totalWeeks}</strong>
        </p>
        <FormGroup style={{ marginTop: 15 }}>
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Typography gutterBottom>Add weeks from the</Typography>
            <Select
              labelId="direction-select-label"
              id="direction-select"
              value={direction}
              onChange={e => setDirection(e.target.value as string)}
            >
              <MenuItem value="end">End</MenuItem>
              <MenuItem value="start">Beginning</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        <FormGroup style={{ marginTop: 15 }}>
          <Typography gutterBottom>How many weeks?</Typography>
          <TextField
            id="increase-weeks"
            type="number"
            fullWidth
            value={weeksToManage}
            onChange={e => setWeeksToManage(Number(e.target.value))}
          />
        </FormGroup>
        <FormGroup style={{ marginTop: 15 }}>
          <Typography gutterBottom>Reason</Typography>
          <SelectBox
            dataSource={reasons}
            displayExpr="Description"
            valueExpr="ID"
            // defaultValue={activeWeeks[0] && activeWeeks[0].StartDate}
            onValueChanged={e => setReason(e.value)}
          />
        </FormGroup>
        <div className="buttons-row">
          <Button onClick={handleAdd} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Add'}
          </Button>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1} style={{ padding: 0 }}>
        {loading && <ClassicSpinner size={50} />}
        <p
          style={{
            marginBottom: 15,
            textAlign: 'center',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
          }}
        >
          Total of weeks: <strong>{totalWeeks}</strong>
        </p>
        <FormGroup style={{ marginTop: 15 }}>
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Typography gutterBottom>Remove weeks from the</Typography>
            <Select
              labelId="direction-select-label"
              id="direction-select"
              value={direction}
              onChange={e => setDirection(e.target.value as string)}
              required
            >
              <MenuItem value="end">End</MenuItem>
              <MenuItem value="start">Beginning</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
        <FormGroup style={{ marginTop: 15 }}>
          <Typography gutterBottom>How many weeks?</Typography>

          <TextField
            id="remove-weeks"
            type="number"
            fullWidth
            value={weeksToManage}
            onChange={e => setWeeksToManage(Number(e.target.value))}
            required
          />
        </FormGroup>

        <div className="buttons-row">
          <Button onClick={handleRemove} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Remove'}
          </Button>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
        </div>
      </TabPanel>
    </>
  );
};

export default DialogWeeks;
