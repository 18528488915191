import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {errorMessage$, successMessage$} from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogClient({handleClose, handleOpen, open, callback}) {
  const classes = useStyles();
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  // const [openPrimarySegment, setOpenPrimarySegment] = useState(false);
  // const [openSecondarySegment, setOpenSecondarySegment] = useState(false);
  const [name, setName] = useState('');
  // const [primarySegments, setPrimarySegments] = useState([]);
  // const [secondarySegments, setSecondarySegments] = useState([]);
  // const [segments, setSegments] = useState([]);
  const [economicGroup, setEconomicGroup] = useState('');
  const [economicsGroups, setEconomicsGroups] = useState([]);

  useEffect(() => {
    loadEconomicsGroups();
    // loadSegments();
  }, []);

  async function loadEconomicsGroups() {
    try {
      const response = await api.get('master/client-economic-groups');
      setEconomicsGroups(Object.keys(response.data).map((key) => response.data[key]));
    } catch (err) {
      errorMessage$.next({open: true, message: 'Error on listing economics groups'});
    }
  }

  // async function loadSegments() {
  //   try {
  //     const response = await api.get('master/segments');
  //     setSegments(Object.keys(response.data).map((key) => response.data[key]));
  //   } catch (err) {
  //     errorMessage$.next({open: true, message: 'Error on listing segments'});
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const clientData = {
        Name: name,
        LogoAuthorization: false,
        IsActive: true, 
        EconomicGroup: economicGroup,
        ClientSegments: []
      };

      // don't save client segments on scheduling.
      // just KNM should do it on Portal
      // primarySegments.map(segment => {
      //   clientData.ClientSegments.push({ SegmentLevel: 1, Segment: segment });
      // });

      // secondarySegments.map(segment => {
      //   clientData.ClientSegments.push({ SegmentLevel: 2, Segment: segment });
      // });

      handleClose();
      await api.post('master/clients', clientData);
      successMessage$.next({open: true, message: 'Client created successfully'});
      
      if (callback) callback();
    } catch (err) {
      errorMessage$.next({open: true, message: 'Error on creating client'});
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">+ Add client</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
            style={{marginBottom: 8}}
          />

          <Autocomplete
            id="client-economic-group-autocomplete"
            style={{ width: '100%', marginBottom: 8 }}
            open={openAutoComplete}
            onOpen={() => {
              setOpenAutoComplete(true);
            }}
            onClose={() => {
              setOpenAutoComplete(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setEconomicGroup(value.ID)}
            options={economicsGroups}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Economic Group"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          {/* <Autocomplete
            id="client-primary-group-autocomplete"
            multiple
            style={{ width: '100%', marginBottom: 8 }}
            open={openPrimarySegment}
            onOpen={() => {
              setOpenPrimarySegment(true);
            }}
            onClose={() => {
              setOpenPrimarySegment(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setPrimarySegments(value.map(v => v.ID))}
            options={segments}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Primary Segment"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />


          <Autocomplete
            id="client-secondary-group-autocomplete"
            multiple
            style={{ width: '100%', marginBottom: 8 }}
            open={openSecondarySegment}
            onOpen={() => {
              setOpenSecondarySegment(true);
            }}
            onClose={() => {
              setOpenSecondarySegment(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            onChange={(event, value) => setSecondarySegments(value.map(v => v.ID))}
            options={segments}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Secondary Segment"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}