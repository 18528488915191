import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  FormGroup,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { SelectBox } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProfessionalLookup from '../../components/ProfessionalLookup/index.tsx';

import { errorMessage$, successMessage$ } from '../../utils/subjects';
import api from '../../services/api';
import { WhisperSpinner } from 'react-spinners-kit';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // padding: '16px',
      justifyContent: 'center',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogDelete({
  handleClose,
  handleOpen,
  open,
  id,
  projectId,
  callback,
  isTBD,
  loadAllocation,
  handleDeleteResource,
  type,
  onReload,
}) {
  const classes = useStyles();
  const [professionalAllocationId] = useState(id);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState([0]);
  const [isReplace, setIsReplace] = useState(false);
  const [projectStatus, setProjectStatus] = useState();
  // const [isTbd, setIsTbd] = useState(false);
  const [allocatedProfessionals, setAllocatedProfessionals] = useState([]);
  const [professionalAlreadyAllocated, setProfessionalAlreadyAllocated] =
    useState(false);
  const [professionalReturnAllocation, setProfessionalReturnAllocation] =
    useState([]);

  const handleDeleteTBDProfessional = useCallback(async () => {
    try {
      const { data } = await api.delete(
        `${type}/projects/${projectId}/professionals-allocations/${professionalAllocationId}`,
      );
      successMessage$.next({
        open: true,
        message: 'Professional deleted successfully',
      });
      if (callback) callback(professionalAllocationId);
      return data;
    } catch (err) {
      console.error('Error in handleDeleteTBDProfessional:', err);
      errorMessage$.next({
        open: true,
        message: 'Error on delete' + ': ' + err.message,
      });
    }
  }, [callback, handleClose, projectId, professionalAllocationId]);

  const handleLoadAllocations = useCallback(async () => {
    setRemoveLoading(true);
    const allocationsResponse = await api.get(
      `${type}/projects/${projectId}/professionals-allocations`,
    );
    const projectStatusResponse = await api.get(
      `${type}/projects/${projectId}`,
    );

    setProjectStatus(projectStatusResponse.data.ProjectStatus.ID);
    setAllocatedProfessionals(allocationsResponse.data);

    const professionalReturn = allocationsResponse.data.find(
      x => x.ID === professionalAllocationId,
    );

    setProfessionalReturnAllocation(professionalReturn.Allocations);
    console.log(professionalReturn);

    // setIsTbd(professionalReturn?.IsTBD);
    setRemoveLoading(false);
  }, []);

  useEffect(() => {
    console.log(professionalReturnAllocation);
  }, [professionalReturnAllocation]);

  const isProfessionalAlreadyAllocated = useCallback(
    (selectedProfessionalId, selectedJobTitleId) => {
      return allocatedProfessionals.some(allocation => {
        return (
          allocation.Professional.ID === selectedProfessionalId &&
          allocation.Professional.JobTitle.ID === selectedJobTitleId
        );
      });
    },
    [allocatedProfessionals],
  );

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit();
  };

  const handleSubmit = useCallback(async () => {
    try {
      setRemoveLoading(true);

      if (isReplace) {

        await api.patch(
          `${type}/projects/${projectId}/professionals-allocations/${professionalAllocationId}/change`,
          {
            Professional: selectedProfessionalId,
            IsTBD: false,
          },
        );
      } else {
        await handleDeleteTBDProfessional();
      }

      handleDeleteResource();
      setRemoveLoading(false);
      successMessage$.next({
        open: true,
        message: 'Professional replaced successfully',
      });
      handleClose();
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      errorMessage$.next({
        open: true,
        message:
          (isReplace ? 'Error on replace' : 'Error on delete') +
          ': ' +
          err.message,
      });
    }
  }, [
    handleDeleteTBDProfessional,
    projectId,
    professionalAllocationId,
    isReplace,
    selectedProfessionalId,
  ]);

  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });

  useEffect(() => {
    handleLoadAllocations();
  }, [handleLoadAllocations]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      width={600}
      height={500}
      className="delete-dialog"
    >
      <DialogTitle id="form-dialog-title">Do you confirm?</DialogTitle>
      {removeLoading ? (
        <div
          style={{
            width: 600,
            height: 260,
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          {projectStatus === 1 && (
            // || projectStatus === 4
            <div
              style={{
                paddingLeft: '24px',
                paddingRight: ' 16px',
                marginBottom: '24px',
              }}
            >
              <p>
                If you choose a Professional in the dropdown, the currently
                assigned Professional TBD will be
                <b> replaced from the project</b>.
              </p>

              <p>
                <b>Leave it blank</b> if you want the currently assigned
                Professional removed.
              </p>
            </div>
          )}
          <form className={classes.root} onSubmit={handleSubmit}>
            {projectStatus === 1 && (
              // || projectStatus === 4
              <FormGroup>
                <SelectBox
                  dataSource={professionalSource}
                  displayExpr="Name"
                  valueExpr="ID"
                  placeholder="Select a professional"
                  itemRender={ProfessionalLookup}
                  searchExpr={['Name', 'JobTitle.Name']}
                  minSearchLength={3}
                  showDataBeforeSearch={true}
                  searchEnabled={true}
                  value={selectedProfessionalId}
                  required={false}
                  onValueChanged={e => {
                    setSelectedProfessionalId(e.value);
                    setIsReplace(!!e.value);
                    const isAllocated = isProfessionalAlreadyAllocated(
                      e.value,
                      e.component.option('selectedItem').JobTitle?.ID || null,
                    );
                    setProfessionalAlreadyAllocated(isAllocated);
                  }}
                />
                {professionalAlreadyAllocated && (
                  <p
                    style={{
                      color: '#8B0305',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      margin: 20,
                    }}
                  >
                    Professional already allocated in this project with the same
                    jobtitle
                  </p>
                )}
              </FormGroup>
            )}

            <DialogActions>
              <div className="buttons-row">
                <Button
                  color="primary"
                  type="submit"
                  onClick={onSubmit}
                  disabled={removeLoading || professionalAlreadyAllocated}
                >
                  {removeLoading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : isReplace ? (
                    'Replace'
                  ) : (
                    'Delete'
                  )}
                </Button>
                <Button onClick={handleClose} color="default">
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
}
