import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NumberFormatValue from '../../../components/NumberFormat';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogTaxRate({
  handleClose,
  handleOpen,
  open,
  callback,
}) {
  const classes = useStyles();

  const [warningMessage, setWarningMessage] = useState(null);
  const [office, setOffice] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [feeHourlyValue, setFeeHourlyValue] = useState('');
  const [feeDailyValue, setFeeDailyValue] = useState('');
  const [mainValue, setMainValue] = useState('');
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [currency, setCurrency] = useState(1);
  const [currencyName, setCurrencyName] = useState(null);

  const [jobtitleSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/jobtitles');

        return response.data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });
  const [officeSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/offices');

        return response.data;
      },
    }),
    paginate: true,
  });

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        const data = {
          IDOffice: office,
          IDPosition: jobtitle,
          MainValue: mainValue,
          IDCurrency: currency,
          Month: month,
          Year: year,
          FeeHourlyValue: feeHourlyValue,
          FeeDailyLocalValue: feeDailyValue,
        };

        handleClose();
        await api.post(`scheduling/cost-rates`, data);

        successMessage$.next({
          open: true,
          message: 'Cost rate created successfully',
        });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on create cost rate',
        });
      }
    },
    [
      callback,
      currency,
      feeHourlyValue,
      month,
      year,
      feeDailyValue,
      mainValue,
      handleClose,
      jobtitle,
      office,
    ],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">+ Add cost rate</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          {warningMessage && (
            <Alert severity="warning" style={{ marginBottom: 15 }}>
              <Typography variant="inherit" component="p">
                {warningMessage}
              </Typography>
            </Alert>
          )}

          <SelectBox
            dataSource={jobtitleSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select jobtitle"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={jobtitle}
            required={true}
            onValueChanged={e => {
              setJobtitle(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
          <SelectBox
            dataSource={officeSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select the office"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={office}
            required={true}
            onValueChanged={e => {
              setOffice(e.value);
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Month"
            id="month"
            value={month}
            required={true}
            type="number"
            onChange={e => setMonth(e.target.value)}
            style={{ marginBottom: 24, marginTop: 8 }}
          />
          <TextField
            fullWidth
            label="Year"
            id="year"
            value={year}
            required={true}
            type="number"
            onChange={e => setYear(e.target.value)}
            style={{ marginBottom: 24, marginTop: 8 }}
          />
          <SelectBox
            dataSource={currencySource}
            displayExpr="DisplayName"
            valueExpr="ID"
            placeholder="Select a currency"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={currency}
            required={true}
            onValueChanged={e => {
              setCurrencyName(e.component.option('text').split('-')[1]);
              setCurrency(e.value);
            }}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <TextField
            fullWidth
            label="Hourly value"
            id="hourly-value"
            value={feeHourlyValue}
            required={true}
            onChange={e => setFeeHourlyValue(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Daily value"
            id="dayly-value"
            value={feeDailyValue}
            onChange={e => setFeeDailyValue(e.target.value)}
            required={true}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Main Value in Dollar"
            id="main-value"
            value={mainValue}
            onChange={e => setMainValue(e.target.value)}
            required={true}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
