import React, { useEffect, useState, useCallback } from 'react';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import { FiEdit2, FiPlus, FiTrash } from 'react-icons/fi';
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Grouping,
  RequiredRule,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useControlContent } from '../../../hooks/controlContent';

import ControlList from '../ControlList';
import GridToolbar from '../../../components/GridToolbar';

import { Board, BoardContent, BoardHeader } from '../../../components/Board';

import DialogDateOutType from './DialogDateOutType';
import DialogDateOutTypeEdit from './DialogDateOutTypeEdit';
import DialogDateOutTypeDelete from './DialogDateOutTypeDelete';

import api from '../../../services/api';

export default function DateOutType() {
  const [dataSource, setDataSource] = useState([]);
  const [dateOutTypeId, setDateOutTypeId] = useState(null);
  const [openAddDateOutType, setOpenAddDateOutType] = useState(false);
  const [openEditDateOutType, setOpenEditDateOutType] = useState(false);
  const [openDeleteDateOutType, setOpenDeleteDateOutType] = useState(false);

  const { setControlContent, setPageTitle } = useControlContent();

  // Set control menu
  useEffect(() => {
    setPageTitle('HR');
    setControlContent(<ControlList />);

    const dateOutTypeStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        try {
          const response = await api.get('scheduling/date-out-types');
          return response.data;
        } catch (err) {
          console.log('load date-out-types', err);
        }
      },
    });

    const dateOutTypeDataSource = new DataSource({
      store: dateOutTypeStore,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(dateOutTypeDataSource);
  }, [setControlContent, setPageTitle]);

  const handleAdd = useCallback(() => {
    dataSource.reload();
  }, [dataSource]);

  const handleEdit = useCallback(() => {
    dataSource.reload();
  }, [dataSource]);

  const handleDelete = useCallback(() => {
    dataSource.reload();
  }, [dataSource]);

  const commandColumnRender = useCallback(e => {
    return (
      <>
        <Tooltip title="Edit" aria-label="edit" placement="left">
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setDateOutTypeId(e.key);
              setOpenEditDateOutType(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" aria-label="delete" placement="left">
          <IconButton
            aria-label="Delete"
            onClick={() => {
              setDateOutTypeId(e.key);
              setOpenDeleteDateOutType(true);
            }}
          >
            <FiTrash size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }, []);

  return (
    <Board>
      <BoardHeader
        title="Date Out Type"
        description="Create, edit and delete date out types"
      />

      <BoardContent style={{ padding: 0, margin: 0 }}>
        <GridToolbar style={{ justifyContent: 'flex-end' }}>
          <Tooltip title="Add new date out type">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              aria-label="Add new date out type"
              onClick={() => setOpenAddDateOutType(true)}
              startIcon={<FiPlus />}
            >
              Add
            </Button>
          </Tooltip>
        </GridToolbar>

        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          height="100%"
        >
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Grouping autoExpandAll={false} />

          <Column dataField="Description">
            <RequiredRule message="The Name field is required." />
          </Column>

          <Column
            type="buttons"
            cellRender={commandColumnRender}
            width={100}
          ></Column>
        </DataGrid>

        {openAddDateOutType ? (
          <DialogDateOutType
            open={openAddDateOutType}
            handleOpen={() => setOpenAddDateOutType(true)}
            handleClose={() => setOpenAddDateOutType(false)}
            callback={handleAdd}
          />
        ) : null}

        {openEditDateOutType ? (
          <DialogDateOutTypeEdit
            open={openEditDateOutType}
            handleOpen={() => setOpenEditDateOutType(true)}
            handleClose={() => setOpenEditDateOutType(false)}
            id={dateOutTypeId}
            callback={handleEdit}
          />
        ) : null}

        {openDeleteDateOutType ? (
          <DialogDateOutTypeDelete
            open={openDeleteDateOutType}
            handleOpen={() => setOpenDeleteDateOutType(true)}
            handleClose={() => setOpenDeleteDateOutType(false)}
            id={dateOutTypeId}
            callback={handleDelete}
          />
        ) : null}
      </BoardContent>
    </Board>
  );
}
