import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';

import ClosureDialog from './ClosureDialog';

import { FiChevronRight } from 'react-icons/fi';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    padding: '5px 16px 5px 16px',
  },
}));

export default function ControlList() {
  const classes = useStyles();
  const history = useHistory();
  const [openClosureDialog, setOpenClosureDialog] = useState(false);

  const handleTransferPrice = useCallback(() => {
    history.push('/finances/transfer-prices');
  }, [history]);

  const handleTaxRate = useCallback(() => {
    history.push('/finances/base-rates');
  }, [history]);

  const handleCostRate = useCallback(() => {
    history.push('/finances/cost-rates');
  }, [history]);

  const handleExchangeRate = useCallback(() => {
    history.push('/finances/exchange-rates');
  }, [history]);

  const handleCurrency = useCallback(() => {
    history.push('/finances/currencies');
  }, [history]);

  const handlePracticeMargin = useCallback(() => {
    history.push('/finances/practices-margins');
  }, [history]);

  const handleImport = useCallback(() => {
    history.push('/finances/import');
  }, [history]);

  // const handleProjects = useCallback(() => {
  //   history.push('/projects');
  // }, [history]);

  const handleAuxJobtitles = useCallback(() => {
    history.push('/finances/aux-jobtitles');
  }, [history]);

  const handleWeeklyClosure = useCallback(() => {
    setOpenClosureDialog(true);
  }, []);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button className={classes.listItem} onClick={handleTaxRate}>
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Base Rate
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem button className={classes.listItem} onClick={handleCostRate}>
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Cost Rate
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem
        button
        className={classes.listItem}
        onClick={handleAuxJobtitles}
      >
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Aux JobTitles
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      {/* <ListItem button className={classes.listItem} onClick={handleTransferPrice}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                style={{ fontSize: 14 }}
              >
                Transfer price
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem> */}

      <ListItem
        button
        className={classes.listItem}
        onClick={handleExchangeRate}
      >
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Exchange
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem button className={classes.listItem} onClick={handleCurrency}>
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Currencies
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem
        button
        className={classes.listItem}
        onClick={handlePracticeMargin}
      >
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Practices Margins
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem button className={classes.listItem} onClick={handleImport}>
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Imports
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      <ListItem
        button
        className={classes.listItem}
        onClick={handleWeeklyClosure}
      >
        <ListItemText
          primary={
            <>
              <Typography component="span" style={{ fontSize: 14 }}>
                Weekly Closure
              </Typography>
              <ListItemSecondaryAction>
                <FiChevronRight />
              </ListItemSecondaryAction>
            </>
          }
          style={{ margin: 0 }}
        />
      </ListItem>

      {openClosureDialog && (
        <ClosureDialog
          handleClose={() => setOpenClosureDialog(false)}
          handleOpen={() => setOpenClosureDialog(true)}
          open={openClosureDialog}
        />
      )}
    </List>
  );
}
