import React, { useCallback, useState, useMemo } from 'react';

import { Drawer, Box, Button, Typography, FormGroup } from '@material-ui/core';
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';

import { WhisperSpinner } from 'react-spinners-kit';
import ProfessionalLookup from '../../components/ProfessionalLookup';
import { errorMessage$, successMessage$ } from '../../utils/subjects.js';
import GridLoader from './GridLoader.js';
import api, {
  IGeographicScope,
  IMethodology,
  IPractice,
  IToolkit,
} from '../../services/api';

interface DialogCreatePortalProps {
  projectId: number;
  handleClose(): void;
  open: boolean;
  onSubmitted(portalId: number): void;
}

interface CreatePortalResponse {
  IDProject: number;
}

const DialogCreatePortal: React.FC<DialogCreatePortalProps> = ({
  handleClose,
  onSubmitted,
  open,
  projectId,
}) => {
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);
  const [leader, setLeader] = useState(null);
  const [practice, setPractice] = useState(null);
  const [methodology, setMethodology] = useState(null);
  const [businessUnit, setBusinessUnit] = useState(null);
  const [toolkit, setToolkit] = useState(null);
  const [geographicScope, setGeographicScope] = useState(null);
  const [sendNotification, setSendNotification] = useState(null);

  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });

  const practiceSource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await api.get<IPractice[]>('master/practices');
          return response.data.filter(x => x.IsActive);
        },
      }),
      paginate: true,
    }),
    [],
  );

  const methodologySource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await api.get<IMethodology[]>(
            'master/methodologies',
          );
          return response.data.filter(
            x => x.IsActive && x.IDPractice === practice,
          );
        },
      }),
      paginate: true,
    }),
    [practice],
  );

  const businessUnitSource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await api.get('master/business-units');
          return response.data;
        },
      }),
      paginate: true,
    }),
    [],
  );

  const toolkitSource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await api.get<IToolkit[]>('master/toolkits');
          return response.data.filter(
            x => x.IsActive && x.IDMethodology === methodology,
          );
        },
      }),
      paginate: true,
    }),
    [methodology],
  );

  const geographicScopeSource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const response = await api.get<IGeographicScope[]>(
            'master/geographic-scopes',
          );
          return response.data;
        },
      }),
      paginate: true,
    }),
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (
        partner === null ||
        leader === null ||
        practice === null ||
        geographicScope === null ||
        businessUnit === null ||
        sendNotification === null
      ) {
        errorMessage$.next({ open: true, message: 'Please, fill all fields' });
        return;
      }

      setLoading(true);
      const { data } = await api.post<CreatePortalResponse>(
        `scheduling/projects/${projectId}/create-portal`,
        {
          IDGeographicScope: geographicScope,
          IDLeader: leader,
          IDPartner: partner,
          IDMethodology: methodology,
          IDPractice: practice,
          IDBusinessUnit: businessUnit,
          IDToolkit: toolkit,
          SendNotification: sendNotification,
        },
      );

      successMessage$.next({
        open: true,
        message: 'Portal created!',
      });
      setLoading(false);
      onSubmitted(data.IDProject);
      handleClose();
    } catch {
      setLoading(false);
      errorMessage$.next({
        open: true,
        message: 'Something went wrong on create portal',
      });
    }
  }, [
    geographicScope,
    leader,
    partner,
    methodology,
    businessUnit,
    practice,
    toolkit,
    sendNotification,
    projectId,
    handleClose,
    onSubmitted,
  ]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading ? (
        <GridLoader>
          <WhisperSpinner size={50} frontColor="#8b0304" backColor="#8b0304" />
        </GridLoader>
      ) : null}

      <Box m={2} style={{ minWidth: 350, maxWidth: '100%' }}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Create Portal project
        </Typography>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Responsible</Typography>
          <SelectBox
            dataSource={professionalSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a professional"
            itemRender={ProfessionalLookup}
            searchExpr={['Name', 'JobTitle.Name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            value={partner}
            onValueChanged={e => setPartner(e.value)}
          />
        </FormGroup>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Leader</Typography>
          <SelectBox
            dataSource={professionalSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a professional"
            itemRender={ProfessionalLookup}
            searchExpr={['Name', 'JobTitle.Name']}
            minSearchLength={3}
            showDataBeforeSearch
            searchEnabled
            value={leader}
            onValueChanged={e => setLeader(e.value)}
          />
        </FormGroup>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Practice</Typography>
          <SelectBox
            dataSource={practiceSource}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a pratice"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={practice}
            onValueChanged={e => setPractice(e.value)}
          />
        </FormGroup>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Methodology</Typography>
          <SelectBox
            dataSource={methodologySource || {}}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a methodology"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={methodology}
            onValueChanged={e => setMethodology(e.value)}
          />
        </FormGroup>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Business Unit</Typography>
          <SelectBox
            dataSource={businessUnitSource || {}}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a business unit"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={businessUnit}
            onValueChanged={e => setBusinessUnit(e.value)}
          />
        </FormGroup>

        {/*  <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Toolkit</Typography>
          <SelectBox
            dataSource={toolkitSource || {}}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a toolkit"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={toolkit}
            onValueChanged={e => setToolkit(e.value)}
          />
        </FormGroup> */}

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Geographic Scope</Typography>
          <SelectBox
            dataSource={geographicScopeSource || {}}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Select a geographic scope"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={geographicScope}
            onValueChanged={e => setGeographicScope(e.value)}
          />
        </FormGroup>

        <FormGroup style={{ marginBottom: 20 }}>
          <Typography>Send notifications?</Typography>
          <SelectBox
            dataSource={[
              { ID: true, Name: 'Yes' },
              { ID: false, Name: 'No' },
            ]}
            displayExpr="Name"
            valueExpr="ID"
            placeholder="Yes or No"
            searchExpr={['Name']}
            minSearchLength={2}
            showDataBeforeSearch
            searchEnabled
            value={sendNotification}
            onValueChanged={e => setSendNotification(e.value)}
          />
        </FormGroup>

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
          >
            Create Portal
          </Button>
        </div>
      </Box>
    </Drawer>
  );
};

export default DialogCreatePortal;
