import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip, Button } from '@material-ui/core';
import { FiPlus } from 'react-icons/fi';

import SellValueTypeItem from './SellValueTypeItem';

import api from '../../../services/api';
import schedulingGroups from '../../../config/schedulingGroups';
import { useAuth } from '../../../hooks/auth';
import { CircularProgress } from '@material-ui/core';

import '../styles.css';

export default function SellValueType({
  categoryId,
  projectId: _projectId,
  projectStatus: _projectStatus,
  comanagementId: _comanagementId,
  currency,
}) {
  const { user } = useAuth();

  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
    schedulingGroups.CoManager,
  ]);
  const [allowEdit] = useState(() => {
    const projectStatus = _projectStatus;
    const projectComanagement = _comanagementId;
    const found = userScopes.some(scope => allowEditScopes.includes(scope));

    if (!found) {
      return false;
    } else {
      const isCoManager = userScopes.includes(schedulingGroups.CoManager);

      if (isCoManager) {
        const isEditableStatus =
          projectStatus !== 'Approved' &&
          projectStatus !== 'Canceled' &&
          projectStatus !== 'Closed' &&
          projectStatus !== 'Waiting validation';
        return (
          isEditableStatus &&
          user.comanagementsIds.includes(Number(projectComanagement))
        );
      }

      return true;
    }
  });

  const [projectSellValueTypes, setProjectSellValueTypes] = useState([]);
  const [projectId] = useState(_projectId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProjectSellValueTypes([]);

    Promise.all([
      api.get(`scheduling/projects/${projectId}/sell-value-types`, {
        params: {
          where: {
            Category: categoryId,
          },
        },
      }),
    ]).then(([projectSellValueTypes]) => {
      setProjectSellValueTypes(projectSellValueTypes.data);
    });
  }, [categoryId, projectId]);

  const handleAddItem = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        Project: projectId,
        Category: categoryId,
      };

      const sellValueType = await api.post(
        `scheduling/projects/${projectId}/sell-value-types`,
        data,
      );

      setProjectSellValueTypes(state => [...state, sellValueType.data]);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [categoryId, projectId]);

  const handleRemoveItem = useCallback(key => {
    setProjectSellValueTypes(state => state.filter(item => item.ID !== key));
  }, []);

  return (
    <>
      {allowEdit && (
        <Tooltip
          title="Add new registry"
          aria-label="create"
          placement="bottom"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FiPlus />}
            size="small"
            aria-label="Include sell value type"
            onClick={handleAddItem}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </Tooltip>
      )}

      <div className="sell-value-type-list">
        {projectSellValueTypes.map(item => (
          <SellValueTypeItem
            key={item.ID}
            id={item.ID}
            projectId={projectId}
            projectSellValueType={item}
            categoryId={categoryId}
            currency={currency}
            onRemove={handleRemoveItem}
            comanagementId={_comanagementId}
            projectStatus={_projectStatus}
          />
        ))}
      </div>
    </>
  );
}
