import React, { BaseHTMLAttributes } from 'react';

import './styles.css';

const Board: React.FC<BaseHTMLAttributes<HTMLDivElement>> = ({ style, children }) => {
  return (
    <div className="board-wrapper" style={style}>
      { children }
    </div>
  );
}

export default Board;