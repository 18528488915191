import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import io from "socket.io-client";
import {
  parseISO,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addDays,
} from 'date-fns';
import ContentLoader from 'react-content-loader';
import { Grid as GridContainer } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Grid from './Grid.js';

import Tools from '../../components/ToolsMenu/index.js';

import { useAuth } from '../../hooks/auth';
import schedulingGroups from '../../config/schedulingGroups';

import ProjectControlContentList from '../../components/ProjectControlContentList';
import ChipStatus from '../../components/ChipStatus';
import ProjectHeaderInfo from './ProjectHeaderInfo';
import { useControlContent } from '../../hooks/controlContent';

import { errorMessage$ } from '../../utils/subjects';

import {
  Board,
  BoardContent,
  BoardHeader,
  BoardActions,
} from '../../components/Board';

import './styles.css';

import api from '../../services/api';

export default function Allocation() {
  const history = useHistory();
  const { user } = useAuth();
  const [userScopes] = useState(() => (user && user.scopes) || []);

  const { projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [portalId, setPortalId] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [currentMargin, setCurrentMargin] = useState('');
  const [firstAllocationMargin, setFirstAllocationMargin] = useState('');
  const [projectLocalValue, setProjectLocalValue] = useState('');
  const [lastWeekMargin, setLastWeekMargin] = useState('');
  const [marginSales, setMarginSales] = useState('');
  const [clientName, setClientName] = useState('');
  const [isLegacy, setIsLegacy] = useState(false);
  const [mainCurrencyId, setMainCurrencyId] = useState(2);
  const [projectStatus, setProjectStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [missingDataInfo, setMissingDataInfo] = useState('');
  const [endDate, setEndDate] = useState('');
  const [comanagementName, setComanagementName] = useState('');
  const [comanagement, setComanagement] = useState('');
  const [supportComanagement, setSupportComanagement] = useState('');
  const [office, setOffice] = useState(0);
  const [officeName, setOfficeName] = useState('');
  const [loading, setLoading] = useState(true);
  const [marginLoading, setMarginLoading] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [showDeallocated, setShowDeallocated] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const handleReloadComponent = () => {
    setRefreshCount(refreshCount + 1);
  };

  const handleNavigation = useCallback(
    projectId => {
      history.push(`/projects/${projectId}/allocations`);
    },
    [history],
  );

  const { setControlContent, setPageTitle } = useControlContent();

  // Build control menu
  useEffect(() => {
    setPageTitle('Allocations');
    setControlContent(
      <ProjectControlContentList
        editedProject={editedProject}
        selected={projectId}
        onSelectProject={handleNavigation}
      />,
    );
  }, [
    editedProject,
    handleNavigation,
    projectId,
    setControlContent,
    setPageTitle,
  ]);

  const handleMarginUpdating = useCallback(() => {
    setMarginLoading(true);
  }, []);

  const handleMarginUpdated = useCallback(
    closureId => {
      const closure = closureId && closureId !== '' ? closureId : null;

      if (
        userScopes &&
        userScopes.some(scope =>
          [
            schedulingGroups.Admin,
            schedulingGroups.Allocation,
            schedulingGroups.CoManager,
            schedulingGroups.Manager,
            schedulingGroups.TechLab,
          ].includes(scope),
        )
      ) {
        api
          .get(`scheduling/projects/${projectId}/margin`, {
            params: {
              closureId: closure,
            },
          })
          .then(project => {
            setMarginLoading(false);
            setCurrentMargin(project.data.MarginCurrent);
          })
          .catch(err => {
            setMarginLoading(false);
            errorMessage$.next({
              open: true,
              message: `Cannot update margin. ${err}`,
            });
          });
      } else {
        setMarginLoading(false);
      }
    },
    [projectId, userScopes],
  );

  useEffect(() => {
    try {
      const loadProject = async () => {
        const project = await api.get(`scheduling/projects/${projectId}`);
        setProjectName(project.data.ProjectName);
        setProjectCode(project.data.ProjectCode);
        setClientName((project.data.Client && project.data.Client.Name) || '');
        setProjectStatus(project.data.ProjectStatus.Description);
        setComanagement(
          project.data.Comanagement && project.data.Comanagement.ID,
        );
        setComanagementName(
          project.data.Comanagement && project.data.Comanagement.Name,
        );
        setSupportComanagement(
          project.data.SupportComanagement &&
            project.data.SupportComanagement.ID,
        );
        setOffice(project.data.Office && project.data.Office.ID);
        setOfficeName(project.data.Office && project.data.Office.Name);
        setPortalId(project.data.IDPortal);
        setMainCurrencyId(project.data.IDMainCurrency);
        setIsLegacy(project.data.IsLegacy);
        setMissingDataInfo(project.data.MissingFinancialDataInfo);

        setProjectLocalValue(
          project.data.MainProjectValueTotalLocal
            ? new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: project.data?.Currency?.Name || 'BRL',
              }).format(project.data.MainProjectValueTotalLocal || 0)
            : '-',
        );

        setProjectValue(
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: project.data?.MainCurrency?.Name || 'BRL',
          }).format(project.data.MainProjectValueTotal),
        );

        // Read scheduling permission not allow to see margin
        if (
          userScopes &&
          userScopes.some(scope =>
            [
              schedulingGroups.Admin,
              schedulingGroups.Allocation,
              schedulingGroups.CoManager,
              schedulingGroups.Manager,
              schedulingGroups.TechLab,
            ].includes(scope),
          )
        ) {
          setCurrentMargin(project.data.MarginCurrent);
        }
        setFirstAllocationMargin(project.data.MarginFirstAllocation);
        setLastWeekMargin(project.data.MarginLastWeek);
        setMarginSales(project.data.SalesMargin);

        if (project.data.StartDate && project.data.EndDate) {
          const startDateParsed = parseISO(
            project.data.StartDateReal || project.data.StartDate,
          );
          const endDateParsed = parseISO(project.data.EndDate);
          setStartDate(
            format(
              startOfWeek(addDays(startDateParsed, 2), { weekStartsOn: 1 }),
              'dd MMM yyyy',
            ),
          );
          setEndDate(
            format(
              endOfWeek(subWeeks(endDateParsed, 1), { weekStartsOn: 6 }),
              'dd MMM yyyy',
            ),
          );
        } else {
          setStartDate('-');
          setEndDate('-');
        }

        setLoading(false);
      };

      setLoading(true);
      loadProject();
    } catch (err) {
      setLoading(false);
      errorMessage$.next({ open: true, message: 'Error on load project info' });
    }
  }, [projectId, userScopes]);

  // useEffect(() => {
  //   const socket = io(process.env.REACT_APP_SOCKET_IO_URL);
  //   socket.emit('connectedAllocation', { userEmail });

  //   socket.on('connectedAllocation', (msg) => {
  //     setUserConnected(msg.userEmail);
  //     setOpen(true);
  //   });
  // }, []);

  function handleEditProject(projectInfo) {
    if (projectInfo.ProjectName) {
      setProjectName(projectInfo.ProjectName);
    }
    if (projectInfo.ProjectCode) {
      setProjectCode(projectInfo.ProjectCode);
    }
    if (projectInfo.ProjectStatus) {
      setProjectStatus(projectInfo.ProjectStatus);
    }
    if (projectInfo.IDPortal) {
      setPortalId(projectInfo.IDPortal);
    }
    if (projectInfo.StartDate) {
      setStartDate(
        format(
          startOfWeek(addDays(projectInfo.StartDate, 2), { weekStartsOn: 1 }),
          'dd MMM yyyy',
        ),
      );
    }

    setEditedProject(projectInfo);
  }

  return (
    <>
      <Board>
        {!loading ? (
          <BoardHeader
            title={projectName}
            masterTitle={projectCode}
            description={
              <div className="description">
                <p>
                  <b>{clientName}</b> | {startDate} — {endDate}
                </p>
                <p>
                  <b>{comanagementName}</b> | {officeName}
                </p>
              </div>
            }
            headerRight={
              userScopes.some(scope =>
                [
                  schedulingGroups.Admin,
                  schedulingGroups.Allocation,
                  schedulingGroups.CoManager,
                  schedulingGroups.Manager,
                  schedulingGroups.TechLab,
                ].includes(scope),
              ) && (
                <BoardActions>
                  <ProjectHeaderInfo
                    projectValue={projectValue}
                    currentMargin={currentMargin}
                    firstAllocationMargin={firstAllocationMargin}
                    lastWeekMargin={lastWeekMargin}
                    loadingMargin={marginLoading}
                    projectValueLocal={projectLocalValue}
                    isLegacy={isLegacy}
                    mainCurrencyId={mainCurrencyId}
                    missingDataInfo={missingDataInfo}
                    marginSales={marginSales}
                  />
                </BoardActions>
              )
            }
            chip={<ChipStatus status={projectStatus} />}
          >
            {missingDataInfo &&
              userScopes.some(scope =>
                [
                  schedulingGroups.Admin,
                  schedulingGroups.Allocation,
                  schedulingGroups.CoManager,
                  schedulingGroups.Manager,
                  schedulingGroups.TechLab,
                ].includes(scope),
              ) && (
                <Alert severity="warning" style={{ margin: '30px 30px 0 0' }}>
                  <p>Financial data missing. Numbers may be incorrect.</p>
                  <p>Warning: {missingDataInfo}</p>
                </Alert>
              )}
          </BoardHeader>
        ) : (
          <div>
            <ContentLoader
              speed={2}
              viewBox="0 0 900 120"
              backgroundColor="#f3f3f3"
              foregroundColor="#dedede"
            >
              <rect x="0" y="26" rx="3" ry="3" width="400" height="30" />
              <rect x="0" y="71" rx="3" ry="3" width="260" height="23" />
            </ContentLoader>
          </div>
        )}

        <Grid
          onEditProject={handleEditProject}
          projectStatus={projectStatus}
          onUpdatedMargin={handleMarginUpdated}
          onUpdatingMargin={handleMarginUpdating}
          projectComanagement={comanagement}
          projectSupportComanagement={supportComanagement}
          portalId={portalId}
          officeId={office}
          showDeallocated={showDeallocated}
          setShowDeallocated={setShowDeallocated}
          onReload={handleReloadComponent}
          type="scheduling"
          refreshCount={refreshCount}
        />
      </Board>
      {userScopes.some(scope =>
        [
          schedulingGroups.Admin,
          schedulingGroups.Allocation,
          schedulingGroups.CoManager,
          schedulingGroups.Manager,
          schedulingGroups.TechLab,
        ].includes(scope),
      ) && (
        <Tools
          onEditProject={handleEditProject}
          projectStatus={projectStatus}
          onUpdatedMargin={handleMarginUpdated}
          onUpdatingMargin={handleMarginUpdating}
          projectComanagement={comanagement}
          projectSupportComanagement={supportComanagement}
          portalId={portalId}
          officeId={office}
          showDeallocated={showDeallocated}
          setShowDeallocated={setShowDeallocated}
          onReload={handleReloadComponent}
          type="scheduling"
        />
      )}
    </>
    // {/* <SnackBar open={open} autoHideDuration={6000} onClose={handleClose}>
    //   <Alert severity="success" variant="filled" elevation={6}>
    //     {`${userConnected} is online`}
    //   </Alert>
    // </SnackBar> */}
  );
}
