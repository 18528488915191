import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import NumberFormatValue from '../../../components/NumberFormat';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';
import api from '../../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DialogCostRateEdit({
  handleClose,
  handleOpen,
  open,
  callback,
  id,
}) {
  const classes = useStyles();

  const [costRateId] = useState(id);
  const [office, setOffice] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [feeHourlyValue, setFeeHourlyValue] = useState('');
  const [feeDailyValue, setFeeDailyValue] = useState('');
  const [mainValue, setMainValue] = useState('');

  useEffect(() => {
    api
      .get(`scheduling/cost-rates/${costRateId}`)
      .then(response => {
        const costRate = response.data;

        setOffice(costRate.IDOffice);
        setJobtitle(costRate.IDPosition);
        setFeeHourlyValue(costRate.FeeHourlyValue);
        setFeeDailyValue(costRate.FeeDailyLocalValue);
        setMainValue(costRate.MainValue);
      })
      .catch(err => {
        errorMessage$.next({
          open: true,
          message: 'Error to get cost rate information',
        });
      });
  }, [costRateId]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      try {
        const data = {
          MainValue: mainValue,
          FeeHourlyValue: feeHourlyValue,
          FeeDailyLocalValue: feeDailyValue,
        };

        handleClose();
        await api.put(`scheduling/cost-rates/${costRateId}`, data);

        successMessage$.next({
          open: true,
          message: 'Cost rate updated successfully',
        });
        if (callback) callback();
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on update cost rate',
        });
      }
    },
    [callback, mainValue, feeHourlyValue, handleClose, feeDailyValue],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ minWidth: 200 }}
    >
      <DialogTitle id="form-dialog-title">Edit cost rate</DialogTitle>
      <form className={classes.root} onSubmit={handleSubmit}>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            fullWidth
            label="Hourly value"
            id="hourly-value"
            value={feeHourlyValue}
            required={true}
            onChange={e => setFeeHourlyValue(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Daily value"
            id="dayly-value"
            onChange={e => setFeeDailyValue(e.target.value)}
            value={feeDailyValue}
            required={true}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
            style={{ marginBottom: 24 }}
          />
          <TextField
            fullWidth
            label="Main value in US Dollar"
            onChange={e => setMainValue(e.target.value)}
            id="main-value"
            value={mainValue}
            required={true}
            InputProps={{
              inputComponent: NumberFormatValue,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
