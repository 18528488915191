import React, { useCallback, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  Tooltip,
  Button,
} from '@material-ui/core';

import { FiArrowDown, FiTrash, FiSave } from 'react-icons/fi';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react';
import NumberFormatValue from '../../../components/NumberFormat';
import DialogDelete from './DialogDelete';
import { CircularProgress } from '@material-ui/core';

import { errorMessage$, successMessage$ } from '../../../utils/subjects';

import api from '../../../services/api';
import schedulingGroups from '../../../config/schedulingGroups';
import { useAuth } from '../../../hooks/auth';

export default function SellValueTypeItem({
  id,
  categoryId,
  projectId,
  onRemove,
  projectSellValueType,
  projectStatus: _projectStatus,
  comanagementId: _comanagementId,
}) {
  const { user } = useAuth();

  const [userScopes] = useState(() => (user && user.scopes) || []);
  const [allowEditScopes] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.CoManager,
    schedulingGroups.Admin,
  ]);
  const [allowEditScopesRemove] = useState([
    schedulingGroups.Allocation,
    schedulingGroups.Admin,
  ]);
  const [allowEdit] = useState(() => {
    const projectStatus = _projectStatus;
    const projectComanagement = _comanagementId;
    const found = userScopes.some(scope => allowEditScopes.includes(scope));

    if (!found) {
      return false;
    } else {
      const isCoManager = userScopes.includes(schedulingGroups.CoManager);

      if (isCoManager) {
        const isEditableStatus =
          projectStatus !== 'Approved' &&
          projectStatus !== 'Canceled' &&
          projectStatus !== 'Closed' &&
          projectStatus !== 'Waiting validation';
        return (
          isEditableStatus &&
          user.comanagementsIds.includes(Number(projectComanagement))
        );
      }

      return true;
    }
  });

  const [key] = useState(id);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [sellValueType, setSellValueType] = useState(
    (projectSellValueType.SellValueType &&
      projectSellValueType.SellValueType.ID) ||
      '',
  );
  const [operationId, setOperationId] = useState(
    (projectSellValueType.Operation && projectSellValueType.Operation.ID) || '',
  );
  const [operation, setOperation] = useState(
    (projectSellValueType.Operation &&
      projectSellValueType.Operation.Operation) ||
      '',
  );
  const [operations, setOperations] = useState([]);
  const [sellValueTypeName, setSellValueTypeName] = useState(
    (projectSellValueType.SellValueType &&
      projectSellValueType.SellValueType.Description) ||
      'Click to select a type',
  );
  const [value, setValue] = useState(projectSellValueType.Amount || 0);
  const [valueDisplay, setValueDisplay] = useState(
    projectSellValueType.Amount || 0,
  );
  const [currency, setCurrency] = useState(
    (projectSellValueType.Currency && projectSellValueType.Currency.ID) || '',
  );
  const [currencyAbbreviation, setCurrencyAbbreviation] = useState(
    (projectSellValueType.Currency &&
      projectSellValueType.Currency.Abbreviation) ||
      '',
  );
  const [currencyName, setCurrencyName] = useState(
    (projectSellValueType.Currency && projectSellValueType.Currency.Name) || '',
  );
  const [observation, setObservation] = useState(
    projectSellValueType.Observation || '',
  );
  const [taxPeriod, setTaxPeriod] = useState(
    (projectSellValueType.TaxPeriod && projectSellValueType.TaxPeriod.ID) || '',
  );
  const [loading, setLoading] = useState(false);

  // console.log(categoryId, 'categoryId');
  const [sellValueTypeSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/sell-value-types', {
          params: {
            where: {
              Category: categoryId,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [currencySource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/currencies');
        const currencyWithDisplayName = response.data.map(currency => ({
          ...currency,
          DisplayName: `${currency.Name} - ${currency.Abbreviation}`,
        }));

        return currencyWithDisplayName;
      },
    }),
    paginate: true,
  });
  const [sellValueTypeOperationsSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get(
          'scheduling/sell-value-types-operations',
        );
        setOperations(response.data);
        return response.data;
      },
    }),
    paginate: true,
  });
  const [taxPeriodSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('scheduling/tax-periods');
        const taxPeriodsWithDisplayValue = [];
        response.data.map(taxPeriod => {
          taxPeriodsWithDisplayValue.push({
            ...taxPeriod,
            DisplayValue: `${taxPeriod.Period}Q - ${taxPeriod.Year}`,
          });
        });

        return taxPeriodsWithDisplayValue;
      },
    }),
    paginate: true,
  });

  const handleRemove = useCallback(() => {
    onRemove(key);
  }, [key, onRemove]);

  const handleSave = useCallback(
    async (isButtonSave = false) => {
      try {
        setLoading(true);
        if ((operationId || categoryId === 1) && value && currency) {
          await api.put(
            `scheduling/projects/${projectId}/sell-value-types/${id}`,
            {
              SellValueType: sellValueType,
              Amount: value,
              Operation: operationId || 4, // ignore
              Currency: currency,
              Observation: observation,
              TaxPeriod: taxPeriod,
            },
          );

          if (isButtonSave) {
            successMessage$.next({ open: true, message: 'Saved' });
          }
        }
      } catch (err) {
        errorMessage$.next({
          open: true,
          message: 'Error on save sell value type',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      currency,
      id,
      observation,
      operationId,
      projectId,
      sellValueType,
      taxPeriod,
      value,
    ],
  );

  useEffect(() => {
    try {
      const displayAmount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: currencyName,
      }).format(value);

      if (operation.trim() === 'sub') {
        setValueDisplay(`(- ${displayAmount})`);
      } else {
        setValueDisplay(displayAmount);
      }
    } catch (err) {
      const displayAmount = '0,00';

      if (operation === 'sub') {
        setValueDisplay(`(- ${displayAmount})`);
      } else {
        setValueDisplay(displayAmount);
      }
    }
  }, [currencyName, operation, projectSellValueType, value]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<FiArrowDown />}
        aria-controls={`sell-value-type-${key}`}
      >
        <Typography>{`${sellValueTypeName}: ${valueDisplay}`}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid>
          <SelectBox
            dataSource={sellValueTypeSource}
            displayExpr="Description"
            valueExpr="ID"
            placeholder="Select a type"
            searchExpr={['Description']}
            minSearchLength={3}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={sellValueType}
            onOpened={e => {
              e.component.focus();
            }}
            onValueChanged={e => {
              setSellValueTypeName(e.component.option('text'));
              setSellValueType(e.value);
            }}
            onFocusOut={() => {
              handleSave();
            }}
            disabled={!allowEdit}
            style={{ marginBottom: 24 }}
          />

          <SelectBox
            dataSource={taxPeriodSource}
            displayExpr="DisplayValue"
            valueExpr="ID"
            placeholder="Select the period"
            searchExpr={['DisplayValue', 'Period', 'Year']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={taxPeriod}
            required={true}
            onValueChanged={e => {
              setTaxPeriod(e.value);
            }}
            style={{ marginBottom: 24 }}
            disabled={!allowEdit}
          />

          {/* {categoryId !== 1 && ( */}
          <SelectBox
            dataSource={sellValueTypeOperationsSource}
            displayExpr="Description"
            valueExpr="ID"
            placeholder="Select a operation"
            searchExpr={['Description', 'Operation']}
            minSearchLength={3}
            showDataBeforeSearch={true}
            searchEnabled={true}
            onFocusOut={() => {
              handleSave();
            }}
            value={operationId}
            onOpened={e => {
              e.component.focus();
            }}
            onValueChanged={e => {
              setOperationId(e.value);
              const operation = operations.find(
                operation => operation.ID === e.value,
              );
              setOperation(operation && operation.Operation);
            }}
            disabled={!allowEdit}
            style={{ marginBottom: 24 }}
          />
          {/* )} */}

          <SelectBox
            dataSource={currencySource}
            displayExpr="DisplayName"
            valueExpr="ID"
            placeholder="Select a currency"
            searchExpr={['Name', 'Abbreviation']}
            minSearchLength={2}
            showDataBeforeSearch={true}
            searchEnabled={true}
            value={currency}
            onOpened={e => {
              e.component.focus();
            }}
            onValueChanged={e => {
              setCurrencyAbbreviation(
                e.component.option('text').split('-')[1].trim(),
              );
              setCurrencyName(e.component.option('text').split('-')[0].trim());
              setCurrency(e.value);
            }}
            onFocusOut={() => {
              handleSave();
            }}
            disabled={!allowEdit}
            style={{ marginBottom: 8 }}
          />

          <TextField
            fullWidth
            label="Value"
            value={value}
            onChange={e => {
              setValue(Number(e.target.value));
            }}
            onBlur={() => {
              handleSave();
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencyAbbreviation}
                </InputAdornment>
              ),
              inputComponent: NumberFormatValue,
            }}
            disabled={!allowEdit}
            style={{ marginBottom: 8 }}
          />

          <TextField
            fullWidth
            label="Observation"
            value={observation}
            onChange={e => {
              setObservation(e.target.value);
            }}
            onBlur={() => {
              handleSave();
            }}
            disabled={!allowEdit}
            style={{ marginBottom: 15 }}
          />

          {allowEdit && (
            <>
              <Tooltip
                title="Save this item"
                aria-label="create"
                placement="bottom"
              >
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  aria-label="Save"
                  onClick={handleSave}
                  style={{ marginRight: 15 }}
                  disabled={!allowEdit || loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
              </Tooltip>
              <Tooltip
                title="Delete this item"
                aria-label="create"
                placement="bottom"
              >
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  aria-label="Delete"
                  onClick={() => setOpenDialogDelete(true)}
                  style={{ marginRight: 15 }}
                  disabled={
                    !userScopes.some(scope =>
                      allowEditScopesRemove.includes(scope),
                    ) || loading
                  }
                >
                  Remove
                </Button>
              </Tooltip>
            </>
          )}

          {openDialogDelete && (
            <DialogDelete
              callback={handleRemove}
              handleOpen={() => setOpenDialogDelete(true)}
              handleClose={() => setOpenDialogDelete(false)}
              id={key}
              open={openDialogDelete}
              projectId={projectId}
            />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
