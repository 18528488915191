import React, { useState, useCallback, useEffect } from 'react';

import { Button, Drawer, Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getYear } from 'date-fns';

import api from '../../../services/api';

import { errorMessage$, successMessage$, infoMessage$ } from '../../../utils/subjects';

export default function NetRevenue({open, handleClose, handleOpen}) {

  const [year, setYear] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [years, setYears] = useState([]);

  const listYears = useCallback(async () => {
    const { data } = await api.get('scheduling/reports/finances/net-revenue/years');

    setYears(data);
  }, []);

  useEffect(() => {
    listYears();
  }, [listYears]);

  const handleGenerate = useCallback(async () => {
    try {
      setErrorMessage(null);
      if (!year) {
        setErrorMessage('Please select a year');
        return;
      }

      infoMessage$.next({ open: true, message: 'We generating your report. It could take a while' });

      const response = await api.get('scheduling/reports/finances/net-revenue', {
        params: {
          year,
        },
        responseType: 'blob',
      });
  
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-net-revenue-${year}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      successMessage$.next({ open: true, message: 'Your report is ready. Open or save it below' });
    } catch (err) {
      errorMessage$.next({ open: true, message: 'Something were wrong with your report. Try again please.' });
    }
  }, [year]);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose} style={{ position: 'relative' }}>
      <Box m={2} minWidth={300}>
        <Typography variant="h5" component="h5" style={{ fontWeight: 500, marginBottom: '15px' }}>
          Allocation per project
        </Typography>

        {errorMessage && (
          <Alert severity="error" style={{ marginTop: 15 }}>
            <Typography variant="inherit" component="p">{errorMessage}</Typography>
          </Alert>
        )}

        <FormControl fullWidth style={{ marginTop: 15 }}>
          <InputLabel id="years-label">Select the year</InputLabel>
          <Select
            labelId="years-label"
            id="years-select"
            value={year}
            onChange={e => setYear(e.target.value)}
          >
            {years.map(year => (
              <MenuItem key={year} value={ year } selected>{ year }</MenuItem>
            ))}
          </Select>
        </FormControl>

        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <Button 
            onClick={handleClose}
            size="small"
            style={{ marginRight: 15 }}
          >
            Close
          </Button>

          <Button 
            onClick={handleGenerate} 
            variant="contained" 
            color="primary" 
            size="small"
          >
            Generate
          </Button>
        </div>
      </Box>
    </Drawer>
  );
}