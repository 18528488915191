import axios from 'axios';
import { errorMessage$ } from '../utils/subjects.js';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;
const BADREQUEST = 400;
const INTERNALSERVERERROR = 500;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/';
      } else if (status === BADREQUEST || status === INTERNALSERVERERROR) {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.message
            : 'Something went wrong';
        errorMessage$.next({
          open: true,
          message: errorMessage,
        });
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization = localStorage.getItem('accessToken');

export default api;

export interface ITaxPeriod {
  ID: number;
  Period: number;
  Year: number;
  DisplayValue?: string;
}

export interface IDoDont {
  ID: number;
  IDProfessional: number;
}

export interface IClassification {
  ID: number;
  Description: string;
  Icon: string;
}

export interface IDoDontClassification {
  ID: number;
  IDDoDont: number;
  IDClassification: number;
  Subject: string;
  IsDo: boolean;
  CreatedAt: string;
  UpdatedAt?: string;

  Classification: IClassification;
  DoDont: IDoDont;
}

export interface ISkill {
  Comment?: string;
  Description1?: string;
  Description2?: string;
  ExpiredAt?: string;
  ID: number;
  IDProfessional: number;
  IDSkillType: number;
  IsActive: boolean;
  UpdatedAt?: string;
  SkillType: ISkillType;
}

export interface ISkillType {
  ID: number;
  Description: string;
  ListType: string;
  IsActive: boolean;
  IsList: boolean;
  CaptionComment?: string;
  CaptionDescription1?: string;
  CaptionDescription2?: string;
  CaptionExpiredAt?: string;
}

export interface IClient {
  ID: number;
  Name: string;
  IsActive: boolean;
}

export interface IJobTitle {
  ID: number;
  Name: string;
}

export interface IOffice {
  ID: number;
  Name: string;
}

export interface IScheduleWeek {
  ID: number;
  WeekNumber: number;
  Year: number;
  WeekStartDate: string;
  WeekEndDate: string;
}

export interface IScheduleWeekClosure {
  ID: number;
  Week: number;
  Year: number;
  ProjectStatusObservation?: string;
}

export interface IProfessional {
  ID: number;
  Name: string;
  Login: string;
  Email: string;
  JobTitle: IJobTitle;
  Offices: IOffice[];
  Practices: IPractice[];
}

export interface IProfessionalAllocation {
  AllowEdit: boolean;
  ID: number;
  Professional: IProfessional;
  IsTBD: boolean;
  Position: IJobTitle;
  Allocations: IAllocation[];
  Office: number;
  Practice: number;
}

export interface IAllocation {
  ID: number;
  IsModified: boolean;
  TotalDays: number;
  ProjectScheduleWeek: IProjectScheduleWeek;
}

export interface IProjectScheduleWeek {
  ID: number;
  AllowEdit: boolean;
  ScheduleWeek: IScheduleWeek;
}

export interface IProjectSuggestions {
  Professionals: IProjectSuggestionProfessionals[];
  ScheduleWeek: IScheduleWeek;
}

export interface IProjectSuggestionProfessionals {
  ID: number;
  IDProfessional: number;
  TotalDays: number;
  Professional: IProfessional;
  IDOnePage: number;
  AllowEdit: boolean;
}

export interface IMethodology {
  ID: number;
  Name: number;
  IsActive: boolean;
  IDPractice: number;
}

export interface IPractice {
  ID: number;
  Name: string;
  IsActive: boolean;
}

export interface IToolkit {
  ID: number;
  Name: number;
  IsActive: boolean;
  IDMethodology: number;
}

export interface IGeographicScope {
  ID: number;
  Name: number;
  IsActive: boolean;
}

export interface IChangeToApprovedBulk {
  status: 'error' | 'success';
  errors: string;
}

export interface IReason {
  ID: number;
  Description: string;
  ReasonType: number;
}
