import React from 'react';

import './styles.css';

export const statusColors = {
  Draft: '#9e9e9e',
  'Waiting validation': '#ff9800',
  Approved: '#a2cf6e',
  Simulation: '#2196f3',
  Closed: '#f48fb1',
  Canceled: '#d32f2f',
}

export default function ChipStatus({ status }) {
  return (
    <div className="chip-status-container">
      <span className="chip-status" style={{ backgroundColor: statusColors[status] }}></span>
      <span className="chip-status-text">
        {status}
      </span>
    </div>
  );
}