import React, { useMemo } from 'react';
import { parseISO, format, startOfWeek, addDays } from 'date-fns';

interface WeekProps {
  ID: number;
  WeekNumber: number;
  Year: number;
  StartDate: string;
  EndDate: string;
  AllowEdit: boolean;
}

interface WeekHeaderProps {
  week: WeekProps | null;
  index?: number | null;
  isToday: boolean;
  isEditable: boolean;
}

const WeekHeader: React.FC<WeekHeaderProps> = ({
  week,
  index,
  isToday,
  isEditable,
}) => {
  const startDate = useMemo(() => week && parseISO(week.StartDate), [week]);

  return (
    <div
      style={{
        // borderRadius: '50%',
        // backgroundColor: isToday ? '#8b0304' : 'white',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <p
        style={{
          fontSize: '12px',
          color: isEditable ? (isToday ? '#8b3034' : '#333') : '#CCC',
          margin: 0,
          padding: 0,
        }}
      >
        {startDate &&
          `${format(
            startOfWeek(addDays(startDate, 2), { weekStartsOn: 1 }),
            'MMM',
          ).toUpperCase()} ${format(
            startOfWeek(addDays(startDate, 2), { weekStartsOn: 1 }),
            'dd',
          )}`}
      </p>

      <p
        style={{
          fontSize: '12px',
          color: isEditable ? (isToday ? '#8b3034' : '#333') : '#CCC',
          margin: 0,
          padding: 0,
          fontWeight: 'bold',
          letterSpacing: '0.1em',
        }}
      >
        {`WEEK ${index !== null && (index || 0) + 1}`}
      </p>
    </div>
  );
};

WeekHeader.defaultProps = {
  index: null,
};

export default WeekHeader;
