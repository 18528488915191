import React, { useEffect, useState } from 'react';
import {
  Column,
  DataGrid,
  HeaderFilter,
  Scrolling,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import ControlList from '../ControlList';

import { useControlContent } from '../../../hooks/controlContent';

import {
  Board,
  BoardActions,
  BoardContent,
  BoardHeader,
} from '../../../components/Board';

import api from '../../../services/api';

export default function Reason({ pageTitle }) {
  const [dataSource, setDataSource] = useState([]);
  const [, setReasonCustomStore] = useState([]);

  const { setControlContent, setPageTitle } = useControlContent();

  // Get reasons from api
  useEffect(() => {
    setPageTitle('Reasons');
    setControlContent(<ControlList />);

    const reasonStore = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('scheduling/reasons');
        return data;
      },
      insert: async data => {
        await api.post('scheduling/reasons', data);
      },
      update: async (id, data) => {
        await api.put(`scheduling/reasons/${id}`, data);
      },
      remove: async id => {
        await api.delete(`scheduling/reasons/${id}`);
      },
    });

    setReasonCustomStore(reasonStore);

    const reasonDataSource = new DataSource({
      store: reasonStore,
      paginate: true,
      reshapeOnPush: true,
    });

    setDataSource(reasonDataSource);
  }, [setControlContent, setPageTitle]);

  return (
    <Board>
      <BoardHeader title={pageTitle}>
        <BoardActions></BoardActions>
      </BoardHeader>

      <BoardContent style={{ padding: 0, margin: 0, position: 'relative' }}>
        <DataGrid
          dataSource={dataSource}
          showBorders={false}
          remoteOperations={false}
          height="100%"
        >
          <HeaderFilter visible={true} />
          <Scrolling mode="infinite" />
          <Column dataField="Description" allowFiltering={false} />
          <Column dataField="ReasonType" width={150} alignment="center">
            <Lookup
              dataSource={[
                { id: 1, name: 'Professional' },
                { id: 2, name: 'Week' },
              ]}
              displayExpr="name"
              valueExpr="id"
            />
          </Column>
          <Editing allowAdding allowDeleting allowUpdating />
        </DataGrid>
      </BoardContent>
    </Board>
  );
}
