import React from 'react';

import './styles.css';

export default function BoardHeaderRight({ children }) {

  return (
    <div className="board-header-right">
      { children }
    </div>
  );
}