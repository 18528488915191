import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useControlContent } from "../../hooks/controlContent";

import ProfessionalControlContentList from '../../components/ProfessionalControlContentList';

import LandingPage from '../../components/LandingPage';

export default function ProfessionalHome() {
  const history = useHistory();
  const { setControlContent, setPageTitle, pageTitle } = useControlContent();

  const handleNavigationAllocation = useCallback((professionalId) => {
    history.push(`/professionals/${professionalId}`);
  }, [history]);

  // Build control menu
  useEffect(() => {
    setPageTitle('Professionals');
    setControlContent(<ProfessionalControlContentList onSelectProfessional={handleNavigationAllocation} />);
  }, [handleNavigationAllocation, setControlContent, setPageTitle]);

  return (
    <LandingPage title={pageTitle} subtitle="Select a professional to see allocation, holidays, vacation and more" />
  );
}
