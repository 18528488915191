import React from 'react';

import { ControlContentProvider } from './controlContent';
import { AuthProvider } from './auth';

export default function AppProvider({ children }) {
  return (
    <AuthProvider>
      <ControlContentProvider>
        {children}
      </ControlContentProvider>
    </AuthProvider>
  );
}