import React, { useEffect } from "react";

import { useControlContent } from "../../../hooks/controlContent";

import ControlList from "../ControlList";

import LandingPage from '../../../components/LandingPage';

export default function FinanceHome() {
  const { setControlContent, setPageTitle, pageTitle } = useControlContent();
  
  // Build control menu
  useEffect(() => {
    setPageTitle("Finance");
    setControlContent(<ControlList />);
  }, [setControlContent, setPageTitle]);

  return (
    <LandingPage title={pageTitle} subtitle="Use the menu on the side to navigate" />
  );
}
