import React, { BaseHTMLAttributes } from 'react';

import './styles.css';

const BoardContent: React.FC<BaseHTMLAttributes<HTMLDivElement>> = ({ 
  style, children, className, ...rest 
}) => {
  return (
    <div className={`board-content-component ${className || ''}`} style={style} {...rest}>
      { children }
    </div>
  );
}

export default BoardContent;