import React, { useState, useEffect, useCallback } from 'react';

import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Selection,
} from 'devextreme-react/data-grid';

import { FiFlag, FiCheck, FiInfo } from 'react-icons/fi';
import ContentLoader from 'react-content-loader';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { WhisperSpinner } from 'react-spinners-kit';

import { addDays, format, parseISO, startOfWeek } from 'date-fns';

import { errorMessage$ } from '../../utils/subjects.js';

import api, {
  IProfessionalAllocation,
  IProjectSuggestions,
} from '../../services/api';
import ProfessionaLookup from '../../components/ProfessionalLookup';
import GridLoader from './GridLoader.js';

interface DialogSuggestionsProps {
  projectId: number;
  handleClose(): void;
  open: boolean;
  closureId: string;
  onSubmitted(): void;
}

const DialogSuggestions: React.FC<DialogSuggestionsProps> = ({
  projectId: _projectId,
  handleClose,
  open,
  closureId,
  onSubmitted,
}) => {
  const [loading, setLoading] = useState(true);
  const [projectId] = useState(_projectId);
  const [source, setSource] = useState({} as DataSource);
  const [startWeek, setStartWeek] = useState('');
  const [selectedProfessionals, setSelectedProfessionals] = useState<number[]>(
    [],
  );

  const [isSelected, setIsSelected] = useState(false);
  const [suggestionsArray, setSuggestionsArray] = useState([] as any);
  const [allocationsArray, setAllocationsArray] = useState([] as any);
  const [currentTotalDays, setCurrentTotalDays] = useState([] as any);

  const [selectedIds, setSelectedIds] = useState([]);

  const loadSuggestions = useCallback(async () => {
    setLoading(true);
    const filter = closureId !== '' ? `?closureId=${closureId}` : '';

    const [suggestions, allocations] = await Promise.all([
      api.get<IProjectSuggestions>(
        `scheduling/projects/${projectId}/suggestions${filter}`,
      ),
      api.get<IProfessionalAllocation[]>(
        `scheduling/projects/${projectId}/professionals-allocations${filter}`,
      ),
    ]);

    setSuggestionsArray(suggestions.data);
    setAllocationsArray(allocations.data);

    const store = new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: () => {
        return suggestions.data.Professionals.map(item => {
          const previousTotalDays = allocations.data
            .find(
              x =>
                x.Professional.ID === item.Professional?.ID &&
                x.Position.ID === item.Professional?.JobTitle?.ID &&
                x.Office === item.Professional?.Offices[0]?.ID &&
                x.Practice === item.Professional?.Practices[0]?.ID &&
                x.AllowEdit,
            )
            ?.Allocations.find(
              x =>
                x.ProjectScheduleWeek?.ScheduleWeek?.WeekNumber ===
                  suggestions?.data?.ScheduleWeek?.WeekNumber &&
                x.ProjectScheduleWeek?.ScheduleWeek?.Year ===
                  suggestions?.data?.ScheduleWeek?.Year,
            )?.TotalDays;

          console.log('item.Professional:', item.Professional);
          console.log('allocations:', allocations.data);

          const isTotalDaysEqual = item.TotalDays === previousTotalDays;
          return {
            ...item,
            CurrentTotalDays: previousTotalDays || 0,
            isTotalDaysEqual,
          };
        });
      },
    });

    setStartWeek(
      format(
        startOfWeek(
          addDays(parseISO(suggestions.data.ScheduleWeek.WeekStartDate), 2),
          {
            weekStartsOn: 1,
          },
        ),
        'dd MMM',
      ),
    );

    setSource(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setLoading(false);
  }, [closureId, projectId]);

  useEffect(() => {
    loadSuggestions();
  }, [loadSuggestions]);

  const professionalLookup = useCallback(data => {
    return ProfessionaLookup({
      Name: data.value.Name,
      JobTitle: data.value.JobTitle,
      ID: data.value.ID,
      Login: data.value.Login,
      HasClientAgreement: false,
    });
  }, []);

  const commandColumnRender = (e: any) => {
    const isDisabled = e.data.isTotalDaysEqual;
    const icon = isDisabled ? (
      <FiInfo size={18} style={{ color: '#999' }} />
    ) : (
      <FiCheck size={18} />
    );
    return (
      <>
        <Tooltip
          title={
            isDisabled
              ? 'The Current Value is the same as the suggested value'
              : 'The current value is different from the suggested value.'
          }
          aria-label={
            isDisabled
              ? 'The Current Value is the same as the suggested value'
              : 'The current value is different from the suggested value.'
          }
          placement="left"
        >
          <IconButton
            aria-label={
              isDisabled
                ? 'The Current Value is the same as the suggested value'
                : 'The current value is different from the suggested value.'
            }
          >
            {icon}
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const handleSubmitSelectedProfessionals = useCallback(async () => {
    setLoading(true);

    try {
      await api.post(`scheduling/projects/${projectId}/suggestions/apply`, {
        professionalIds: selectedProfessionals,
      });

      // setSelectedProfessionals([]);
      onSubmitted();
    } catch (err) {
      errorMessage$.next({
        open: true,
        message: 'Something went wrong',
      });
    }

    setLoading(false);
  }, [projectId, selectedProfessionals, onSubmitted]);

  const handleSelectionChanged = useCallback(
    ({ selectedRowKeys, selectedRowsData }) => {
      setSelectedProfessionals(
        selectedRowsData.map((x: any) => x.IDProfessional),
      );
      setSelectedIds(selectedRowKeys);
    },
    [],
  );

  return (
    <>
      {loading ? (
        <ContentLoader
          speed={2}
          width={540}
          height={300}
          viewBox="0 0 540 300"
          backgroundColor="#f3f3f3"
          foregroundColor="#dedede"
        >
          <rect x="0" y="0" rx="7" ry="7" width="540" height="100" />
          <rect x="0" y="130" rx="7" ry="7" width="540" height="100" />
          <rect x="0" y="260" rx="7" ry="7" width="540" height="30" />
        </ContentLoader>
      ) : (
        <>
          <div className="suggestions-grid">
            <DataGrid
              dataSource={source}
              showBorders
              remoteOperations
              showColumnLines
              onSelectionChanged={handleSelectionChanged}
              allowColumnResizing={false}
              height="100%"
            >
              <Selection
                mode="multiple"
                selectAllMode="allPages"
                showCheckBoxesMode="onClick"
              />
              <Paging enabled={false} />
              <Scrolling columnRenderingMode="infinite" />

              <Column
                dataField="Professional"
                fixed
                allowEditing={false}
                cellRender={professionalLookup}
                width={250}
              />

              <Column
                alignment="center"
                dataField="CurrentTotalDays"
                allowSorting={false}
                caption="Current value"
                allowEditing={false}
              />

              <Column
                alignment="center"
                dataField="TotalDays"
                allowSorting={false}
                allowEditing={false}
                caption="Suggestion"
              />

              <Column
                type="buttons"
                cellRender={commandColumnRender}
                width={100}
              />
            </DataGrid>
          </div>

          <div className="buttons-row">
            <Button
              variant="contained"
              color="primary"
              disabled={selectedProfessionals.length === 0}
              onClick={handleSubmitSelectedProfessionals}
            >
              Update Allocation
            </Button>
            <Button onClick={handleClose} color="default">
              Close
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DialogSuggestions;
