import React, { useEffect } from 'react';

import ControlList from './ControlList';
import {useControlContent} from '../../hooks/controlContent';

import LandingPage from '../../components/LandingPage';

export default function Admin() {

  const { setControlContent, setPageTitle, pageTitle } = useControlContent();

  useEffect(() => {
    setPageTitle('Admin');
    setControlContent(<ControlList />);
  }, [pageTitle, setControlContent, setPageTitle]);

  return (
    <LandingPage title={pageTitle} subtitle="Select a option on the side menu" />
  );
}