import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, FormGroup, Typography, Tab, Tabs } from '@material-ui/core';
import { TagBox, SelectBox, Slider } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

import { addDays, format, isSameWeek, parseISO, startOfWeek } from 'date-fns';
import TabPanel from '../../components/TabPanel';
import ContentLoader from 'react-content-loader';
import ProfessionalLookup from '../../components/ProfessionalLookup/index.tsx';

import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formGroup: {
    marginTop: theme.spacing(3),
  },
}));

export default function CreateResourceModal({
  weeks,
  projectId: _projectId,
  handleClose,
  handleOpen,
  open,
  onAddProfessionalAllocation,
  onReload,
  type, //if it's simulation, allocation or professionaL
}) {
  const classes = useStyles();
  const [professionalSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals', {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });
  const [professionalTBDSource] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('master/professionals-tbd');
        return response.data;
      },
    }),
    paginate: true,
  });

  const [reasons] = useState({
    store: new CustomStore({
      key: 'ID',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get(`scheduling/reasons`);
        return response.data.filter(x => x.ReasonType === 1);
      },
    }),
    paginate: true,
  });

  const [tab, setTab] = useState(0);
  const [filledTagBox, setFilledTagBox] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  const [projectId] = useState(_projectId);
  const [professionals, setProfessionals] = useState([]);
  const [tbdProfessionals, setTBDProfessionals] = useState([]);
  const [totalDays, setTotalDays] = useState(0);
  const [totalWeeks, setTotalWeeks] = useState(0);
  const [startWeek, setStartWeek] = useState(0);
  const [activeWeeks, setActiveWeeks] = useState([]);
  const [reason, setReason] = useState(0);
  const [maxWeek, setMaxWeek] = useState(0);
  const isSubmitDisabled = type === 'scheduling/simulation' ? reason : !reason;

  useEffect(() => {
    // Show loading indicator only if weeks are not yet available
    if (weeks.length === 0) {
      setLoading(true);
    } else {
      const activeWeeksArray = [];
      weeks.map(week => {
        if (week.AllowEdit) {
          const startDate = startOfWeek(addDays(parseISO(week.StartDate), 2), {
            weekStartsOn: 1,
          });

          activeWeeksArray.push({
            ...week,
            DisplayValue: format(startDate, 'dd MMM yyyy'),
          });
        }
      });

      const startWeekVerified = startWeek || activeWeeksArray[0]?.StartDate;
      const activeWeeksFromStartWeek = activeWeeksArray.filter(
        activeWeek => activeWeek.StartDate,
      );
      const weekFilter = activeWeeksArray.filter(
        activeWeek => activeWeek.StartDate >= startWeekVerified,
      );
      setMaxWeek(weekFilter.length);

      setActiveWeeks(activeWeeksFromStartWeek);

      // Hide loading indicator since weeks are now available
      setLoading(false);
    }
  }, [startWeek, weeks, setLoading]);

  // On change tab reset values
  useEffect(() => {
    setProfessionals([]);
    setTotalDays(0);
    setTotalWeeks(0);
    setStartWeek(0);
  }, [tab]);

  useEffect(() => {
    setTotalWeeks(0);
  }, [startWeek]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setIsLoadingSubmission(true);
      const professionalsData = [
        ...professionals.map(professional => ({
          Professional: professional,
          Status: 1,
          IsTBD: false,
          Reason: reason,
        })),
        ...tbdProfessionals.map(professional => ({
          Professional: professional,
          Status: 1,
          IsTBD: true,
          Reason: reason,
        })),
      ];

      // store professional allocation
      const response = await api.post(
        `${type}/projects/${projectId}/professionals-allocations`,
        professionalsData,
      );

      if (response.data && response.data.length > 0) {
        const professionalAllocations = [];
        const startWeekVerified = startWeek || activeWeeks[0].StartDate;

        response.data.map(professionalAllocation => {
          // Now store allocation for this professional allocation
          const allocations = [];

          let i = 0;
          let aux = 0;
          while (i < totalWeeks || aux < totalWeeks) {
            if (
              activeWeeks[aux] &&
              activeWeeks[aux].AllowEdit &&
              activeWeeks[aux].StartDate >= startWeekVerified
            ) {
              allocations.push({
                [type === 'scheduling/simulation'
                  ? 'SimulationProjectScheduleWeek'
                  : 'ProjectScheduleWeek']: activeWeeks[aux].ID,
                TotalDays: totalDays,
              });
              i++;
            }

            aux++;
          }

          professionalAllocations.push({
            ID: professionalAllocation.ID,
            Professional: professionalAllocation.Professional,
            Allocations: allocations,
            AllowEdit: professionalAllocation.AllowEdit,
            AllowDelete: professionalAllocation.AllowDelete,
            IsTBD: professionalAllocation.IsTBD,
          });
        });

        if (onAddProfessionalAllocation) {
          onAddProfessionalAllocation(professionalAllocations);
        }
      } else {
        alert('Please, select a Reason');
      }
      setIsLoadingSubmission(false);
      handleClose();
    },
    [
      handleClose,
      professionals,
      tbdProfessionals,
      projectId,
      reason,
      startWeek,
      activeWeeks,
      onAddProfessionalAllocation,
      totalWeeks,
      totalDays,
      onReload,
    ],
  );

  return (
    <>
      {loading ? (
        <ContentLoader
          speed={2}
          width={540}
          height={300}
          viewBox="0 0 540 300"
          backgroundColor="#f3f3f3"
          foregroundColor="#dedede"
        >
          <rect x="0" y="0" rx="7" ry="7" width="540" height="100" />
          <rect x="0" y="130" rx="7" ry="7" width="540" height="100" />
          <rect x="0" y="260" rx="7" ry="7" width="540" height="30" />
        </ContentLoader>
      ) : (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Typography gutterBottom>Professional</Typography>
            <TagBox
              dataSource={professionalSource}
              displayExpr="Name"
              valueExpr="ID"
              placeholder="Select a professional"
              itemRender={ProfessionalLookup}
              searchExpr={['Name', 'JobTitle.Name']}
              minSearchLength={3}
              showDataBeforeSearch
              searchEnabled
              value={professionals}
              required
              onValueChanged={e => setProfessionals(e.value)}
            />
          </FormGroup>

          <FormGroup>
            <Typography gutterBottom>TBD Professional</Typography>
            <TagBox
              dataSource={professionalTBDSource}
              displayExpr="Name"
              itemRender={e => {
                return (
                  <p>
                    TBD - {e.JobTitle.Name} ({e.Office.Name}) ({e.Practice.Name}
                    )
                  </p>
                );
              }}
              valueExpr="ID"
              placeholder="Select a professional"
              searchExpr={['Name', 'JobTitle.Name']}
              minSearchLength={3}
              showDataBeforeSearch
              searchEnabled
              value={tbdProfessionals}
              required
              onValueChanged={e => setTBDProfessionals(e.value)}
            />
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <Typography gutterBottom>Total days</Typography>
            <Slider
              min={0}
              max={5}
              defaultValue={0}
              step={0.5}
              tooltip={{
                enabled: true,
                position: 'top',
              }}
              name="totalDays"
              style={{ paddingTop: 20, paddingBottom: 0 }}
              label={{
                visible: true,
                position: 'top',
              }}
              value={totalDays}
              onValueChanged={e => setTotalDays(e.value)}
            />
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <Typography gutterBottom>Start from week</Typography>
            <SelectBox
              dataSource={activeWeeks}
              displayExpr="DisplayValue"
              valueExpr="StartDate"
              // defaultValue={activeWeeks[0] && activeWeeks[0].StartDate}
              onValueChanged={e => setStartWeek(e.value)}
            />
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <Typography gutterBottom>Apply to weeks</Typography>
            <Slider
              min={0}
              max={maxWeek}
              defaultValue={maxWeek}
              tooltip={{
                enabled: true,
                position: 'top',
              }}
              name="totalWeeks"
              style={{ paddingTop: 20, paddingBottom: 0 }}
              label={{
                visible: true,
                position: 'top',
              }}
              value={totalWeeks}
              onValueChanged={e => setTotalWeeks(e.value)}
            />
          </FormGroup>
          {type !== 'scheduling/simulation' && (
            <FormGroup className={classes.formGroup}>
              <Typography gutterBottom>Reason</Typography>
              <SelectBox
                dataSource={reasons}
                displayExpr="Description"
                valueExpr="ID"
                // defaultValue={activeWeeks[0] && activeWeeks[0].StartDate}
                onValueChanged={e => setReason(e.value)}
              />
            </FormGroup>
          )}

          <div className="buttons-row">
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitDisabled || isLoadingSubmission}
            >
              {isLoadingSubmission ? <CircularProgress size={24} /> : 'Add'}
            </Button>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
          </div>
        </form>
      )}
    </>
  );
}
